<template>
  <div>
    <el-form label-width="80px" style="margin-top: 20px">
      <el-form-item label="当前城市">
        <el-select
          style="width: 200px"
          v-model="cityCode"
          placeholder="全国"
          filterable
          @change="getBusinessSet"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-card class="box-card" v-if="pageShow">
      <el-form :model="form" :rules="relus" ref="form" label-width="120px">
        <el-form-item label="营业状态" prop="name">
          <div class="flex">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">营业</el-radio>
              <el-radio :label="0">休息</el-radio>
            </el-radio-group>
            <div class="text">
              设置休息后，用户将无法在小程序下单，请谨慎操作！
            </div>
          </div>
        </el-form-item>
        <div class="box">
          <div class="box_title flex" style="margin-bottom: 10px">
            <h3>接单服务</h3>
          </div>
          <div v-if="form.status">
            <div
              class="box_title flex"
              style="margin-bottom: 10px; margin-left: 20px"
            >
              <div>营业时间</div>
              <div class="text">在您设置的营业时间内，用户可在小程序内下单</div>
            </div>
            <div>
              <!-- 常规充电 -->
              <el-form-item
                label="常规充电"
                prop="conventional_charging.business_type"
              >
                <el-radio-group
                  v-model="form.conventional_charging.business_type"
                  @change="radioChange($event, 0)"
                >
                  <el-radio :label="0">全天</el-radio>
                  <el-radio :label="1">自定义</el-radio>
                </el-radio-group>
                <div v-if="form.conventional_charging.business_type">
                  <div>每天重复</div>
                  <el-form-item prop="convention_time">
                    <el-time-picker
                      v-model="form.convention_time"
                      format="HH:mm"
                      value-format="HH:mm"
                      is-range
                      clearable
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      @change="timeChange($event, 0)"
                      @input="$forceUpdate()"
                    >
                    </el-time-picker>
                  </el-form-item>
                  <div>每周重复</div>
                  <el-form-item>
                    <el-checkbox-group
                      v-model="form.conventional_charging.weeks"
                    >
                      <el-checkbox
                        v-for="(item, index) in weekList"
                        :key="index"
                        :label="item"
                        >{{ filtersWeek(item) }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </div>
                <div>
                  <div>尽快抵达时间设置</div>
                  <el-form-item prop="conventional_charging.arrive_time">
                    <span>用户下单时当下时间延后</span>
                    <el-input
                      style="width: 300px; margin-left: 10px"
                      v-model="form.conventional_charging.arrive_time"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      maxlength="3"
                    >
                      <template slot="suffix">分钟</template>
                    </el-input>
                  </el-form-item>
                </div>
              </el-form-item>
              <!-- 应急补电 -->
              <el-form-item label="应急补电" prop="name">
                <el-radio-group
                  v-model="form.emergency_charging.business_type"
                  @change="radioChange($event, 1)"
                >
                  <el-radio :label="0">全天</el-radio>
                  <el-radio :label="1">自定义</el-radio>
                </el-radio-group>
                <div v-if="form.emergency_charging.business_type">
                  <div>每天重复</div>
                  <el-form-item prop="emergency_time">
                    <el-time-picker
                      v-model="form.emergency_time"
                      format="HH:mm"
                      value-format="HH:mm"
                      is-range
                      clearable
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      @change="timeChange($event, 1)"
                      @input="$forceUpdate()"
                    >
                    </el-time-picker>
                  </el-form-item>
                  <div>每周重复</div>
                  <el-form-item>
                    <el-checkbox-group v-model="form.emergency_charging.weeks">
                      <el-checkbox
                        v-for="(item, index) in weekList"
                        :key="index"
                        :label="item"
                        >{{ filtersWeek(item) }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </div>
                <div>
                  <div>尽快抵达时间设置</div>
                  <el-form-item prop="emergency_charging.arrive_time">
                    <span>用户下单时当下时间延后</span>
                    <el-input
                      style="width: 300px; margin-left: 10px"
                      v-model="form.emergency_charging.arrive_time"
                      oninput="value=value.replace(/[^0-9]/g,'')"
                      maxlength="3"
                    >
                      <template slot="suffix">分钟</template>
                    </el-input>
                  </el-form-item>
                </div>
              </el-form-item>
            </div>
          </div>
          <div v-else>
            <el-form-item v-if="!form.status" label="自定义提示语">
              <div
                style="
                  border: 1px solid #409eff;
                  padding: 10px 20px;
                  width: 300px;
                  border-radius: 10px;
                "
              >
                <div>亲爱的用户，您好！</div>
                <div style="display: flex; white-space: nowrap">
                  {{ form.city_name }}&nbsp;<el-input
                    v-model.trim="form.tips"
                    clearable
                    maxlength="20"
                    style="width: 300px"
                  />
                </div>
                <div>因此给您带来不便，请见谅!</div>
                <div>如有问题，可联系:4009936668</div>
              </div>
              <div>设置休息后，用户将无法在小程序下单，请谨慎操作！</div>
            </el-form-item>
            <el-form-item label="设置自动开业" prop="auto_open">
              <el-radio-group v-model="form.auto_open">
                <el-radio :label="0">不设置自动开业时间</el-radio>
                <el-radio :label="1">设置自动开业时间</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="form.auto_open"
              label="自动开业时间"
              prop="auto_open_time"
            >
              <el-date-picker
                type="datetime"
                placeholder="请选择开始时间"
                style="width: 100%"
                clearable
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                v-model="form.auto_open_time"
              ></el-date-picker>
            </el-form-item>
          </div>
          <el-form-item
            label="服务区域"
            prop="customer_service_config.service_area"
            :rules="[
              {
                required: true,
                message: '请输入服务区域',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <el-input
              v-model="form.customer_service_config.service_area"
              style="width: 300px"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </div>
        <div class="box">
          <div>
            <div class="box_title flex" style="margin-bottom: 10px">
              <h3>客服服务</h3>
            </div>
            <div class="box_box">
              <el-form-item
                label="服务电话"
                prop="customer_service_config.service_tel"
                :rules="[
                  {
                    required: true,
                    message: '请输入服务电话',
                    trigger: ['change', 'blur'],
                  },
                ]"
              >
                <el-input
                  v-model="form.customer_service_config.service_tel"
                  style="width: 300px"
                  maxlength="20"
                ></el-input>
              </el-form-item>
              <el-form-item label="服务时间" prop="service_time">
                <el-time-picker
                  v-model="form.service_time"
                  format="HH:mm"
                  value-format="HH:mm"
                  is-range
                  clearable
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  @change="timeChange($event, 2)"
                  @input="$forceUpdate()"
                >
                </el-time-picker>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="footer-btn">
          <el-button class="confirm" type="primary" @click="dataFormSubmit()"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      cityCode: "",
      pageShow: false,
      weekList: [1, 2, 3, 4, 5, 6, 7],
      pickerOptions: {
        disabledDate(time) {
          const dateTime = new Date();
          const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
          const endDateTime = dateTime.setDate(dateTime.getDate() + 30000); //30000为当前日期之后多少天
          return (
            time.getTime() < new Date(startDateTime).getTime() ||
            time.getTime() > new Date(endDateTime).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:00",
      },
      form: {
        status: "",
        auto_open: "",
        auto_open_time: "",
        convention_time: [],
        emergency_time: [],
        service_time: [],
        conventional_charging: {
          business_type: "",
          start_time: null,
          end_time: null,
          weeks: [],
          arrive_time: "",
        },
        emergency_charging: {
          business_type: "",
          start_time: null,
          end_time: null,
          weeks: [],
          arrive_time: "",
        },
        customer_service_config: {
          service_tel: "",
          wx_work_url: "",
          service_area: "",
          service_start_time: "",
          service_end_time: "",
        },
      },
      relus: {
        status: [
          {
            required: true,
            message: "请选择营业状态",
            trigger: ["blur", "change"],
          },
        ],
        emergency_time: [
          {
            required: true,
            message: "请选择时间范围",
            trigger: ["blur", "change"],
          },
          {
            validator: this.validateEmergency_timeEndTime,
            trigger: ["blur", "change"],
          },
        ],
        convention_time: [
          {
            required: true,
            message: "请选择时间范围",
            trigger: ["blur", "change"],
          },
          {
            validator: this.validateConventionalEndTime,
            trigger: ["blur", "change"],
          },
        ],
        service_time: [
          {
            required: true,
            message: "请选择时间范围",
            trigger: ["blur", "change"],
          },
          {
            validator: this.validateServiceEndTime,
            trigger: ["blur", "change"],
          },
        ],
        auto_open_time: [
          {
            required: true,
            message: "请选择自动开业时间",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (moment(value).valueOf() < moment().valueOf()) {
                callback("自动开业时间不能小于当前时间");
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    filtersWeek() {
      return (item) => {
        let str = "";
        switch (item) {
          case 1:
            str = "周一";
            break;
          case 2:
            str = "周二";
            break;
          case 3:
            str = "周三";
            break;
          case 4:
            str = "周四";
            break;
          case 5:
            str = "周五";
            break;
          case 6:
            str = "周六";
            break;
          case 7:
            str = "周日";
            break;
        }
        return str;
      };
    },
  },
  created() {
    if (this.restrictedCityList.length > 0) {
      this.cityCode = this.restrictedCityList[0].cityCode;
      this.getBusinessSet();
    }
  },
  methods: {
    getBusinessSet() {
      this.showLoading("加载中");
      this.axiosHelper
        .get(`/api/config/area/get?cityCode=${this.cityCode}`)
        .then((response) => {
          this.hideLoading();
          if (response.data.code != 0) {
            this.pageShow = false;
            this.$message.error(response.data.msg);
            return;
          }
          this.pageShow = true;
          this.init(response.data.data);
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("加载失败");
        });
    },
    init(data) {
      this.$nextTick(() => {
        let row = JSON.parse(JSON.stringify(data));
        if (row) {
          this.form = row;
          this.form.convention_time = [
            row.conventional_charging.start_time,
            row.conventional_charging.end_time,
          ];
          this.form.emergency_time = [
            row.emergency_charging.start_time,
            row.emergency_charging.end_time,
          ];
          this.form.service_time = [
            row.customer_service_config.service_start_time || "",
            row.customer_service_config.service_end_time || "",
          ];
          return;
        }
        this.form = {
          status: "",
          auto_open: "",
          auto_open_time: "",
          convention_time: [],
          emergency_time: [],
          service_time: [],
          conventional_charging: {
            business_type: "",
            start_time: null,
            end_time: null,
            weeks: [],
            arrive_time: "",
          },
          emergency_charging: {
            business_type: "",
            start_time: null,
            end_time: null,
            weeks: [],
            arrive_time: "",
          },
          customer_service_config: {
            service_tel: "",
            wx_work_url: "",
            service_area: "",
            service_start_time: "",
            service_end_time: "",
          },
        };
      });
    },
    timeChange(val, type) {
      if (!val) {
        switch (type) {
          case 0:
            this.form.convention_time = [];
            break;
          case 1:
            this.form.emergency_time = [];
            break;
          case 2:
            this.form.service_time = [];
            break;
        }
      }
      if (val && val.length && type === 0) {
        this.form.conventional_charging.start_time = val[0];
        this.form.conventional_charging.end_time = val[1];
        this.form.convention_time = val;
        this.$nextTick(() => {
          this.$refs.form.validateField("convention_time"); // 触发对 convention_time 的校验
        });
        return;
      }
      if (val && val.length && type === 1) {
        this.form.emergency_charging.start_time = val[0];
        this.form.emergency_charging.end_time = val[1];
        this.form.emergency_time = val;
        this.$nextTick(() => {
          this.$refs.form.validateField("emergency_time"); // 触发对 convention_time 的校验
        });
        return;
      }
      if (val && val.length && type === 2) {
        this.form.customer_service_config.service_start_time = val[0];
        this.form.customer_service_config.service_end_time = val[1];
        this.form.service_time = val;
        this.$nextTick(() => {
          this.$refs.form.validateField("service_time"); // 触发对 convention_time 的校验
        });
        return;
      }
    },
    validateConventionalEndTime(rule, value, callback) {
      console.log(this.form.convention_time, "convention_time");
      if (!this.form.convention_time.length) {
        callback(new Error("请选择时间范围"));
      } else {
        const startTime = parseInt(
          this.form.convention_time[0].replace(":", "")
        );
        const endTime = parseInt(this.form.convention_time[1].replace(":", ""));
        if (endTime <= startTime + 199) {
          callback(new Error("结束时间必须大于开始时间至少2个小时"));
        } else {
          callback();
        }
      }
    },
    validateServiceEndTime(rule, value, callback) {
      console.log(this.form.service_time, "service_time");
      if (!this.form.service_time.length) {
        callback(new Error("请选择时间范围"));
      } else {
        console.log(this.form.service_time[0]);
        if (!this.form.service_time[0] || !this.form.service_time[1]) {
          callback(new Error("请选择时间范围"));
        } else {
          callback();
        }
      }
    },
    validateEmergency_timeEndTime(rule, value, callback) {
      if (!this.form.emergency_time.length) {
        callback(new Error("请选择时间范围"));
      } else {
        const startTime = parseInt(
          this.form.emergency_time[0].replace(":", "")
        );
        const endTime = parseInt(this.form.emergency_time[1].replace(":", ""));
        if (endTime <= startTime + 199) {
          callback(new Error("结束时间必须大于开始时间至少2个小时"));
        } else {
          callback();
        }
      }
    },

    radioChange(index, type) {
      let weeks = [1, 2, 3, 4, 5, 6, 7];
      if (index && !type)
        return (this.form.conventional_charging.weeks = weeks);
      if (index && type) return (this.form.emergency_charging.weeks = weeks);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        let str = !this.form.status
          ? "确定将营业状态调整为“休息”吗？营业状态为休息后，用户将无法在小程序下单哦！"
          : "确定讲营业状态调整为“营业”吗？";
        const confirmResult = await this.$confirm(str, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => {
          this.hideLoading();
          return err;
        });
        if (confirmResult != "confirm") {
          return;
        }
        this.axiosHelper
          .put(`/api/config/area/update/${this.form.id}`, this.form)
          .then((res) => {
            this.hideLoading();
            if (res.data.code != 0) return this.$message.error(res.data.msg);
            this.visible = false;
            this.$emit("refreshDataList");
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("加载失败");
          });
      });
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
          time = parseInt(time);
        } else if (typeof time === "string") {
          time = time.replace(new RegExp(/-/gm), "/");
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
  height: 100%;
}

.footer-btn {
  margin: 50px 0 0 12px;
}

.flex {
  display: flex;
  align-items: center;

  .text {
    color: #999999;
    font-size: 14px;
    margin: 0 0 3px 10px;
  }
}

.box {
  padding-top: 20px;
  border-top: 2px solid #5a8bff;
}

.box_box {
  padding: 20px;
  // border: 1px solid #5a8bff;
  border-radius: 4px;
}

.footer-btn {
  text-align: right;

  .confirm {
    width: 100px;
  }
}
</style>