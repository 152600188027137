<template>
  <el-dialog :visible.sync="visible" @close="close" title="订单发货">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="配送信息">
        <div>
          <p>配送方式：快递</p>
          <p>收货人信息：{{ selectObj.real_name }} {{ selectObj.mobile }}</p>
          <p>收货地址：{{ selectObj.address }}{{ selectObj.address_detail }}</p>
        </div>
      </el-form-item>
      <el-form-item label="发货方式">
        <p>在线下单</p>
      </el-form-item>
      <el-form-item label="发货类型">
        <el-radio v-model="form.delivery_type" label="1"
          >快递员上门打印面单</el-radio
        >
        <el-radio v-model="form.delivery_type" label="2" disabled
          >打印面单并通知快递员揽件</el-radio
        >
      </el-form-item>
      <el-form-item label="快递公司">
        <el-select
          v-model="form.express_company_code"
          placeholder="请选择快递公司"
        >
          <el-option
            v-for="item in address_company_list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递单号">
        <el-input
          v-model="form.courier_number"
          maxlength="30"
          placeholder="请输入快递单号"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">发货</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { shopOrderSendOutGoodsApi } from "@/api-new/shop-order";

export default {
  data() {
    return {
      visible: true,
      form: {
        courier_number: "",
        delivery_type: "1",
        express_company: "",
        express_company_code: "",
      },
      address_company_list: [
        {
          label: "圆通速递",
          value: "yuantong",
        },
        {
          label: "韵达快递",
          value: "yunda",
        },
        {
          label: "中通快递",
          value: "zhongtong",
        },
        {
          label: "申通快递",
          value: "shentong",
        },
        {
          label: "极兔速递",
          value: "jtexpress",
        },
        {
          label: "邮政快递包裹",
          value: "youzhengguonei",
        },
        {
          label: "顺丰速运",
          value: "shunfeng",
        },
        {
          label: "EMS",
          value: "ems",
        },
        {
          label: "京东物流",
          value: "jd",
        },
        {
          label: "邮政标准快递",
          value: "youzhengbk",
        },
        {
          label: "丰网速运",
          value: "fengwang",
        },
        {
          label: "德邦快递",
          value: "debangkuaidi",
        },
        {
          label: "顺丰快运",
          value: "shunfengkuaiyun",
        },
        {
          label: "德邦",
          value: "debangwuliu",
        },
        {
          label: "丹鸟",
          value: "danniao",
        },
        {
          label: "中通快运",
          value: "zhongtongkuaiyun",
        },
        {
          label: "优速",
          value: "youshuwuliu",
        },
        {
          label: "京东快运",
          value: "jingdongkuaiyun",
        },
      ],
      rules: {
        courier_number: [
          { required: true, message: "请输入快递单号", trigger: "blur" },
        ],
        express_company_code: [
          { required: true, message: "请选择快递公司", trigger: "change" },
        ],
      },
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        let res = await shopOrderSendOutGoodsApi({
          ...this.form,
          shop_order_id: this.selectObj.id,
          express_company: this.address_company_list.find(
            (it) => it.value == this.form.express_company_code
          ).label,
        }).finally(() => {
          this.hideLoading();
        });
        if (res.data.code == 0) {
          this.$message.success("发货成功");
          this.$emit("sure");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>