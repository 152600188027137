
<el-dialog
  title="取消订单"
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  append-to-body
  width="500px"
  @close="close"
>
  <p class="text-red">*取消原因备注必填</p>
  <el-select
    v-model="reason"
    style="margin-bottom: 20px"
    placeholder="全部"
    clearable
  >
    <el-option
      v-for="item in reasonList"
      :key="item.id"
      :value="item.id"
      :label="item.name"
    ></el-option>
  </el-select>
  <el-input
    v-if="reason == 2"
    v-model="remark"
    type="textarea"
    :rows="4"
    placeholder="请输入取消原因"
  ></el-input>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button type="primary" @click="submitForm">确定</el-button>
  </div>
</el-dialog>
