
<div>
  <el-form ref="form" :model="form" :rules="rules">
    <el-card class="box-card">
      <div class="flex">
        <h3>商品分类</h3>
        <el-form-item style="margin-left: 30px" label="" prop="goods_type_id">
          <el-cascader
            v-model="goods_type_id"
            :options="typeList"
            :props="{
              value: 'id',
              label: 'goods_type_name',
              children: 'children',
            }"
            :show-all-levels="false"
            @change="handleChange"
            :disabled="readonly"
          ></el-cascader>
        </el-form-item>
      </div>
    </el-card>
    <el-card class="box-card">
      <h3>基本信息</h3>
      <div style="margin: 30px 0 0 30px">
        <el-form-item label-width="140px" required label="商品图片（大）：">
          <div class="flex">
            <div
              class="image-list"
              v-for="item in bigImageList"
              :key="item.url"
            >
              <el-image class="image" :src="`${IMG_SERVE_URL}${item.url}`" />
              <i class="el-icon-close" @click="handleRemove(item.url)"></i>
            </div>
            <div
              class="upload-btn"
              @click="showImgManamentDialog(750, 666, 'big')"
            >
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label-width="140px" required label="商品图片（小）：">
          <div class="flex">
            <div
              class="image-list"
              v-for="item in smallImageList"
              :key="item.url"
            >
              <el-image class="image" :src="`${IMG_SERVE_URL}${item.url}`" />
              <i class="el-icon-close" @click="handleRemove(item.url)"></i>
            </div>
            <div
              class="upload-btn"
              @click="showImgManamentDialog(1, 1, 'small')"
            >
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="商品名称："
          prop="goods_name"
        >
          <el-input
            style="width: 300px"
            v-model="form.goods_name"
            placeholder="请输入商品名称"
            maxlength="20"
            :readonly="readonly"
          />
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="商品品牌："
          prop="goods_brand_id"
        >
          <el-select
            style="width: 300px"
            filterable
            v-model="form.goods_brand_id"
            :disabled="readonly"
          >
            <el-option
              v-for="item in goodsBrandList"
              :key="item.id"
              :label="item.goods_brand_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="商品供应商："
          prop="goods_supplier_id"
        >
          <el-select
            style="width: 300px"
            filterable
            v-model="form.goods_supplier_id"
            :disabled="readonly"
          >
            <el-option
              v-for="item in goodsSupplierList"
              :key="item.id"
              :label="item.supplier_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="140px"
          label="商品编码："
          prop="goods_code"
        >
          <el-input
            v-model="form.goods_code"
            style="width: 300px"
            placeholder="请输入商品编码"
            maxlength="20"
            :readonly="readonly"
          ></el-input
        ></el-form-item>
        <el-form-item label-width="140px" label="规格信息：" prop="specs">
          <el-input
            style="width: 300px"
            v-model="form.specs"
            placeholder="请输入规格信息"
            :readonly="readonly"
            maxlength="20"
          />
        </el-form-item>
      </div>
    </el-card>
    <el-card class="box-card">
      <h3>价格和库存</h3>
      <div style="margin: 30px 0 0 30px">
        <div class="flex">
          <el-form-item
            label-width="140px"
            label="售价："
            prop="goods_sku.goods_price"
          >
            <el-input
              v-model="form.goods_sku.goods_price"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入售价"
              @input="validateInput('goods_price', $event, 8, 2)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="140px"
            label="划线价："
            prop="goods_sku.goods_underline_price"
          >
            <el-input
              v-model="form.goods_sku.goods_underline_price"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入划线价"
              @input="validateInput('goods_underline_price', $event, 8, 2)"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex">
          <el-form-item
            label-width="140px"
            label="库存："
            prop="goods_sku.inventory"
          >
            <el-input
              v-model="form.goods_sku.inventory"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入库存"
              @input="validateInput('inventory', $event, 7)"
            ></el-input
          ></el-form-item>
          <el-form-item
            label-width="140px"
            label="进货价："
            prop="goods_sku.cost_price"
          >
            <el-input
              v-model="form.goods_sku.cost_price"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入进货价"
              maxlength="20"
              @input="validateInput('cost_price', $event, 8, 2)"
            ></el-input
          ></el-form-item>
        </div>
        <div class="flex">
          <el-form-item
            label-width="140px"
            label="服务说明标题："
            prop="service_desc"
          >
            <el-input
              v-model="form.service_desc_title"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入服务说明标题"
              maxlength="20"
            ></el-input
          ></el-form-item>
          <el-form-item
            label-width="140px"
            label="服务说明："
            prop="service_desc"
          >
            <el-input
              v-model="form.service_desc"
              :readonly="readonly"
              style="width: 300px"
              placeholder="请输入服务说明"
              maxlength="100"
              type="textarea"
            ></el-input
          ></el-form-item>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <h3>商品详情</h3>
      <!-- <VueUeditorWrap
        style="margin-top: 30px"
        v-model="form.goods_details"
        :config="myConfig"
      /> -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        mode="default"
        :defaultConfig="toolbarConfig"
      />
      <Editor
        style="height: 500px; overflow-y: hidden; margin-top: 30px"
        v-model="form.goods_details"
        mode="default"
        ref="editor"
        :defaultConfig="editorConfig"
        @onCreated="onCreated"
      />
    </el-card>
  </el-form>
  <div class="flex justify-center">
    <el-button @click="submitForm" v-if="!readonly" :loading="isSubmitLoad"
      >保存</el-button
    >
  </div>
  <ImageManagement
    v-if="isImageManagement"
    rootFilePath="shop"
    :scale="false"
    @close="isImageManagement = false"
    :width="imgDialogWidth"
    :height="imgDialogHeight"
    @sure="uploadImgSuccess"
  />
</div>
