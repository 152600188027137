import request from "@/utils/request";

/**
 * @description: 新增商品品牌
 * @param {Object} data 参数对象
 * @param {String} data.goods_brand_name 商品品牌名称
 * @param {String} data.id 主键id,添加的时候不用传，更新时必传
 * @param {String} data.remarks	备注
 * @return {*}
 */

export function addGoodsBrandApi(data) {
  return request({
    url: "/api/goods/brand/add",
    method: "post",
    data,
  });
}

/**
 * @description: 删除商品品牌
 * @param {Object} data 参数对象
 * @param {String} data.id 主键id
 * @return {*}
 */

export function deleteGoodsBrandApi(data) {
  return request({
    url: "/api/goods/brand/delete",
    method: "post",
    data,
  });
}

/**
 * @description: 商品品牌列表
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end 创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start 创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.goods_brand_name 商品品牌名称
 * @return {*}
 */

export function getGoodsBrandListApi(data) {
  return request({
    url: "/api/goods/brand/list",
    method: "post",
    data,
  });
}

/**
 * @description: 商品品牌列表（分页）
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end 创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start 创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.goods_brand_name 商品品牌名称
 * @param {String} limit 参数对象
 * @param {String} offset 参数对象
 * @return {*}
 */

export function queryGoodsBrandApi(data, limit, offset) {
  return request({
    url: "/api/goods/brand/query",
    method: "post",
    data,
    params: {
      limit,
      offset,
    },
  });
}

/**
 * @description: 更新商品品牌
 * @param {Object} data 参数对象
 * @param {String} data.goods_brand_name 商品品牌名称
 * @param {String} data.id 主键id,添加的时候不用传，更新时必传
 * @param {String} data.remarks	备注
 * @return {*}
 */

export function updateGoodsBrandApi(data) {
  return request({
    url: "/api/goods/brand/update",
    method: "post",
    data,
  });
}
