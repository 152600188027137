
<el-dialog
  title="退回"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  @close="close"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
    <el-form-item label="退回度数：" prop="degree">
      <el-input
        v-model="form.degree"
        size="small"
        style="width: 300px"
        maxlength="10"
        @input="validateInput('degree', $event, 8, 2)"
      ></el-input>
    </el-form-item>
    <el-form-item label="退回金额：" prop="degree_buy_money">
      <el-input
        v-model="form.degree_buy_money"
        size="small"
        style="width: 300px"
        maxlength="10"
        @input="validateInput('degree_buy_money', $event, 8, 2)"
      ></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button @click="submitForm">确定</el-button>
  </div>
</el-dialog>
