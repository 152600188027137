
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <p class="title">数据筛选</p>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
        >
          <el-row type="flex">
            <el-col :span="3">
              <el-form-item
                label="城市"
                label-width="60px"
                style="margin-bottom: 0"
              >
                <el-select
                  v-model="searchValue.city_code"
                  placeholder="请选择城市"
                  multiple
                  collapse-tags
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item
                label="手机号"
                label-width="80px"
                style="margin-bottom: 0"
              >
                <el-input v-model.trim="searchValue.cellphone" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item
                label="用户编号"
                label-width="80px"
                style="margin-bottom: 0"
              >
                <el-input
                  v-model.trim="searchValue.userNumber"
                  maxlength="10"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item
                label="充值状态"
                label-width="90px"
                style="margin-bottom: 0"
              >
                <el-select
                  v-model="searchValue.recharge_status"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in paymentStatusDim"
                    :key="item.data_value"
                    :value="item.data_value"
                    :label="item.data_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="充值时间"
                label-width="100px"
                style="margin-bottom: 0"
              >
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  style="width: auto"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="4" style="float: left; text-align: left">
              <el-form-item
                label="充值类型："
                label-width="90px"
                style="margin-bottom: 0"
              >
                <el-select
                  v-model="searchValue.type"
                  placeholder="全部"
                  collapse-tags
                  clearable
                  multiple
                >
                  <el-option label="账户余额" :value="0"></el-option>
                  <el-option label="度数" :value="7"></el-option>
                  <el-option label="B端度数" :value="21"></el-option>
                  <el-option label="车辆性能评估" :value="33"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3" style="float: left; text-align: left">
              <el-button
                type="primary"
                @click="clickSearch"
                style="margin-left: 10px"
                >查询</el-button
              >
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="filter"
        >高级查询</el-button
      >
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>充值列表</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
      <el-button
        v-if="isShowBtn"
        style="float: right; margin-right: 10px"
        @click="addRechargeSubsidy()"
        >手动充值返现</el-button
      >
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="first_city_name"
        label="城市"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_platform_serial_number"
        label="支付平台流水号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="time"
        label="充值时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="实付金额（元）"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="degree"
        label="充值度数（度）"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="充值类型"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type === 7">度数</span>
          <span v-else-if="scope.row.type === 0">账户余额</span>
          <span v-else-if="scope.row.type === 21">B端度数</span>
          <span v-else-if="scope.row.type === 33">车辆性能评估</span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="pay_status_name"
        label="充值状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="real_name"
        label="姓名"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_method_name"
        label="支付方式"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="open_id"
        label="微信ID"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recommend_type_name"
        label="推荐类型"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reference_name"
        label="推荐人"
        min-width="120"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="serial_number" label="充值流水号" min-width="150" align="center"></el-table-column> -->
      <!-- <el-table-column prop="wechat_no" label="微信号" min-width="150" align="center"></el-table-column> -->
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="early_amount"
        label="充值前"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 33">
            {{ scope.row.early_num }}
          </span>
          <span v-else>
            <span v-if="scope.row.early_amount == null">{{
              scope.row.early_available_degree
            }}</span>
            <span v-else>{{ scope.row.early_amount }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="current_amount"
        label="充值后"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 33">
            {{ scope.row.early_num + scope.row.num }}
          </span>
          <span v-else>
            <span v-if="scope.row.current_amount == null">{{
              scope.row.cur_available_degree
            }}</span>
            <span v-else>{{ scope.row.current_amount }}</span>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <el-dialog
    title="手动充值返现"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="40%"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="110px"
    >
      <el-form-item label="城市" prop="cityCode">
        <el-select
          v-model="dataForm.cityCode"
          placeholder="请选择城市"
          @change="cityOpsChange"
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动" prop="promotionId">
        <el-select v-model="dataForm.promotionId" placeholder="请选择城市">
          <el-option
            v-for="item in promotionOpsList"
            :key="item.promotionId"
            :label="item.name"
            :value="item.promotionId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" prop="cellphone">
        <el-input
          disabled
          v-model="dataForm.cellphone"
          placeholder="用户手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="流水id" prop="id">
        <el-input
          disabled
          v-model="dataForm.id"
          placeholder="流水id"
        ></el-input>
      </el-form-item>
      <el-form-item label="支付平台流水号" prop="pay_platform_serial_number">
        <el-input
          disabled
          v-model="dataForm.pay_platform_serial_number"
          placeholder="支付平台流水号"
        ></el-input>
      </el-form-item>
      <el-form-item label="充值金额" prop="rechargeMount">
        <el-input
          disabled
          v-model="dataForm.rechargeMount"
          placeholder="充值金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="返现金额" prop="amount">
        <el-input
          v-model="dataForm.amount"
          placeholder="请填写返现金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="dataForm.remark"
          placeholder="备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
</div>
