
<el-dialog
  :title="editState == 'edit' ? '编辑轮播图' : '新增轮播图'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  @close="close"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="120px">
    <el-form-item label="标题：" prop="title">
      <el-input
        v-model="form.title"
        size="small"
        style="width: 300px"
        maxlength="30"
      ></el-input>
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input
        v-model="form.sort"
        style="width: 300px"
        maxlength="140"
      ></el-input>
    </el-form-item>
    <el-form-item label="发布位置" prop="publish_location">
      <el-radio-group
        v-model="form.publish_location"
        @change="form.banner_url = ''"
      >
        <el-radio :label="1">首页</el-radio>
        <el-radio :label="2">商城</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="图片：" prop="banner_url">
      <img
        v-if="form.banner_url"
        :src="bannerUrl(form.banner_url)"
        class="avatar"
        @click="isImageManagement = true"
      />
      <div class="upload-icon" v-else @click="isImageManagement = true">
        <i class="el-icon-plus"></i>
      </div>
    </el-form-item>
    <el-form-item label="跳转类型" prop="type">
      <el-select
        v-model="form.type"
        @change="(form.popup_url = ''), (form.path = '')"
      >
        <el-option
          v-for="item in typeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="视频弹窗地址"
      prop="popup_url"
      v-if="form.type == '2'"
    >
      <el-input
        v-model="form.popup_url"
        style="width: 300px"
        maxlength="140"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="小程序跳转类型"
      prop="mp_type"
      v-if="form.type == '4'"
    >
      <el-radio-group v-model="form.mp_type">
        <el-radio :label="1">普通</el-radio>
        <el-radio :label="2">tabBar</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="跳转地址"
      prop="path"
      v-if="form.type != '1' && form.type != '2'"
    >
      <el-input
        v-model="form.path"
        style="width: 300px"
        maxlength="140"
      ></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button @click="submitForm">确定</el-button>
  </div>
  <ImageManagement
    v-if="isImageManagement"
    rootFilePath="banner"
    :width="width"
    :height="height"
    @close="isImageManagement = false"
    @sure="uploadImgSuccess"
  />
</el-dialog>
