<template>
  <el-dialog
    :title="editState == 'edit' ? '编辑轮播图' : '新增轮播图'"
    width="800px"
    :height="'80%'"
    :max-height="'600px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="标题：" prop="title">
        <el-input
          v-model="form.title"
          size="small"
          style="width: 300px"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model="form.sort"
          style="width: 300px"
          maxlength="140"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布位置" prop="publish_location">
        <el-radio-group
          v-model="form.publish_location"
          @change="form.banner_url = ''"
        >
          <el-radio :label="1">首页</el-radio>
          <el-radio :label="2">商城</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="图片：" prop="banner_url">
        <img
          v-if="form.banner_url"
          :src="bannerUrl(form.banner_url)"
          class="avatar"
          @click="isImageManagement = true"
        />
        <div class="upload-icon" v-else @click="isImageManagement = true">
          <i class="el-icon-plus"></i>
        </div>
      </el-form-item>
      <el-form-item label="跳转类型" prop="type">
        <el-select
          v-model="form.type"
          @change="(form.popup_url = ''), (form.path = '')"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="视频弹窗地址"
        prop="popup_url"
        v-if="form.type == '2'"
      >
        <el-input
          v-model="form.popup_url"
          style="width: 300px"
          maxlength="140"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="小程序跳转类型"
        prop="mp_type"
        v-if="form.type == '4'"
      >
        <el-radio-group v-model="form.mp_type">
          <el-radio :label="1">普通</el-radio>
          <el-radio :label="2">tabBar</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="跳转地址"
        prop="path"
        v-if="form.type != '1' && form.type != '2'"
      >
        <el-input
          v-model="form.path"
          style="width: 300px"
          maxlength="140"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submitForm">确定</el-button>
    </div>
    <ImageManagement
      v-if="isImageManagement"
      rootFilePath="banner"
      :width="width"
      :height="height"
      @close="isImageManagement = false"
      @sure="uploadImgSuccess"
    />
  </el-dialog>
</template>

<script>
import { IMG_SERVE_URL } from "@/config/index";
import { validateNum } from "@/utils/utils";
export default {
  data() {
    return {
      visible: true,
      typeList: [
        {
          label: "无",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
        {
          label: "h5跳转",
          value: 3,
        },
        {
          label: "小程序跳转",
          value: 4,
        },
      ],
      form: {
        banner_url: "",
        sort: "",
        publish_location: 1,
        title: "",
        path: "",
        popup_url: "",
        type: "",
        mp_type: 1,
      },
      rules: {
        banner_url: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
        path: [{ required: true, message: "请输入跳转地址", trigger: "blur" }],
        popup_url: [
          { required: true, message: "请输入跳转地址", trigger: "blur" },
        ],
      },
      isImageManagement: false,
    };
  },
  computed: {
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
    editState() {
      return this.selectItem.id ? "edit" : "add";
    },
    width() {
      if (this.form.publish_location == 1) {
        return 349;
      }
      if (this.form.publish_location == 2) {
        return 355;
      }
      return 1;
    },
    height() {
      if (this.form.publish_location == 1) {
        return 137;
      }
      if (this.form.publish_location == 2) {
        return 120;
      }
      return 1;
    },
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    console.log(this.selectItem);
    if (this.editState == "edit") {
      this.form.banner_url = this.selectItem.banner_url;
      this.form.sort = this.selectItem.sort || "";
      this.form.publish_location = this.selectItem.publish_location || 1;
      this.form.title = this.selectItem.title || "";
      this.form.path = this.selectItem.path || "";
      this.form.popup_url = this.selectItem.popup_url || "";
      this.form.type = this.selectItem.type || 1;
      this.form.mp_type = this.selectItem.mp_type || 1;
    }
  },
  methods: {
    uploadImgSuccess(imgUrl) {
      console.log(imgUrl);
      this.form.banner_url = imgUrl;
      this.isImageManagement = false;
    },
    bannerUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return `${IMG_SERVE_URL}${url}`;
    },
    validateInput(type, e) {
      this.form[type] = validateNum(e);
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        let res;
        this.showLoading("加载中");
        if (this.editState == "add") {
          res = await this.axiosHelper({
            url: `/v0.1/api/rotation/add`,
            method: "post",
            params: {
              ...this.form,
            },
          }).finally(() => {
            this.hideLoading();
          });
        } else {
          res = await this.axiosHelper({
            url: `/v0.1/api/rotation/update`,
            method: "post",
            data: {
              ...this.form,
              id: this.selectItem.id,
            },
          }).finally(() => {
            this.hideLoading();
          });
        }
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$emit("sure");
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 30px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>