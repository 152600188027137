<template>
  <div>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>商品分类</span>
        <el-button
          style="float: right"
          @click="showGoodsTypeDialog('', 0, '-1')"
          >新增分类</el-button
        >
      </el-row>
      <div class="table-wrapper" v-if="false">
        <div class="table-th">
          <span class="type-name">分类名称</span>
          <span class="flex-1">排序</span>
          <span class="flex-2">分类图标</span>
          <span class="flex-4">操作</span>
        </div>
        <!-- 一级类型 -->
        <vuedraggable
          v-model="typeList"
          @change="setGoodsTypeSortNum(typeList)"
        >
          <div
            class="table-tr"
            v-for="(typeInfo, pIndex) in typeList"
            :key="typeInfo.id"
          >
            <div class="table-tr-parent">
              <div class="type-name">
                <i
                  class="icon iconfont iconzhankai"
                  @click="openChild(pIndex, '', 1)"
                  v-show="pIndex !== firstIndex"
                ></i>
                <i
                  class="icon iconfont iconshouqi mr_10"
                  @click="openChild(pIndex, '', 1)"
                  v-show="pIndex === firstIndex"
                ></i>
                <span>{{ typeInfo.name }}</span>
              </div>
              <div class="flex-1">{{ typeInfo.sortNum }}</div>
              <div class="flex-2">
                <span
                  v-show="typeInfo.iconUrl === ''"
                  class="icon iconfont iconshangchuantupian"
                  style="font-size: 23px"
                ></span>
                <img
                  :src="typeInfo.iconTotalUrl"
                  alt
                  v-show="typeInfo.iconUrl !== ''"
                  style="width: 23px; height: 23px"
                />
              </div>
              <div class="flex-4">
                <span
                  class="edit"
                  v-show="typeInfo.typeGrade < 3"
                  @click="
                    showGoodsTypeDialog('', typeInfo.typeGrade, typeInfo.id)
                  "
                  >新增下级</span
                >
                <span
                  class="edit"
                  @click="
                    showGoodsTypeDialog(
                      typeInfo.id,
                      typeInfo.typeGrade,
                      typeInfo.parentId
                    )
                  "
                  >修改</span
                >
                <span class="del" @click="deleteGoodsType(typeInfo.id)"
                  >删除</span
                >
              </div>
            </div>
            <!-- 二级类型 -->
            <vuedraggable
              v-model="typeInfo.children"
              v-show="firstIndex === pIndex"
              @change="setGoodsTypeSortNum(typeInfo.children)"
            >
              <div
                class="table-tr-child"
                v-for="(childInfo, cIndex) in typeInfo.children"
                :key="childInfo.id"
              >
                <div class="table-tr-parent">
                  <div class="type-name">
                    <span class="border-left-bottom"></span>
                    <i
                      class="icon iconfont iconzhankai"
                      @click="openChild('', cIndex, 2)"
                      v-show="cIndex !== secondIndex"
                    ></i>
                    <i
                      class="icon iconfont iconshouqi mr_10"
                      @click="openChild('', cIndex, 2)"
                      v-show="cIndex === secondIndex"
                    ></i>
                    <span>{{ childInfo.name }}</span>
                  </div>
                  <div class="flex-1">{{ childInfo.sortNum }}</div>
                  <div class="flex-2">
                    <span
                      v-show="childInfo.iconUrl === ''"
                      class="icon iconfont iconshangchuantupian"
                      style="font-size: 23px"
                    ></span>
                    <img
                      :src="childInfo.iconTotalUrl"
                      alt
                      v-show="childInfo.iconUrl !== ''"
                      style="width: 23px; height: 23px"
                    />
                  </div>
                  <div class="flex-4">
                    <span
                      class="edit"
                      v-show="childInfo.typeGrade < 2"
                      @click="
                        showGoodsTypeDialog(
                          '',
                          childInfo.typeGrade,
                          childInfo.id
                        )
                      "
                      >新增下级</span
                    >
                    <span
                      class="edit"
                      @click="
                        showGoodsTypeDialog(
                          childInfo.id,
                          childInfo.typeGrade,
                          childInfo.parentId
                        )
                      "
                      >修改</span
                    >
                    <span class="del" @click="deleteGoodsType(childInfo.id)"
                      >删除</span
                    >
                  </div>
                </div>
              </div>
            </vuedraggable>
            <!-- 二级类型 -->
          </div>
        </vuedraggable>
      </div>
      <el-table
        :data="typeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="goods_type_name" label="分类名称">
        </el-table-column>
        <el-table-column prop="sort_num" label="排序"> </el-table-column>
        <el-table-column label="分类图标">
          <template slot-scope="scope">
            <img
              class="icon"
              v-if="scope.row.icon_url"
              :src="`${IMG_SERVE_URL}${scope.row.icon_url}`"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.type_grade == 1"
              @click="
                showGoodsTypeDialog('', scope.row.type_grade, scope.row.id)
              "
              >新增下级</el-button
            >
            <el-button
              type="text"
              @click="
                showGoodsTypeDialog(
                  scope.row.id,
                  scope.row.type_grade,
                  scope.row.parent_id
                )
              "
              >修改</el-button
            >
            <el-button type="text" @click="deleteGoodsType(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <editGoodsClassflyDialog
      :activityId="activityId"
      :typeGrade="typeGrade"
      :parentId="parentId"
      :typeAllList="typeAllList"
      v-if="goodsTypeDialog"
      @close="goodsTypeDialog = false"
      @submit="getGoodsTypeList"
    />
  </div>
</template>

<script>
import editGoodsClassflyDialog from "./edit-goods-classfly-dialog.vue";
import vuedraggable from "vuedraggable";
import { IMG_SERVE_URL } from "@/config/index";
import { deleteGoodsTypeApi, getGoodsTypeListApi } from "@/api-new/goods";
export default {
  data() {
    return {
      typeList: [],
      firstIndex: -1,
      secondIndex: -1,
      activityId: "",
      typeGrade: "",
      parentId: "",
      goodsTypeDialog: false,
      typeAllList: [],
    };
  },
  computed: {
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
  },
  created() {
    this.getGoodsTypeList();
  },
  methods: {
    showGoodsTypeDialog(id, typeGrade, parentId) {
      this.activityId = id;
      if (id) {
        this.typeGrade = Number(typeGrade);
      } else {
        this.typeGrade = Number(typeGrade) + 1;
      }
      this.parentId = parentId;
      this.goodsTypeDialog = true;
    },
    async getGoodsTypeList() {
      this.goodsTypeDialog = false;
      const res = await getGoodsTypeListApi();
      if (res.data.code == 0) {
        let arr = res.data.data || [];
        this.typeAllList = arr;
        arr.sort((a, b) => {
          if (a.type_grade == b.type_grade) {
            return b.sort_num - a.sort_num;
          } else {
            return a.type_grade - b.type_grade;
          }
        });
        let list = [];
        arr.forEach((item) => {
          if (item.type_grade == 1) {
            list.push(item);
          }
          if (item.type_grade == 2) {
            let parent = list.find((it) => it.id === item.parent_id);
            if (parent) {
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          }
        });
        this.typeList = list;
      }
    },
    async deleteGoodsType(id) {
      let obj = this.typeList.find((it) => it.id == id);
      if (obj?.type_grade == 1 && obj?.children?.length > 0) {
        this.$message.error("请先删除子分类");
        return;
      }
      await this.$confirm("确定要删除该分类吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      const res = await deleteGoodsTypeApi({
        id,
      });
      if (res.data.code == 0) {
        this.$message.success("删除成功");
        this.getGoodsTypeList();
      }
    },
  },
  components: {
    editGoodsClassflyDialog,
    vuedraggable,
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 50px;
  height: 50px;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 1100px;
  margin-top: 20px;
  .table-th {
    display: flex;
    background: #f5f7fa;
    padding: 10px 40px;
    color: #7c7d80;
    font-weight: bold;
    font-size: 14px;
    .flex-1 {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .flex-2 {
      flex: 2;
      display: flex;
      justify-content: center;
    }
    .flex-3 {
      flex: 3;
      display: flex;
      justify-content: center;
    }
    .flex-4 {
      flex: 4;
      display: flex;
      justify-content: center;
    }
    .type-name {
      flex: 3;
      display: flex;
      justify-content: flex-start;
    }
  }
  .table-tr {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    .table-tr-parent {
      display: flex;
      height: 50px;
      align-items: center;
      width: 100%;
      border-bottom: solid 1px #eeeeee;
      .border-left-bottom {
        border-left: solid 1px #cdd7e0;
        border-bottom: solid 1px #cdd7e0;
        width: 25px;
        height: 10px;
        margin: 0px 10px;
      }
      .iconxiaji1 {
        margin-left: 50px;
        margin-right: 10px;
      }
    }
    .table-tr-child {
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
    .flex-1 {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .flex-2 {
      flex: 2;
      display: flex;
      justify-content: center;
    }
    .flex-3 {
      flex: 3;
      display: flex;
      justify-content: center;
    }
    .flex-4 {
      flex: 4;
      display: flex;
      justify-content: center;
    }
    .type-name {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .iconzhankai {
      color: #1890ff;
      margin-right: 10px;
    }
    .edit {
      color: #1890ff;
      padding: 0px 10px;
      cursor: pointer;
    }
    .del {
      color: #ff4949;
      padding: 0px 10px;
      cursor: pointer;
    }
    .iconshouqi {
      color: #1890ff;
    }
    .iconxianshi {
      border: 1px solid #bcf1d3;
      color: #13ce66;
      border-radius: 5px;
      background: #e7faef;
      padding: 2px;
    }
    .iconbuxianshi {
      color: #ff4a4a;
      border: 1px solid #ffcbcb;
      border-radius: 5px;
      background: #ffecec;
      padding: 2px;
    }
  }
}
</style>