
<div>
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item
            label="退款时间"
            label-width="100px"
            style="margin-bottom: 0"
          >
            <div class="flex">
              <el-date-picker
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="供应商：" label-width="90px">
            <el-select
              v-model="searchValue.goods_supplier_id"
              placeholder="全部"
              clearable
            >
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :value="item.id"
                :label="item.supplier_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>退款管理</span>
      <div style="float: right">
        <el-button @click="exportOrder">导出</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      :span-method="handleRowSpan"
      border
    >
      <el-table-column label="退款单号" min-width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: #606266"
            @click="copy(scope.row.apply_number)"
            >{{ scope.row.apply_number }}</el-button
          >
          <i
            class="el-icon-copy-document"
            @click="copy(scope.row.apply_number)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: #606266"
            @click="copy(scope.row.order_no)"
            >{{ scope.row.order_no }}</el-button
          >
          <i
            class="el-icon-copy-document"
            @click="copy(scope.row.apply_number)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column label="退款商品" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="goods">
            <img :src="`${IMG_SERVE_URL}${scope.row.goods_img}`" />
            <p>{{ scope.row.goods_name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="refund_num"
        label="退款数量 (件)"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_name"
        label="买家"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column min-width="150" align="center">
        <template slot="header" slot-scope="scope">
          <p>售后类型</p>
          <p>退单时间</p>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.receiving_status === 0 ? "退款" : "退货退款" }}</p>
          <p>{{ scope.row.create_time }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="real_name"
        label="退款方式"
        min-width="150"
        align="center"
      >
        <template> 原路返回 </template>
      </el-table-column>
      <el-table-column
        prop="apply_refund_money"
        label="应退(元)"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="refund_money"
        label="实退(元)"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_proces_state_name"
        label="退款状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row, 'detail')"
            >退款详情</el-button
          >
          <el-button
            type="text"
            v-if="scope.row.refund_proces_state == '1'"
            @click="detail(scope.row, 'examine')"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <refund-detail-dialog
    :selectObj="selectObj"
    :selectType="selectType"
    v-if="isDetailDialog"
    @close="isDetailDialog = false"
    @sure="getDataList"
  ></refund-detail-dialog>
</div>
