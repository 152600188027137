<template>
  <el-dialog
    :title="editState == 'edit' ? '编辑商品品牌' : '新增商品品牌'"
    width="800px"
    :height="'80%'"
    :max-height="'600px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="商品品牌名称：" prop="goods_brand_name">
        <el-input
          v-model="form.goods_brand_name"
          size="small"
          style="width: 300px"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
          v-model="form.remarks"
          style="width: 300px"
          type="textarea"
          maxlength="140"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { IMG_SERVE_URL } from "@/config/index";
import { addGoodsTypeApi, updateGoodsTypeApi } from "@/api-new/goods";
import { validateNum } from "@/utils/utils";
import {
  addGoodsBrandApi,
  updateGoodsBrandApi,
} from "../../../api-new/shop-brand";
export default {
  data() {
    return {
      visible: true,
      form: {
        goods_brand_name: "",
        remarks: "",
      },
      rules: {
        goods_brand_name: [
          { required: true, message: "请输入商品品牌名称", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
    editState() {
      return this.selectItem.id ? "edit" : "add";
    },
  },
  props: {
    selectItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    if (this.editState == "edit") {
      this.form = {
        goods_brand_name: this.selectItem.goods_brand_name,
        remarks: this.selectItem.remarks,
      };
    }
  },
  methods: {
    validateInput(type, e) {
      this.form[type] = validateNum(e);
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        let res;
        this.showLoading("加载中");
        if (this.editState == "add") {
          res = await addGoodsBrandApi({
            ...this.form,
          }).finally(() => {
            this.hideLoading();
          });
        } else {
          res = await updateGoodsBrandApi({
            ...this.form,
            id: this.selectItem.id,
          }).finally(() => {
            this.hideLoading();
          });
        }
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$emit("submit");
        }
      });
    },
    close() {
      this.$emit("close");
    },
    uploadImgSuccess(imgUrl) {
      this.form.icon_url = imgUrl;
      this.isImageManagement = false;
      console.log(imgUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 30px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>