<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item
              label="发布位置："
              label-width="90px"
              style="margin-bottom: 0"
            >
              <el-select v-model="searchValue.publishLocation">
                <el-option
                  v-for="item in publishLocationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>轮播图管理</span>
        <div style="float: right">
          <el-button @click="edit({})">新增轮播图</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          prop="id"
          label="轮播图"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 40px"
              :src="bannerUrl(scope.row.banner_url)"
              fit="fill"
            />
          </template>
        </el-table-column>
        <el-table-column label="跳转类型" min-width="120" align="center">
          <template slot-scope="scope">
            {{ getDictIns("typeList", scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="跳转链接"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.type == 2 ? scope.row.popup_url : scope.row.path }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_price"
          label="发布位置"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ getDictIns("publishLocationList", scope.row.publish_location) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sort"
          label="排序值"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="添加时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" style="color: red" @click="delBtn(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <banner-add-modal
      v-if="isAddBannerModal"
      :selectItem="selectItem"
      @close="isAddBannerModal = false"
      @sure="addBannerModalSure"
    />
  </div>
</template>
<script>
import { IMG_SERVE_URL } from "@/config/index";
import bannerAddModal from "./model/banner-add-modal.vue";
export default {
  data() {
    return {
      searchValue: {
        publishLocation: "",
      },
      dataTable: [],
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      total: 0,
      vloading: false,
      publishLocationList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "首页",
          value: "1",
        },
        {
          label: "商城首页",
          value: "2",
        },
      ],
      typeList: [
        {
          label: "无",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
        {
          label: "h5跳转",
          value: 3,
        },
        {
          label: "小程序跳转",
          value: 4,
        },
      ],
      isAddBannerModal: false,
      selectItem: {},
    };
  },
  computed: {},
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getBannerList();
  },

  methods: {
    bannerUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return `${IMG_SERVE_URL}${url}`;
    },
    getDictIns(data, type) {
      return this.$data[data].find((item) => item.value == type).label;
    },
    addBannerModalSure() {
      this.isAddBannerModal = false;
      this.getBannerList();
    },
    edit(item) {
      console.log(item);
      this.selectItem = item;
      this.isAddBannerModal = true;
    },
    async delBtn(item) {
      await this.$confirm("请确认是否删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      const res = await this.axiosHelper({
        url: `/v0.1/api/rotation/delete`,
        method: "post",
        params: {
          id: item.id,
        },
      }).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        this.$message.success("删除成功");
        this.getBannerList();
      }
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getBannerList();
    },
    async getBannerList() {
      let offset = (this.pageIndex - 1) * this.pageSize;
      let limit = this.pageSize;
      this.vloading = true;
      const res = await this.axiosHelper({
        url: `/v0.1/api/rotation/query/${offset}/${limit}`,
        method: "get",
        data: {
          ...this.searchValue,
        },
      }).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        this.dataTable = arr;
        this.total = res.data.data.total;
      }
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getBannerList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getBannerList();
    },
    clear() {
      this.searchValue = {
        publishLocation: "",
      };
      this.clickSearch();
    },
  },
  components: {
    bannerAddModal,
  },
};
</script>
<style  lang="scss" scoped >
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
</style>