<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div slot="header" class="clearfix">
        <span>数据筛选</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="filter"
          >高级查询</el-button
        >
      </div>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          label-width="110px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="手机号：" label-width="110px">
                <el-input
                  v-model.trim="searchValue.user_no"
                  placeholder="请输入手机号"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="优惠券名称：" label-width="120px">
                <el-input
                  v-model.trim="searchValue.name"
                  placeholder="请输入优惠券名称"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="城市：" label-width="110px">
                <el-select
                  v-model="searchValue.city_code"
                  placeholder="全国"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态：">
                <el-select
                  clearable
                  v-model="searchValue.cur_state"
                  placeholder="请选择状态"
                >
                  <el-option
                    v-for="item in cur_state_emu"
                    :key="item.data_code"
                    :label="item.data_name"
                    :value="item.data_value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="用户编号：" label-width="110px">
                <el-input
                  v-model.trim="searchValue.userNumber"
                  placeholder="请输入用户编号："
                  clearable
                  maxlength="10"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="领取时间：">
                <el-date-picker
                  v-model="create_Time"
                  type="datetimerange"
                  style="width: 335px"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :clearable="true"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="来源活动：" label-width="90px">
                <el-input
                  v-model.trim="searchValue.promotion_name"
                  placeholder="请输入来源活动名称"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="float: right; text-align: right">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>优惠券管理</span>
        <el-button
          style="margin-left: 18px"
          v-if="isShowBtn"
          @click="updateMethod"
          >修改有效期</el-button
        >
        <el-button
          style="margin-left: 20px"
          v-if="isShowBtn"
          @click="cancelMethod()"
          >批量作废</el-button
        >
        <el-upload
          action="string"
          ref="upload"
          class="upload-test"
          :http-request="fileUpload"
          :on-error="fileError"
          :on-success="fileSuccess"
          :on-exceed="fileExceed"
          :show-file-list="false"
          :limit="1"
          :accept="this.suffix.join(',')"
        >
          <el-button v-if="isShowBtn">手动发放优惠（EXCEL导入）</el-button>
        </el-upload>
        <el-button v-if="isShowBtn" @click="addOrUpdateHandle('add')"
          >手动发放优惠</el-button
        >
        <el-button @click="downloadTemplate" v-if="isShowBtn"
          >EXCEL模板下载</el-button
        >
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="手机号"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="优惠券名称"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="优惠券编号"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="discount_type_name"
          label="优惠券类型"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="billing_name"
          label="来源场景"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="promotion_name"
          label="来源活动"
          min-width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="领取时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="validity_time"
          label="有效期"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="use_time"
          label="使用时间"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_mutex_limit"
          label="支付互斥"
          min-width="80"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ !scope.row.pay_mutex_limit ? "否" : "是" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="state_name"
          label="状态"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="isShowBtn && !scope.row.cur_state"
              @click="cancelMethod(1, scope.row)"
              type="text"
              size="small"
              >作废</el-button
            >
            <el-button
              @click="addOrUpdateHandle('details', scope.row)"
              type="text"
              size="small"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
    <UpdateModel
      :selection="selection"
      :dialog.sync="updateVisible"
      @search="updateSearch"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <conponAddModal ref="addOrUpdate" @refreshDataList="search">
    </conponAddModal>
    <conponDetailsModal ref="conponDetailsModal" />
  </div>
</template>
<script>
// import coupon_add_or_update from './model/coupon_add_or_update.vue'
import conponAddModal from "./model/coupon-add-modal.vue";
import conponDetailsModal from "./model/coupon-details.modal.vue";
import FilterModel from "./model/coupon-manage-search-model.vue";
import UpdateModel from "./model/validity-time-model.vue";
import OPERATE_API from "../../api/operate_api";
import agentApi from "../../api/agent_api";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "coupon-manage",
  components: {
    FilterModel,
    UpdateModel,
    conponAddModal,
    conponDetailsModal,
    // coupon_add_or_update
  },
  data() {
    return {
      dataForm: {},
      addOrUpdateVisible: false,
      isShowBtn: false,
      suffix: [
        ".xls",
        ".docx",
        ".docm",
        ".dotx",
        ".dotm",
        ".xlsx",
        ".xlsm",
        ".xltx",
        ".xltm",
        ".xlsb",
        ".xlam",
        ".doc",
      ],
      create_Time: null,
      total: 0,
      currentPage: 1,
      vloading: false,
      searchValue: {
        $limit: 50,
        $offset: 0,
        user_no: "",
        user_number: "",
      },
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      updateVisible: false,
      // 当前状态
      cur_state_emu: [
        {
          data_value: 0,
          data_name: "待使用",
          data_code: "DSY",
        },
        {
          data_value: 1,
          data_name: "已使用",
          data_code: "YSY",
        },
        {
          data_value: 2,
          data_name: "已过期",
          data_code: "YGQ",
        },
        {
          data_value: 3,
          data_name: "已作废",
          data_code: "YZF",
        },
        {
          data_value: 4,
          data_name: "代扣续用",
          data_code: "DKXY",
        },
      ],
    };
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    if (this.$store.state.user.isMaintenanceOrder == 1) {
      this.isShowBtn = true;
    }
    this.search();
  },
  mounted() {
    /*this.getCityList();
            this.search();*/
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  computed: {
    ...mapState(["restrictedCityList"]),
  },
  methods: {
    selectable(row) {
      return !row.cur_state;
    },
    // 新增 / 修改
    addOrUpdateHandle(state, row) {
      switch (state) {
        case "add":
          this.$refs.addOrUpdate.visible = true;
          break;
        case "details":
          this.$refs.conponDetailsModal.visible = true;
          break;
      }
      this.$nextTick(() => {
        this.$refs[state === "add" ? "addOrUpdate" : "conponDetailsModal"].init(
          row
        );
      });
    },
    filter() {
      this.filterVisible = true;
    },
    async search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/coupon_manage", { params: that.searchValue })
        .then((response) => {
          this.dataTable = response.data.items;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取优惠券管理列表失败",
            },
            error
          );
        });
    },
    async clear() {
      this.searchValue = {};
      this.searchValue.$limit = 50;
      this.searchValue.$offset = 0;
      this.create_Time = null;
      this.searchValue.create_time_start1 = "";
      this.searchValue.create_time_end1 = "";
      this.searchValue.user_number = "";
      this.search();
    },
    downloadTemplate() {
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = "手动发放优惠券导入"; // 设置图片名称
      a.href = "/files/手动发放优惠券导入.xlsx"; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    },
    fileUpload(fileObj) {
      let formData = new FormData();
      formData.append("file", fileObj.file);
      this.axiosHelper
        .post("/v0.1/api/operate/coupon_manage/import", formData)
        .then((response) => {
          if (response.data.result === 1) {
            this.$message.success({
              message: "导入成功!",
            });
            this.search();
            this.fileSuccess();
          } else {
            this.$message.warning({
              message: response.data.msg,
            });
            fileObj.onSuccess(response);
          }
        })
        .catch((error) => this.fileError(error));
    },
    // 文件上传成功
    fileSuccess() {
      this.$refs.upload.clearFiles();
    },
    // 文件上传失败
    // 文件上传失败
    fileError() {
      this.$message.error({
        message: "文件上传失败，请重试！",
      });
      this.$refs.upload.clearFiles();
    },
    //文件上传时
    fileExceed() {
      this.$message.warning({
        message: "文件正在上传",
      });
    },
    updateSearch() {
      this.handleCurrentChange(1);
    },
    updateMethod() {
      let _this = this;
      if (!this.selection || this.selection.length === 0) {
        this.$message.warning("请先选择要修改有效期的优惠券数据");
        return;
      }
      this.updateVisible = true;
    },
    cancelMethod(type = 0, row) {
      if (!type) {
        if (!this.selection || this.selection.length === 0) {
          this.$message.warning("请先选择要作废的优惠券数据");
          return;
        }
      }
      this.$confirm("是否要作废该优惠券?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.showLoading("加载中");
        let ids = [];
        switch (type) {
          case 0: // 批量作废时
            for (var i = 0; i < this.selection.length; i++) {
              ids.push(this.selection[i].id);
            }
            break;
          case 1: // 单个作废
            ids = [row.id];
            break;
        }
        let user = this.$store.state.user;
        let dataForm = {
          uid: user.uid,
        };
        this.axiosHelper
          .put("/v0.1/api/operate/coupon_manage/" + ids, dataForm)
          .then((response) => {
            if (response.data.code != 0) {
              this.hideLoading();
              this.$message.error(response.data.msg);
              return;
            }
            this.hideLoading();
            this.$message.success({
              message: "作废成功",
            });
            this.search();
          })
          .catch(() => {
            this.hideLoading();
            this.$message.error("作废失败");
          });
      });
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 16,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/coupon_manage/export", {
              params: this.searchValue,
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "用户编号",
          "手机号",
          "优惠券名称",
          "优惠券编号",
          "优惠券类型",
          "来源场景",
          "来源活动",
          "城市",
          "领取时间",
          "有效期",
          "使用时间",
          "备注",
          "状态",
        ];
        const filterVal = [
          "user_number",
          "cellphone",
          "name",
          "id",
          "discount_type_name",
          "billing_name",
          "promotion_name",
          "city_name",
          "create_time",
          "validity_time",
          "use_time",
          "remark",
          "state_name",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "优惠券管理导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      if (this.create_Time) {
        this.searchValue.create_time_start1 = this.create_Time[0];
        this.searchValue.create_time_end1 = this.create_Time[1];
      } else {
        this.searchValue.create_time_start1 = null;
        this.searchValue.create_time_end1 = null;
      }
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.wechat_no = form.wechat_no;
      this.searchValue.open_id = form.open_id;
      this.searchValue.cellphone = form.cellphone;
      this.searchValue.discount_type = form.discount_type;
      this.searchValue.id = form.id;
      this.searchValue.name = form.name;
      this.searchValue.promotion_name = form.promotion_name;
      this.searchValue.start_time = form.start_time;
      this.searchValue.end_time = form.end_time;
      this.searchValue.cur_state = form.cur_state;
      this.searchValue.create_time_start = form.create_time_start;
      this.searchValue.create_time_end = form.create_time_end;
      this.handleCurrentChange(1);
    },
    searchDataHandle(item) {
      if (item) {
        item.forEach((row) => {
          row.billing_type_name = this.getBillingTypeName(row.billing_type);
        });
      }
      return item;
    },
    getBillingTypeName(billing_type) {
      let billingTypeName = "其他";
      if (billing_type == 0) {
        billingTypeName = "新客见面有礼";
      } else if (billing_type == 1) {
        billingTypeName = "老客户转介绍（推荐有礼）";
      } else if (billing_type == 2) {
        billingTypeName = "手动派券";
      } else if (billing_type == 3) {
        billingTypeName = "充电送券";
      } else if (billing_type == 5) {
        billingTypeName = "充值送券";
      } else if (billing_type == 7) {
        billingTypeName = "新客充电专享";
      } else if (billing_type == 9) {
        billingTypeName = "其他";
      } else if (billing_type == -2) {
        billingTypeName = "总部手动派券";
      }
      return billingTypeName;
    },
  },
};
</script>
<style scoped>
.box-card {
  margin-top: 15px;
}

::v-deep .upload-test {
  display: inline-block;
  margin: 0 10px;
}

::v-deep .el-upload--text {
  width: auto;
  height: auto;
  vertical-align: middle;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
</style>