
<el-dialog
  title="物流信息"
  :visible.sync="islogistics"
  width="520px"
  class="logistics"
  :close-on-click-modal="false"
  @close="close"
>
  <div class="logistics-content">
    <div class="topgoods">
      <div class="wlinfo">
        <section>
          <div>
            <span>订单ID:</span>
            <span>{{ selectObj.order_no }}</span>
          </div>
          <div>
            <span>发货时间:</span>
            <span>{{ selectObj.create_time }}</span>
          </div>
        </section>
        <section>
          <div>
            <span>物流单号:</span>
            <span>{{ selectObj.courier_number }}</span>
            <i
              class="el-icon-document-copy"
              @click="copy(selectObj.courier_number)"
            />
          </div>
          <div>
            <span>物流公司:</span>
            <span>{{ selectObj.express_company }}</span>
          </div>
        </section>
      </div>
    </div>
    <div class="wllist">
      <div v-for="(item, i) in wllog" :key="i" class="listinfo">
        <div class="time">{{ item.time }}</div>
        <div class="progress">
          <div class="ring" />
          <div class="bar" />
        </div>
        <div class="txt" v-html="item.context" />
      </div>
    </div>
  </div>
</el-dialog>
