<template>
  <div>
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item
              label="订单搜索："
              label-width="90px"
              style="margin-bottom: 0"
            >
              <el-input
                v-model="searchValue.search_info"
                placeholder="可输入订单编号、收货人/买家手机号或后四位、收货人姓商品名称、物流单号"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="发货时间"
              label-width="100px"
              style="margin-bottom: 0"
            >
              <div class="flex">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  style="width: auto"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>物流监控</span>
        <div style="float: right">
          <el-button @click="exportOrder">导出</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          prop="courier_number"
          label="物流单号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_no"
          label="所属订单"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="发货时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="express_company"
          label="物流公司"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          label="异常类型"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="物流备注" min-width="150" align="center">
        </el-table-column>
        <el-table-column
          prop="express_name"
          label="物流状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="orderDetail(scope.row)"
              >查看物流</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <detail-dialog
      :selectObj="selectObj"
      v-if="isDetailDialog"
      @close="isDetailDialog = false"
    ></detail-dialog>
  </div>
</template>

<script>
import {
  queryShopOrderExpressApi,
  getShopOrderExpressApi,
} from "@/api-new/shop-order";
import moment from "moment";
import { expressStatus } from "../../../enum/shopOrderEnum";
import detailDialog from "./detail-dialog.vue";
import { operateAdd } from "@/api/system/user-api";
export default {
  components: { detailDialog },
  data() {
    return {
      searchValue: {
        create_time_begin: "",
        create_time_end: "",
        search_info: "",
      },
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      total: 0,
      dataTable: [],
      vloading: false,
      tableHeight: "70vh",
      selectObj: {},
      isDetailDialog: false,
    };
  },
  computed: {
    createTime: {
      get() {
        return [
          this.searchValue.create_time_begin,
          this.searchValue.create_time_end,
        ];
      },
      set(val) {
        this.searchValue.create_time_begin = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.searchValue.create_time_end = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },

  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getDataList();
  },
  methods: {
    orderDetail(item) {
      this.selectObj = item;
      this.isDetailDialog = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async getDataList() {
      this.vloading = true;
      const res = await queryShopOrderExpressApi(
        {
          ...this.searchValue,
        },

        this.pageSize,
        (this.pageIndex - 1) * this.pageSize
      ).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let array = res.data.data.items || [];
        array.forEach((item) => {
          item.express_result = JSON.parse(item.express_result || "{}");
          item.express_name = this.getExpressName(item);
        });
        console.log(array);
        this.dataTable = array;
        this.total = res.data.data.total;
      }
    },
    getExpressName(item) {
      if (item?.express_result?.lastResult?.status == "200") {
        return expressStatus[item?.express_result?.lastResult?.state];
      }
      return "";
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getDataList();
    },
    clear() {
      this.searchValue = {
        create_time_begin: "",
        create_time_end: "",
        search_info: "",
      };
      this.clickSearch();
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getDataList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getDataList();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 41,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      await this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      if (this.total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("数据加载中...");
      const res = await queryShopOrderExpressApi(
        {
          ...this.searchValue,
        },
        20000,
        0
      ).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        arr.forEach((item) => {
          item.express_result = JSON.parse(item.express_result || "{}");
          item.express_name = this.getExpressName(item);
        });
        this.exportData(arr);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "物流单号",
          "所属订单",
          "发货时间",
          "物流公司",
          "物流状态",
        ];
        const filterVal = [
          "courier_number",
          "order_no",
          "create_time",
          "express_company",
          "express_name",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "物流列表导出",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
.flex {
  display: flex;
}
</style>