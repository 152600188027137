import request from "@/utils/request";

/**
 * @description: 度数退回
 * @param {Object} data 参数对象
 * @param {String} data.degree 退回的度数
 * @param {String} data.degree_buy_money 退回的度数购买金额
 * @param {String} data.user_id	用户id
 * @return {*}
 */

export function retrunDegreeApi(data) {
  return request({
    url: "/api/userAccount/degree/retrun",
    method: "post",
    data,
  });
}
