
<el-dialog
  :title="editState == 'edit' ? '编辑商品供应商' : '新增商品供应商'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  @close="close"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="120px">
    <el-form-item label="供应商名称：" prop="supplier_name">
      <el-input
        v-model="form.supplier_name"
        size="small"
        style="width: 300px"
        maxlength="10"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系人：" prop="contacts">
      <el-input
        v-model="form.contacts"
        size="small"
        style="width: 300px"
        maxlength="10"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系电话：" prop="phone_number">
      <el-input
        v-model="form.phone_number"
        size="small"
        style="width: 300px"
        maxlength="11"
      ></el-input>
    </el-form-item>
    <el-form-item label="地址：" prop="address">
      <el-input
        v-model="form.address"
        size="small"
        style="width: 300px"
        maxlength="50"
      ></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remarks">
      <el-input
        v-model="form.remarks"
        style="width: 300px"
        type="textarea"
        maxlength="140"
      ></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button @click="submitForm">确定</el-button>
  </div>
</el-dialog>
