import axios from "axios";
import { Message } from "element-ui";
import Cookies from "js-cookie";
import vue from "vue";
import store from "@/store";
// create an axios instance
const service = axios.create(
  {
    baseURL: import.meta.env.VITE_APP_BASE_API,
    timeout: 30000, // request timeout,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
    },
  },
  { withCredentials: true }
);
let isPageRefresh = false;
service.interceptors.request.use(
  (config) => {
    config.data = config.data || {};
    config.headers["Request-Id"] = BigInt(
      Math.floor(100000000000000000 + Math.random() * 900000000000000000)
    ).toString();
    config.headers["App-Channel-Id"] = "100101";
    config.headers.Timestamp = Number(new Date());
    var token = Cookies.get("userToken");
    if (token && store?.state?.user?.uid) {
      let uid = sessionStorage.getItem("uid") || "";
      if (uid && uid != "undefined") {
        let vuex = localStorage.getItem("vuex") || "{}";
        let user = JSON.parse(vuex)?.user;
        if (uid != user?.uid) {
          if (!isPageRefresh) {
            const vm = new vue();
            vm.$alert("当前账号已切换，请刷新页面！", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                sessionStorage.setItem("uid", "");
                location.reload();
              },
            });
            isPageRefresh = true;
            return;
          }
        }
      } else {
        sessionStorage.setItem("uid", store?.state?.user?.uid);
      }
    }
    if (token !== null && token !== undefined && token !== "") {
      config.headers["Authorization"] = "Bearer " + token;
      var host = window.location.host;
      config.headers["appHost"] = host;
      config.headers["appId"] = Cookies.get("appId");
    }
    if (config.headers["Content-Type"] === "application/json") {
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
var count = 0;
// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    var token = response.headers.freshtoken;
    if (token) {
      // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
      Cookies.set("userToken", token);
    }
    var userInfo = response.headers.userinfo;
    if (userInfo) {
      Cookies.set("userInfo", decodeURIComponent(userInfo));
    }
    console.log(response);
    if (response.data.code != 0) {
      Message({
        message: response.data.msg,
        type: "error",
        duration: 5 * 1000,
      });
    }
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response.status === 401) {
      if (count === 0) {
        count = count + 1;
      } else if (count > 0) {
        return null;
      }
      //eslint-disable-next-line no-debugger
      Message.error("身份信息超时，请重新登录！", { icon: 1, time: 2000 });
      Cookies.remove("userToken");
      localStorage.clear();
      setTimeout(function () {
        //跳转到登录页
        var host = window.location.host;
        if (host.indexOf("http://") == -1) {
          host = "http://" + host;
        }
        window.location.href = host;
      }, 3000);
    }
    return Promise.reject(error);
  }
);

export default service;
