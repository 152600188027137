
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <el-form :inline="true">
      <el-form-item label="用户编号：">
        <el-input
          v-model.trim="form.userNumber"
          placeholder="请输入用户编号"
          clearable
          maxlength="10"
          oninput="value=value.replace(/[^0-9]/g,'')"
        />
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入手机号"
          clearable
        />
      </el-form-item>
      <el-form-item label="城市：">
        <el-select
          v-model="form.cityCode"
          placeholder="全部"
          multiple
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in restrictedCityList"
            :key="item.cityCode"
            :label="item.cityName"
            :value="item.cityCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button style="margin-right: 15px" type="primary" @click="search"
        >查询</el-button
      >
      <el-button style="margin-right: 15px" type="default" @click="clear"
        >重置</el-button
      >
    </el-form>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>度数钱包</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      :height="tableHeight"
      :data="dataTable"
      show-summary
      :summary-method="getSummaries"
      @sort-change="handleSortChange"
    >
      <el-table-column label="序号" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="first_city_name"
        label="城市"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="available_degree"
        label="度数余额（度）"
        sortable="custom"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_available_degree"
        label="C端度数余额（度）"
        sortable="custom"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="busin_transfer_available_degree"
        label="B端转赠度数余额（度）"
        sortable="custom"
        min-width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_degree_buy_money"
        label="购买度数总金额（元）"
        sortable="custom"
        min-width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_degree"
        label="购买度数合计（度）"
        sortable="custom"
        min-width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="busin_transfer_total_degree"
        label="B端转赠度数合计"
        sortable="custom"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="degree_unit_price"
        label="C端实时度数单价（元/度）"
        sortable="custom"
        min-width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="use_degree"
        label="已使用度数（度）"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total_use_degree_order_num"
        label="累计使用度数下单数"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handelDetails(scope.row)"
            type="text"
            size="small"
            >明细</el-button
          >
          <el-button
            @click="handelRefund(scope.row)"
            type="text"
            size="small"
            v-if="user.isMaintenanceOrder == 1"
            >退回</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="page_index"
      ></el-pagination>
    </el-footer>
  </el-card>
  <userDegreesWalletModal
    ref="detailsModal"
    @getList="getUserDetails"
    :userId="user_id"
  >
  </userDegreesWalletModal>
  <userDegreesRefundModal
    v-if="isUserDegreesRefundModal"
    :selectObj="selectObj"
    @close="isUserDegreesRefundModal = false"
    @submit="loadDataTable"
  />
</div>
