
<el-dialog
  :title="editState == 'edit' ? '编辑' : '新增'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  @close="close"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
    <el-form-item label="名称：" prop="goods_type_name">
      <el-input
        v-model="form.goods_type_name"
        size="small"
        style="width: 300px"
        maxlength="10"
      ></el-input>
    </el-form-item>
    <el-form-item label="图片：" prop="icon_url">
      <img
        v-if="form.icon_url"
        :src="`${IMG_SERVE_URL}${form.icon_url}`"
        class="avatar"
        @click="isImageManagement = true"
      />
      <div class="upload-icon" v-else @click="isImageManagement = true">
        <i class="el-icon-plus"></i>
      </div>
    </el-form-item>
    <el-form-item label="排序：" prop="sort_num">
      <el-input
        v-model="form.sort_num"
        size="small"
        style="width: 300px"
        maxlength="6"
        @input="validateInput('sort_num', $event, 6)"
      ></el-input>
    </el-form-item>
    <el-form-item label="上级分类：" prop="region" v-show="typeGrade > 1">
      <el-select
        v-model="parentId"
        placeholder="上级分类"
        :disabled="true"
        size="small"
        style="width: 300px"
      >
        <el-option
          :key="info.id"
          :label="info.goods_type_name"
          :value="info.id"
          v-for="info in typeAllList"
        ></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button @click="submitForm">确定</el-button>
  </div>
  <ImageManagement
    v-if="isImageManagement"
    rootFilePath="shop"
    @close="isImageManagement = false"
    @sure="uploadImgSuccess"
  />
</el-dialog>
