
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item
            label="发布位置："
            label-width="90px"
            style="margin-bottom: 0"
          >
            <el-select v-model="searchValue.publishLocation">
              <el-option
                v-for="item in publishLocationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>轮播图管理</span>
      <div style="float: right">
        <el-button @click="edit({})">新增轮播图</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        prop="id"
        label="轮播图"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 40px"
            :src="bannerUrl(scope.row.banner_url)"
            fit="fill"
          />
        </template>
      </el-table-column>
      <el-table-column label="跳转类型" min-width="120" align="center">
        <template slot-scope="scope">
          {{ getDictIns("typeList", scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="跳转链接"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.type == 2 ? scope.row.popup_url : scope.row.path }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_price"
        label="发布位置"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          {{ getDictIns("publishLocationList", scope.row.publish_location) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sort"
        label="排序值"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="添加时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: red" @click="delBtn(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <banner-add-modal
    v-if="isAddBannerModal"
    :selectItem="selectItem"
    @close="isAddBannerModal = false"
    @sure="addBannerModalSure"
  />
</div>
