<template>
  <el-dialog
    title="取消订单"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
    @close="close"
  >
    <p class="text-red">*取消原因备注必填</p>
    <el-select
      v-model="reason"
      style="margin-bottom: 20px"
      placeholder="全部"
      clearable
    >
      <el-option
        v-for="item in reasonList"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      ></el-option>
    </el-select>
    <el-input
      v-if="reason == 2"
      v-model="remark"
      type="textarea"
      :rows="4"
      placeholder="请输入取消原因"
    ></el-input>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cancelShopOrderApi } from "../../../api-new/shop-order";

export default {
  data() {
    return {
      dialogVisible: true,
      reason: 1,
      reasonList: [
        {
          id: 1,
          name: "收货地无法包邮",
        },
        {
          id: 2,
          name: "其他",
        },
      ],
      remark: "",
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submitForm() {
      if (this.reason == 2 && !this.remark) {
        this.$message({
          message: "请输入取消原因",
          type: "warning",
        });
        return;
      }
      const res = await cancelShopOrderApi({
        remark: this.reason == 1 ? "收货地无法包邮" : this.remark,
        shop_order_id: this.selectObj.id,
      }).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        this.$message.success("操作成功");
        this.$emit("sure");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-red {
  font-size: 14px;
  color: #ff0000;
  margin-bottom: 20px;
}
</style>