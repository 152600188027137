<template>
  <el-dialog
    title="权限配置"
    :visible.sync="cityConfigDialogVisible"
    width="30%"
    @close="closeDialog"
  >
    <el-tabs v-model="authority">
      <el-tab-pane label="城市配置" name="city">
        <el-form>
          <el-form-item label="城市：">
            <el-checkbox-group
              v-model="cityForm.city_codes"
              :disabled="cityForm.restricted_city == 0"
            >
              <el-checkbox
                v-for="city in cityList"
                :key="city.cityCode"
                :label="city.cityCode"
                >{{ city.cityName }}</el-checkbox
              >
            </el-checkbox-group></el-form-item
          >
          <el-form-item label="是否受限城市：">
            <el-radio-group v-model="cityForm.restricted_city">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="供应商配置" name="supplier">
        <el-form>
          <el-form-item label="供应商：">
            <el-checkbox-group
              v-model="supplierForm.user_rel_suppliers"
              :disabled="supplierForm.restricted_supplier == 0"
            >
              <el-checkbox
                v-for="item in supplierList"
                :key="item.id"
                :label="item.id"
                >{{ item.supplier_name }}</el-checkbox
              >
            </el-checkbox-group></el-form-item
          >
          <el-form-item label="是否受限供应商：">
            <el-radio-group v-model="supplierForm.restricted_supplier">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import OPERATE_API from "@/api/operate_api";
import { queryGoodsSupplierListApi } from "@/api-new/shop-supplier";
import {
  getManageSupplierRestrictedApi,
  updateManageSupplierApi,
} from "@/api-new/user";
export default {
  data() {
    return {
      authority: "city",
      cityConfigDialogVisible: true,
      cityForm: {
        city_codes: [],
        restricted_city: 1,
      },
      cityList: [],
      supplierList: [],
      supplierForm: {
        restricted_supplier: 1,
        user_rel_suppliers: [],
      },
    };
  },
  props: {
    select_user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.getRestrictedCity();
    this.getCityList();
    this.getSupplierList();
    this.getManageSupplierRestricted();
  },
  methods: {
    async getManageSupplierRestricted() {
      const res = await getManageSupplierRestrictedApi({
        user_id: this.select_user.uid,
      });
      if (res.data.code == 0) {
        this.supplierForm = {
          restricted_supplier: res.data.data.restrictedSupplier,
          user_rel_suppliers: res.data.data.userRelSuppliers,
        };
      }
    },
    async getSupplierList() {
      const res = await queryGoodsSupplierListApi({}, 100000, 0);
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        this.supplierList = arr;
      }
    },
    async getCityList() {
      let res = await OPERATE_API.getCityList();
      if (res.data.code != 0) {
        this.$message.error({
          message: "加载城市列表错误",
        });
        return;
      }
      this.cityList = res.data.cityList;
    },

    async getRestrictedCity() {
      this.showLoading("加载中");
      const res = await this.axiosHelper
        .post("/api/user/common/manage/city/restricted/get", {
          user_id: this.select_user.uid,
        })
        .finally(() => {
          this.hideLoading();
        });
      if (res.data.code == 0) {
        this.cityForm.city_codes = res.data.data.cityList;
        this.cityForm.restricted_city = res.data.data.restrictedCity;
      } else {
        this.$message.error("获取用户管理城市信息失败" + "");
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    submit() {
      if (this.authority == "city") {
        this.saveCityConfig();
      }
      if (this.authority == "supplier") {
        this.saveSupplierConfig();
      }
    },
    saveCityConfig() {
      let params = {
        user_id: this.select_user.uid,
        ...this.cityForm,
      };
      this.axiosHelper
        .post("/api/user/common/manage/city/update", params)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$emit("sure");
          }
        })
        .catch(() => {
          this.hideLoading();
          this.$message.error("获取用户管理城市信息失败" + "");
        });
    },
    async saveSupplierConfig() {
      const res = await updateManageSupplierApi({
        user_id: this.select_user.uid,
        ...this.supplierForm,
      });
      if (res.data.code == 0) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
        this.$emit("sure");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>