
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <p class="title">数据筛选</p>
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          label-width="110px"
        >
          <el-row>
            <el-col :span="7">
              <el-form-item label="订单编号：" style="margin-bottom: 0">
                <el-input v-model.trim="searchValue.order_no" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="支付状态：" style="margin-bottom: 0">
                <el-select
                  v-model="searchValue.pay_status"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in paymentStatusDim"
                    :key="item.data_value"
                    :value="item.data_value"
                    :label="item.data_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="用户编号：" style="margin-bottom: 0">
                <el-input
                  v-model.trim="searchValue.userNumber"
                  clearable
                  maxlength="10"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3" style="float: right; text-align: right">
              <el-button type="primary" @click="clickSearch">查询</el-button>
              <el-button type="default" @click="clear">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-button class="filter" type="text" @click="filter"
        >高级查询</el-button
      >
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>支付列表</span>
      <el-button style="float: right" @click="exportOrder">导出</el-button>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
      @selection-change="rowSelected"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_serial_number"
        label="支付流水号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_no"
        label="订单编号"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="viewRow(scope.row)">{{
            scope.row.order_no
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="wechat_no"
        label="微信号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="open_id"
        label="微信ID"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cellphone"
        label="联系方式"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_price"
        label="订单预估金额（元）"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="actual_price"
        label="支付金额（元）"
        min-width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="pay_method_name"
        label="支付方式"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_status_name"
        label="支付状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_time"
        label="支付时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_platform_serial_number"
        label="支付平台流水号"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  <OrderModel
    :dialog.sync="orderModelVisible"
    :id="selectId"
    :status="editState"
  />
</div>
