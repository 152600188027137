
<el-dialog
  :title="editState == 'edit' ? '编辑商品品牌' : '新增商品品牌'"
  width="800px"
  :height="'80%'"
  :max-height="'600px'"
  :close-on-click-modal="false"
  :visible.sync="visible"
  @close="close"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="120px">
    <el-form-item label="商品品牌名称：" prop="goods_brand_name">
      <el-input
        v-model="form.goods_brand_name"
        size="small"
        style="width: 300px"
        maxlength="10"
      ></el-input>
    </el-form-item>
    <el-form-item label="备注" prop="remarks">
      <el-input
        v-model="form.remarks"
        style="width: 300px"
        type="textarea"
        maxlength="140"
      ></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button @click="submitForm">确定</el-button>
  </div>
</el-dialog>
