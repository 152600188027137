
<el-dialog
  title="权限配置"
  :visible.sync="cityConfigDialogVisible"
  width="30%"
  @close="closeDialog"
>
  <el-tabs v-model="authority">
    <el-tab-pane label="城市配置" name="city">
      <el-form>
        <el-form-item label="城市：">
          <el-checkbox-group
            v-model="cityForm.city_codes"
            :disabled="cityForm.restricted_city == 0"
          >
            <el-checkbox
              v-for="city in cityList"
              :key="city.cityCode"
              :label="city.cityCode"
              >{{ city.cityName }}</el-checkbox
            >
          </el-checkbox-group></el-form-item
        >
        <el-form-item label="是否受限城市：">
          <el-radio-group v-model="cityForm.restricted_city">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="供应商配置" name="supplier">
      <el-form>
        <el-form-item label="供应商：">
          <el-checkbox-group
            v-model="supplierForm.user_rel_suppliers"
            :disabled="supplierForm.restricted_supplier == 0"
          >
            <el-checkbox
              v-for="item in supplierList"
              :key="item.id"
              :label="item.id"
              >{{ item.supplier_name }}</el-checkbox
            >
          </el-checkbox-group></el-form-item
        >
        <el-form-item label="是否受限供应商：">
          <el-radio-group v-model="supplierForm.restricted_supplier">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
  <span slot="footer" class="dialog-footer">
    <el-button @click="closeDialog">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
</el-dialog>
