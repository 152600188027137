
<div>
  <el-card class="box-card" style="margin-top: 15px">
    <div slot="header" class="clearfix">
      <span>设备筛选</span>
      <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="search_dialog = true"
        >高级搜索</el-button
      >
    </div>
    <div class="text item">
      <el-form
        ref="form"
        style="padding-right: 10px"
        :rules="rules"
        :model="form"
        label-width="110px"
      >
        <el-row class="search">
          <el-col class="elCol">
            <el-form-item label="设备编号：">
              <el-input
                v-model.trim="form.equipment_no"
                clearable
                placeholder="请输入设备编号"
              />
            </el-form-item>
          </el-col>
          <el-col class="elCol">
            <el-form-item label="城市：">
              <el-select
                v-model="form.city_code"
                placeholder="全国"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in restrictedCityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elCol">
            <el-form-item label="设备状态：">
              <el-select
                v-model="form.equipment_state"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in dim_equipment_state"
                  :key="item.data_value"
                  :value="item.data_value"
                  :label="item.data_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="elCol">
            <el-form-item label="创建时间：">
              <el-date-picker
                class="css-forbid-flash"
                style="width: 100%"
                v-model="form.create_time"
                type="date"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="elColBtn">
            <!-- <el-form-item > -->
            <el-button
              style="margin-right: 15px"
              type="primary"
              @click="searchBtn(form)"
              >查询</el-button
            >
            <el-button type="primary" @click="clear">重置</el-button>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card" style="margin-top: 15px">
    <div>
      <span>设备列表</span>
      <el-button
        style="margin-left: 10px"
        type="default"
        v-if="isMaintenanceOrder == 1"
        @click="openModel('add')"
        >添加</el-button
      >
      <el-button
        type="default"
        @click="deletedAll"
        v-if="isMaintenanceOrder == 1"
        >删除</el-button
      >
      <el-button style="float: right" type="default" @click="exportOrder"
        >导出</el-button
      >
    </div>
    <el-table
      ref="dataTable"
      v-loading="table_loading"
      :height="table_high"
      :data="table_data"
      @selection-change="selectChange"
      style="margin-top: 20px"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        min-width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="equipment_no"
        label="设备编号"
        min-width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            primary
            @click="openModel('browse', scope.row.id)"
            >{{ scope.row.equipment_no }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_name"
        label="设备名称"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="agent_name"
        label="代理商"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_type_name"
        label="设备类型"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_power"
        label="设备功率"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_capacity"
        label="容量（kWh）"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="battery_percentage"
        label="电量百分比"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_state_name"
        label="设备状态"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="availability_name"
        label="可用状态"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="open_self_charge_name"
        label="自助充电状态"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_valid_state"
        label="设备有效状态"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="equipment_org"
        label="归属网点"
        min-width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="二维码"
        min-width="150"
        align="center"
        v-if="isMaintenanceOrder == 1"
      >
        <template slot-scope="scope">
          <div @click="showWxappQrcodeView(scope.row)">
            <vue-qr
              :ref="'qrcode_' + scope.row.id"
              :text="`http://qr.lvxiaoma.cn?scanType=zszc&id=${scope.row.id}`"
              :size="300"
              style="width: 100px"
            ></vue-qr>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="equipment_org"
        label="操作"
        min-width="180"
        align="center"
        fixed="right"
        v-if="isMaintenanceOrder == 1"
      >
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            @click="openModel('edit', scope.row.id)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="text"
            style="color: #ff0000"
            @click="deleted(scope.row.id)"
            >删除</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.valid_state == '1'"
            type="text"
            style="color: #000"
            @click="setValidState(scope.row)"
            >停用</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.valid_state == '0'"
            type="text"
            style="color: #67c23a"
            @click="setValidState(scope.row)"
            >启用</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.open_self_charge == '0'"
            type="text"
            style="color: #67c23a"
            @click="setSelftate(scope.row)"
            >开启自助充电</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.open_self_charge == '1'"
            type="text"
            style="color: #000"
            @click="setSelftate(scope.row)"
            >关闭自助充电</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container" style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </div>
    <base_table
      v-if="false"
      ref="finish_table"
      :setTableHigh="true"
      :data="table_data"
      :columns="data_columns"
      @page-change="pageChange"
      @sort-change="sortChange"
      showCheck
      showIndex
      :tableHigh="table_high"
      @on-select-change="selectChange"
      :showLoading="table_loading"
    ></base_table>
  </el-card>
  <search_model
    :dialog="search_dialog"
    @search="flush"
    :close.sync="search_dialog"
  ></search_model>
  <equipment_model
    :dialog="model_dialog"
    :model_type="type"
    :id="form.id"
    :close.sync="model_dialog"
    :cityList="restrictedCityList"
    @search="flush"
  ></equipment_model>
  <export_data_model
    :dialog="export_dialog"
    :columns="data_columns"
    :close.sync="export_dialog"
    @exportExcel="exportExcel"
  ></export_data_model>
  <qrcode v-if="wxappQrcodeViewVisible" ref="qrcodeDialog" />
</div>
