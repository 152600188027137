<template>
  <el-dialog
    :visible.sync="visible"
    @close="close"
    title="退款详情"
    width="600px"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="退款状态：">
        <div>{{ selectObj.refund_proces_state_name }}</div>
      </el-form-item>
      <el-form-item label="退款原因：">
        <div>{{ selectObj.remark }}</div>
      </el-form-item>
      <el-form-item label="退款图片：" v-if="imgList.length > 0">
        <el-image
          v-for="item in imgList"
          :key="item"
          :src="`${item}`"
          style="width: 100px; height: 100px; maring-right: 10px"
          :preview-src-list="imgList"
        />
      </el-form-item>
      <el-form-item label="商品应退金额：">
        <div>{{ selectObj.apply_refund_money }}</div>
      </el-form-item>
      <el-form-item label="应退金额修改为：" prop="refund_money">
        <p v-if="selectType == 'detail'">{{ selectObj.refund_money }}</p>
        <el-input
          v-else
          v-model="form.refund_money"
          @input="
            validateInput(
              'refund_money',
              $event,
              null,
              2,
              false,
              selectObj.apply_refund_money
            )
          "
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item
        label="退款审核备注："
        v-if="
          (selectType == 'detail' && selectObj.examine_remark != '') ||
          selectType != 'detail'
        "
      >
        <p v-if="selectType == 'detail'">{{ selectObj.examine_remark }}</p>
        <el-input
          v-else
          type="textarea"
          v-model="form.examine_remark"
          style="width: 400px"
        />
      </el-form-item>
      <el-form-item
        label="拒绝退款原因："
        v-if="selectObj.refund_proces_state == '3'"
      >
        <p>{{ selectObj.refusing_refund_reason }}</p>
      </el-form-item>
    </el-form>
    <div class="bottom" v-if="selectType != 'detail'">
      <el-button type="primary" @click="submitForm">同意退款</el-button>
      <el-button @click="rejectBtn">拒绝退款</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  agreeOrderRefundApi,
  refundShopOrderRefuseApi,
} from "../../../api-new/shop-order";
import { IMG_SERVE_URL } from "@/config/index";
import { validateNum } from "@/utils/utils";
export default {
  data() {
    return {
      visible: true,
      form: {
        refund_money: "",
        examine_remark: "",
      },
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {},
    },
    selectType: {
      type: String,
      default: "",
    },
  },
  computed: {
    rules() {
      if (this.selectType != "detail") {
        return {
          refund_money: [
            { required: true, message: "请输入退款金额", trigger: "blur" },
            {
              validator(rule, value, callback) {
                if (value === "") {
                  callback(new Error("请输入退款金额"));
                } else if (Number(value) === 0) {
                  callback(new Error("退款金额不可等于0"));
                } else {
                  callback();
                }
              },
            },
          ],
        };
      }
      return {};
    },
    imgList() {
      if (this.selectObj.refund_picture) {
        let pic_content = this.selectObj.refund_picture.split(",");
        pic_content = pic_content.map((item) => `${IMG_SERVE_URL}${item}`);
        return pic_content;
      }
      return [];
    },
  },
  methods: {
    validateInput(type, e, precision, scale, isOwe, max) {
      this.form[type] = validateNum(e, precision, scale, isOwe, max);
    },
    close() {
      this.$emit("close");
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        let res = await agreeOrderRefundApi({
          ...this.form,
          id: this.selectObj.id,
        }).finally(() => {
          this.hideLoading();
        });
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$emit("sure");
        }
      });
    },
    rejectBtn() {
      this.$prompt("请输入拒绝原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputErrorMessage: "请输入拒绝原因",
      })
        .then(async ({ value }) => {
          this.showLoading("加载中");
          let res = await refundShopOrderRefuseApi({
            refusing_refund_reason: value,
            id: this.selectObj.id,
            examine_remark: this.form.examine_remark,
          }).finally(() => {
            this.hideLoading();
          });
          if (res.data.code == 0) {
            this.$message.success("操作成功");
            this.$emit("sure");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>