<template>
  <el-dialog
    title="物流信息"
    :visible.sync="islogistics"
    width="520px"
    class="logistics"
    :close-on-click-modal="false"
    @close="close"
  >
    <div class="logistics-content">
      <div class="topgoods">
        <div class="wlinfo">
          <section>
            <div>
              <span>订单ID:</span>
              <span>{{ selectObj.order_no }}</span>
            </div>
            <div>
              <span>发货时间:</span>
              <span>{{ selectObj.create_time }}</span>
            </div>
          </section>
          <section>
            <div>
              <span>物流单号:</span>
              <span>{{ selectObj.courier_number }}</span>
              <i
                class="el-icon-document-copy"
                @click="copy(selectObj.courier_number)"
              />
            </div>
            <div>
              <span>物流公司:</span>
              <span>{{ selectObj.express_company }}</span>
            </div>
          </section>
        </div>
      </div>
      <div class="wllist">
        <div v-for="(item, i) in wllog" :key="i" class="listinfo">
          <div class="time">{{ item.time }}</div>
          <div class="progress">
            <div class="ring" />
            <div class="bar" />
          </div>
          <div class="txt" v-html="item.context" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      islogistics: true,
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    wllog() {
      if (this.selectObj?.express_result?.lastResult?.status == "200") {
        return this.selectObj?.express_result?.lastResult?.data || [];
      }
      return [];
    },
  },
  methods: {
    copy(text) {
      this.$copyText(text)
        .then((e) => {
          this.$message.success("已复制到剪贴板");
        })
        .catch((e) => {
          this.$message.error("复制失败，请手动复制");
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.logistics {
  cursor: default;
  .logistics-content {
    background: #fff;
    border-radius: 9px;
    .topgoods {
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      .goods {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        img {
          width: 96px;
          height: 96px;
          border-radius: 5px;
          margin-right: 10px;
        }
        .info {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          h3 {
            font-size: 16px;
            font-weight: 400;
            font-family: SourceHanSansCN-Medium;
            color: #000000;
            margin: 0 0 10px;
          }
          div {
            color: #000000;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            margin-bottom: 10px;
            line-height: 22px;
          }
          span {
            color: #bababa;
          }
        }
      }
      .wlinfo {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #a9a9a9;
        section {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          div {
            width: 48%;
            display: flex;
            align-items: flex-start;
            i {
              line-height: 22px;
            }
            span {
              line-height: 22px;
            }
            span + span {
              max-width: 65%;
              color: #606060;
              padding: 0 5px;
            }
            i {
              cursor: pointer;
            }
          }
        }
        section + section {
          margin-top: 20px;
          div {
            span + span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .wllist {
      padding: 20px;
      height: 240px;
      overflow-y: auto;
      & > div:nth-last-child(1) {
        .progress {
          .bar {
            display: none;
          }
        }
      }
      .listinfo {
        display: flex;
        align-items: flex-start;
        .time {
          width: 27%;
          font-size: 12px;
          font-family: DINPro;
          font-weight: bold;
          color: #8d8d8d;
          text-align: center;
          margin-top: 5px;
          line-height: 14px;
        }
        .progress {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          .ring {
            width: 27px;
            height: 27px;
            border: 1px solid #000000;
            opacity: 0.28;
            border-radius: 50%;
          }
          .firstring {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            width: 25px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("https://oss.fjlqq.com/wxapp/static/lqq_pc/shdzbg.png")
              no-repeat;
            background-size: 100%;
          }
          .bar {
            margin: 5px 0;
            height: 70px;
            border: 1px dashed #d4d4d4;
          }
        }
        .txt {
          width: 127%;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          // color: #6B6B6B;
          color: #121212;
          line-height: 24px;
          padding-bottom: 20px;
          span {
            // color: #FF7582;
            color: #ff1e3e;
          }
        }
      }
    }
  }
}
</style>