
<div>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>商品分类</span>
      <el-button
        style="float: right"
        @click="showGoodsTypeDialog('', 0, '-1')"
        >新增分类</el-button
      >
    </el-row>
    <div class="table-wrapper" v-if="false">
      <div class="table-th">
        <span class="type-name">分类名称</span>
        <span class="flex-1">排序</span>
        <span class="flex-2">分类图标</span>
        <span class="flex-4">操作</span>
      </div>
      <!-- 一级类型 -->
      <vuedraggable
        v-model="typeList"
        @change="setGoodsTypeSortNum(typeList)"
      >
        <div
          class="table-tr"
          v-for="(typeInfo, pIndex) in typeList"
          :key="typeInfo.id"
        >
          <div class="table-tr-parent">
            <div class="type-name">
              <i
                class="icon iconfont iconzhankai"
                @click="openChild(pIndex, '', 1)"
                v-show="pIndex !== firstIndex"
              ></i>
              <i
                class="icon iconfont iconshouqi mr_10"
                @click="openChild(pIndex, '', 1)"
                v-show="pIndex === firstIndex"
              ></i>
              <span>{{ typeInfo.name }}</span>
            </div>
            <div class="flex-1">{{ typeInfo.sortNum }}</div>
            <div class="flex-2">
              <span
                v-show="typeInfo.iconUrl === ''"
                class="icon iconfont iconshangchuantupian"
                style="font-size: 23px"
              ></span>
              <img
                :src="typeInfo.iconTotalUrl"
                alt
                v-show="typeInfo.iconUrl !== ''"
                style="width: 23px; height: 23px"
              />
            </div>
            <div class="flex-4">
              <span
                class="edit"
                v-show="typeInfo.typeGrade < 3"
                @click="
                  showGoodsTypeDialog('', typeInfo.typeGrade, typeInfo.id)
                "
                >新增下级</span
              >
              <span
                class="edit"
                @click="
                  showGoodsTypeDialog(
                    typeInfo.id,
                    typeInfo.typeGrade,
                    typeInfo.parentId
                  )
                "
                >修改</span
              >
              <span class="del" @click="deleteGoodsType(typeInfo.id)"
                >删除</span
              >
            </div>
          </div>
          <!-- 二级类型 -->
          <vuedraggable
            v-model="typeInfo.children"
            v-show="firstIndex === pIndex"
            @change="setGoodsTypeSortNum(typeInfo.children)"
          >
            <div
              class="table-tr-child"
              v-for="(childInfo, cIndex) in typeInfo.children"
              :key="childInfo.id"
            >
              <div class="table-tr-parent">
                <div class="type-name">
                  <span class="border-left-bottom"></span>
                  <i
                    class="icon iconfont iconzhankai"
                    @click="openChild('', cIndex, 2)"
                    v-show="cIndex !== secondIndex"
                  ></i>
                  <i
                    class="icon iconfont iconshouqi mr_10"
                    @click="openChild('', cIndex, 2)"
                    v-show="cIndex === secondIndex"
                  ></i>
                  <span>{{ childInfo.name }}</span>
                </div>
                <div class="flex-1">{{ childInfo.sortNum }}</div>
                <div class="flex-2">
                  <span
                    v-show="childInfo.iconUrl === ''"
                    class="icon iconfont iconshangchuantupian"
                    style="font-size: 23px"
                  ></span>
                  <img
                    :src="childInfo.iconTotalUrl"
                    alt
                    v-show="childInfo.iconUrl !== ''"
                    style="width: 23px; height: 23px"
                  />
                </div>
                <div class="flex-4">
                  <span
                    class="edit"
                    v-show="childInfo.typeGrade < 2"
                    @click="
                      showGoodsTypeDialog(
                        '',
                        childInfo.typeGrade,
                        childInfo.id
                      )
                    "
                    >新增下级</span
                  >
                  <span
                    class="edit"
                    @click="
                      showGoodsTypeDialog(
                        childInfo.id,
                        childInfo.typeGrade,
                        childInfo.parentId
                      )
                    "
                    >修改</span
                  >
                  <span class="del" @click="deleteGoodsType(childInfo.id)"
                    >删除</span
                  >
                </div>
              </div>
            </div>
          </vuedraggable>
          <!-- 二级类型 -->
        </div>
      </vuedraggable>
    </div>
    <el-table
      :data="typeList"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="goods_type_name" label="分类名称">
      </el-table-column>
      <el-table-column prop="sort_num" label="排序"> </el-table-column>
      <el-table-column label="分类图标">
        <template slot-scope="scope">
          <img
            class="icon"
            v-if="scope.row.icon_url"
            :src="`${IMG_SERVE_URL}${scope.row.icon_url}`"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="scope.row.type_grade == 1"
            @click="
              showGoodsTypeDialog('', scope.row.type_grade, scope.row.id)
            "
            >新增下级</el-button
          >
          <el-button
            type="text"
            @click="
              showGoodsTypeDialog(
                scope.row.id,
                scope.row.type_grade,
                scope.row.parent_id
              )
            "
            >修改</el-button
          >
          <el-button type="text" @click="deleteGoodsType(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <editGoodsClassflyDialog
    :activityId="activityId"
    :typeGrade="typeGrade"
    :parentId="parentId"
    :typeAllList="typeAllList"
    v-if="goodsTypeDialog"
    @close="goodsTypeDialog = false"
    @submit="getGoodsTypeList"
  />
</div>
