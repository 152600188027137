
<div class="height:100%;">
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item
            label="创建时间"
            label-width="100px"
          >
            <el-date-picker
              v-model="createTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              style="width: auto"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="商品名称："
            label-width="90px"
          >
            <el-input
              v-model="searchValue.goods_name"
              placeholder="请输入商品名称"
              clearable
            />
          </el-form-item>
          <el-form-item
            label="商品类目："
            label-width="90px"
          >
            <el-cascader
              v-model="goods_type_id"
              :options="typeList"
              :props="{
                value: 'id',
                label: 'goods_type_name',
                children: 'children',
              }"
              :show-all-levels="false"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="商品状态："
            label-width="90px"
          >
            <el-select
              v-model="searchValue.shelves"
              placeholder="全部"
              clearable
            >
              <el-option
                v-for="item in shelvesList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商：" label-width="90px">
            <el-select
              v-model="searchValue.goods_supplier_id"
              placeholder="全部"
              clearable
            >
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :value="item.id"
                :label="item.supplier_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>商品列表</span>
      <div style="float: right">
        <el-button @click="addGoods">新增商品</el-button>
        <el-button @click="exportOrder">导出</el-button>
        <el-button @click="exportShelves('1')" v-if="isShelvesAuthority"
          >批量上架</el-button
        >
        <el-button @click="exportShelves('0')">批量下架</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      @selection-change="rowSelected"
      border
    >
      <el-table-column
        fixed="left"
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="商品ID"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_name"
        label="商品名称"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_brand_name"
        label="品牌"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="supplier_name"
        label="供应商"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_price"
        label="价格"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_underline_price"
        label="划线价"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cost_price"
        label="进货价"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="inventory"
        label="库存"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="shelves_name"
        label="商品状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" min-width="150" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">{{
            scope.row.shelves == "1" ? "查看" : "编辑"
          }}</el-button>
          <el-button
            type="text"
            v-if="isShelvesAuthority || scope.row.shelves == '1'"
            @click="shelvesBtn(scope.row)"
            >{{ scope.row.shelves == "1" ? "下架" : "上架" }}</el-button
          >
          <el-button
            type="text"
            style="color: red"
            @click="delGoodBtn(scope.row)"
            v-if="scope.row.shelves != '1'"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
</div>
