<template>
  <el-dialog
    :title="editState == 'edit' ? '编辑' : '新增'"
    width="800px"
    :height="'80%'"
    :max-height="'600px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="名称：" prop="goods_type_name">
        <el-input
          v-model="form.goods_type_name"
          size="small"
          style="width: 300px"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片：" prop="icon_url">
        <img
          v-if="form.icon_url"
          :src="`${IMG_SERVE_URL}${form.icon_url}`"
          class="avatar"
          @click="isImageManagement = true"
        />
        <div class="upload-icon" v-else @click="isImageManagement = true">
          <i class="el-icon-plus"></i>
        </div>
      </el-form-item>
      <el-form-item label="排序：" prop="sort_num">
        <el-input
          v-model="form.sort_num"
          size="small"
          style="width: 300px"
          maxlength="6"
          @input="validateInput('sort_num', $event, 6)"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级分类：" prop="region" v-show="typeGrade > 1">
        <el-select
          v-model="parentId"
          placeholder="上级分类"
          :disabled="true"
          size="small"
          style="width: 300px"
        >
          <el-option
            :key="info.id"
            :label="info.goods_type_name"
            :value="info.id"
            v-for="info in typeAllList"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submitForm">确定</el-button>
    </div>
    <ImageManagement
      v-if="isImageManagement"
      rootFilePath="shop"
      @close="isImageManagement = false"
      @sure="uploadImgSuccess"
    />
  </el-dialog>
</template>

<script>
import { IMG_SERVE_URL } from "@/config/index";
import { addGoodsTypeApi, updateGoodsTypeApi } from "@/api-new/goods";
import { validateNum } from "@/utils/utils";
export default {
  data() {
    return {
      visible: true,
      form: {
        goods_type_name: "",
        icon_url: "",

        sort_num: 0,
      },
      isImageManagement: false,
    };
  },
  computed: {
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
    editState() {
      return this.activityId ? "edit" : "add";
    },
    rules() {
      if (this.typeGrade == 1) {
        return {
          goods_type_name: [
            { required: true, message: "请输入分类名称", trigger: "blur" },
          ],
          icon_url: [
            {
              required: true,
              message: "请上传图片",
              trigger: "blur",
            },
          ],
        };
      } else {
        return {};
      }
    },
  },
  props: {
    activityId: {
      type: String,
      default: "",
    },
    typeGrade: {
      type: Number,
      default: 1,
    },
    parentId: {
      type: String,
      default: "",
    },
    typeAllList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (this.activityId) {
      let obj = this.typeAllList.find((item) => item.id == this.activityId);
      this.form.goods_type_name = obj.goods_type_name;
      this.form.icon_url = obj.icon_url;
      this.form.sort_num = obj.sort_num;
      console.log(obj);
      // this.form = ;
    }
  },
  methods: {
    validateInput(type, e) {
      this.form[type] = validateNum(e);
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        let res;
        this.showLoading("加载中");
        if (this.editState == "add") {
          res = await addGoodsTypeApi({
            ...this.form,
            parent_id: this.parentId,
            type_grade: this.typeGrade,
          }).finally(() => {
            this.hideLoading();
          });
        } else {
          res = await updateGoodsTypeApi({
            ...this.form,
            parent_id: this.parentId,
            type_grade: this.typeGrade,
            id: this.activityId,
          }).finally(() => {
            this.hideLoading();
          });
        }
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$emit("submit");
        }
      });
    },
    close() {
      this.$emit("close");
    },
    uploadImgSuccess(imgUrl) {
      this.form.icon_url = imgUrl;
      this.isImageManagement = false;
      console.log(imgUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 30px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>