import request from "@/utils/request";

/**
 * @description: 商城下单
 * @param {Object} data 参数对象
 * @param {String} data.address_id 订单地址
 * @param {String} data.goods_id 商品id
 * @param {String} data.goods_sku_id	商品sku的id
 * @param {String} data.num	购买数量
 * @param {String} data.remark	备注
 * @return {*}
 */

export function addShopOrderApi(data) {
  return request({
    url: "/api/shop/order/add",
    method: "post",
    data,
  });
}

/**
 * @description: 同意退款
 * @param {Object} data 参数对象
 * @param {String} data.examine_remark 审核备注
 * @param {String} data.id 订单退款条目id
 * @param {String} data.refund_money 退款金额
 * @return {*}
 */

export function agreeOrderRefundApi(data) {
  return request({
    url: "/api/shop/order/agree/refund",
    method: "post",
    data,
  });
}

/**
 * @description: 确认收货
 * @param {Object} data 参数对象
 * @param {String} data.id 订单id
 * @return {*}
 */

export function confirmOrderReceiptApi(data) {
  return request({
    url: "/api/shop/order/confirm/receipt",
    method: "post",
    data,
  });
}

/**
 * @description: 查询订单数据
 * @param {Object} data 参数对象
 * @param {String} data.create_time_begin 查询开始时间
 * @param {String} data.create_time_end 查询结束时间
 * @return {*}
 */

export function getShopOrderDataListApi(data) {
  return request({
    url: "/api/shop/order/data/list",
    method: "post",
    data,
  });
}
/**
 * @description: 查询订单数据合计
 * @param {Object} data 参数对象
 * @param {String} data.create_time_begin 查询开始时间
 * @param {String} data.create_time_end 查询结束时间
 * @return {*}
 */

export function getShopOrderDataTotalApi(data) {
  return request({
    url: "/api/shop/order/data/total",
    method: "post",
    data,
  });
}
/**
 * @description: 删除商城订单
 * @param {Object} data 参数对象
 * @param {String} data.id 订单id
 * @return {*}
 */

export function deleteShopOrderApi(data) {
  return request({
    url: "/api/shop/order/delete",
    method: "post",
    data,
  });
}
/**
 * @description: 查询物流信息
 * @param {Object} data 参数对象
 * @param {String} data.id 订单id
 * @return {*}
 */

export function getShopOrderExpressApi(data) {
  return request({
    url: "/api/shop/order/express/get",
    method: "post",
    data,
  });
}
/**
 * @description: 分页查询物流信息
 * @param {Object} data 参数对象
 * @param {String} data.create_time_begin 发货开始时间
 * @param {String} data.create_time_end 发货结束时间
 * @param {String} data.search_info 搜索信息
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function queryShopOrderExpressApi(data, limit, offset) {
  return request({
    url: `/api/shop/order/express/query?limit=${limit}&offset=${offset}`,
    method: "post",
    data,
  });
}
/**
 * @description: 获取商城订单信息
 * @param {Object} data 参数对象
 * @param {String} data.id 订单id
 * @return {*}
 */

export function getShopOrderApi(data) {
  return request({
    url: "/api/shop/order/get",
    method: "post",
    data,
  });
}
/**
 * @description: 分页查询商城订单
 * @param {Object} data 参数对象
 * @param {String} data.create_time_begin 下单开始时间
 * @param {String} data.create_time_end 下单结束时间
 * @param {String} data.display_status 显示状态 0:显示 1:隐藏 默认为0
 * @param {String} data.goods_name 商品名称
 * @param {String} data.order_no 订单编号
 * @param {String} data.goods_supplier_id 商品供应商id
 * @param {String} data.order_status 订单状态（1：待付款 2：待发货 3：待收货 4：已完成 5：已取消 6:退款订单）
 * @param {String} data.search_info 搜索信息
 * @param {String} data.user_id 用户id
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function queryShopOrderApi(data, limit, offset) {
  return request({
    url: `/api/shop/order/query?limit=${limit}&offset=${offset}`,
    method: "post",
    data,
  });
}
/**
 * @description: 分页查询退款信息
 * @param {Object} data 参数对象
 * @param {String} data.create_time_begin 发货开始时间
 * @param {String} data.create_time_end 发货结束时间
 * @param {String} data.goods_supplier_id 商品供应商id
 * @param {String} data.refund_state 退款状态(0:待退款,1:正在退款,2:退款完成,3:已取消,4:退款异常)
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function queryShopOrderRefundApi(data, limit, offset) {
  return request({
    url: `/api/shop/order/refund/query?limit=${limit}&offset=${offset}`,
    method: "post",
    data,
  });
}
/**
 * @description: 拒绝退款
 * @param {Object} data 参数对象
 * @param {String} data.examine_remark 审核备注
 * @param {String} data.id 订单退款条目id
 * @param {String} data.refund_money 退款金额
 * @return {*}
 */

export function refundShopOrderRefuseApi(data) {
  return request({
    url: "/api/shop/order/refuse/refund",
    method: "post",
    data,
  });
}
/**
 * @description: 订单发货
 * @param {Object} data 参数对象
 * @param {String} data.courier_number 快递单号
 * @param {String} data.delivery_type 配送类型(1:快递)
 * @param {String} data.express_company 快递公司
 * @param {String} data.express_company_code 快递公司编码
 * @param {String} data.shop_order_id 商城订单id
 * @return {*}
 */

export function shopOrderSendOutGoodsApi(data) {
  return request({
    url: "/api/shop/order/send/out/goods",
    method: "post",
    data,
  });
}

/**
 * @description: 取消订单
 * @param {Object} data 参数对象
 * @param {String} data.remark 备注
 * @param {String} data.shop_order_id 商品订单id
 * @return {*}
 */

export function cancelShopOrderApi(data) {
  return request({
    url: "/api/wxPay/shop/cancel/order",
    method: "post",
    data,
  });
}
