<template>
  <div class="height:100%;">
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>供应商管理</span>
        <div style="float: right">
          <el-button @click="edit({})">新增供应商</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          type="index"
          label="序号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="supplier_name"
          label="供应商名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contacts"
          label="联系人"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone_number"
          label="手机"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" style="color: red" @click="delBtn(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <add-supplier-dialog
      v-if="isAddDialog"
      :selectItem="selectItem"
      @close="isAddDialog = false"
      @submit="addDialogSure"
    />
  </div>
</template>
<script>
import moment from "moment";
import addSupplierDialog from "./add-supplier-dialog.vue";
import {
  deleteGoodsSupplierApi,
  queryGoodsSupplierListApi,
} from "@/api-new/shop-supplier";
export default {
  components: { addSupplierDialog },
  data() {
    return {
      searchValue: {
        create_time_end: "",
        create_time_start: "",
        goods_brand_name: "",
      },
      dataTable: [],
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      typeList: [],
      allTypeList: [],
      total: 0,
      vloading: false,
      selectItem: {},
      isAddDialog: false,
    };
  },
  computed: {},
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getSupplierList();
  },

  methods: {
    addDialogSure() {
      this.isAddDialog = false;
      this.clickSearch();
    },
    edit(item) {
      this.selectItem = item;
      this.isAddDialog = true;
    },
    async delBtn(item) {
      await this.$confirm("请确认是否删除该供应商?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.showLoading();
      const res = await deleteGoodsSupplierApi({
        id: item.id,
      }).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        this.$message.success("删除成功");
        this.getSupplierList();
      }
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getSupplierList();
    },
    async getSupplierList() {
      let offset = (this.pageIndex - 1) * this.pageSize;
      let limit = this.pageSize;
      this.vloading = true;
      const res = await queryGoodsSupplierListApi(
        {
          ...this.searchValue,
        },
        limit,
        offset
      ).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        this.dataTable = arr;
        this.total = res.data.data.total;
      }
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getSupplierList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getSupplierList();
    },
    clear() {
      this.searchValue = {
        create_time_end: "",
        create_time_start: "",
        goods_brand_name: "",
      };
      this.clickSearch();
    },
  },
};
</script>
<style  lang="scss" scoped >
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
</style>