import request from "@/utils/request";

/**
 * @description: 新增商品供应商
 * @param {Object} data 参数对象
 * @param {String} data.address 地址
 * @param {String} data.contacts 联系人
 * @param {String} data.id	主键id,添加的时候不用传，更新时必传
 * @param {String} data.phone_number	手机号
 * @param {String} data.remark	备注
 * @param {String} data.supplier_name	供应商名称
 * @return {*}
 */

export function addGoodsSupplierApi(data) {
  return request({
    url: "/api/goods/supplier/add",
    method: "post",
    data,
  });
}

/**
 * @description: 删除商品供应商
 * @param {Object} data 参数对象
 * @param {String} data.id	主键id
 * @return {*}
 */

export function deleteGoodsSupplierApi(data) {
  return request({
    url: "/api/goods/supplier/delete",
    method: "post",
    data,
  });
}

/**
 * @description: 获取商品供应商列表
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end	创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start	创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.supplier_name	供应商名称
 * @return {*}
 */

export function getGoodsSupplierListApi(data) {
  return request({
    url: "/api/goods/supplier/list",
    method: "post",
    data,
  });
}

/**
 * @description: 分页查询商品供应商
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end	创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start	创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.supplier_name	供应商名称
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function queryGoodsSupplierListApi(data, limit, offset) {
  return request({
    url: "/api/goods/supplier/query",
    method: "post",
    data,
    params: {
      limit,
      offset,
    },
  });
}

/**
 * @description: 分页查询商品供应商
 * @param {Object} data 参数对象
 * @param {String} data.address 地址
 * @param {String} data.contacts 联系人
 * @param {String} data.id	主键id,添加的时候不用传，更新时必传
 * @param {String} data.phone_number	手机号
 * @param {String} data.remark	备注
 * @param {String} data.supplier_name	供应商名称
 * @return {*}
 */

export function updateGoodsSupplierListApi(data) {
  return request({
    url: "/api/goods/supplier/update",
    method: "post",
    data,
  });
}
