<template>
  <el-dialog
    title="退回"
    width="800px"
    :height="'80%'"
    :max-height="'600px'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="退回度数：" prop="degree">
        <el-input
          v-model="form.degree"
          size="small"
          style="width: 300px"
          maxlength="10"
          @input="validateInput('degree', $event, 8, 2)"
        ></el-input>
      </el-form-item>
      <el-form-item label="退回金额：" prop="degree_buy_money">
        <el-input
          v-model="form.degree_buy_money"
          size="small"
          style="width: 300px"
          maxlength="10"
          @input="validateInput('degree_buy_money', $event, 8, 2)"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { validateNum } from "@/utils/utils";
import { retrunDegreeApi } from "@/api-new/userAccount";
export default {
  data() {
    return {
      visible: true,
      form: {
        degree: "",
        degree_buy_money: "",
      },
      rules: {
        degree: [
          { required: true, message: "请输入退回度数", trigger: "blur" },
        ],
        degree_buy_money: [
          { required: true, message: "请输入退回金额", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {},
  methods: {
    validateInput(type, e, precision, scale) {
      this.form[type] = validateNum(e, precision, scale);
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.showLoading("加载中");
        const res = await retrunDegreeApi({
          ...this.form,
          user_id: this.selectObj.user_id,
        }).finally(() => {
          this.hideLoading();
        });
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$emit("submit");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 30px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
</style>