<template>
  <div>
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item label="订单编号：" label-width="90px">
              <el-input
                v-model="searchValue.order_no"
                placeholder="请输入订单编号"
                clearable
              />
            </el-form-item>
            <el-form-item label="下单时间" label-width="100px">
              <div class="flex">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  style="width: auto"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="商品名称：" label-width="90px">
              <el-input
                v-model="searchValue.goods_name"
                placeholder="请输入商品名称"
                clearable
              />
            </el-form-item>
            <el-form-item label="订单状态：" label-width="90px">
              <el-select
                v-model="searchValue.order_status"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商：" label-width="90px">
              <el-select
                v-model="searchValue.goods_supplier_id"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.supplier_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>订单列表</span>
        <div style="float: right">
          <el-button @click="exportOrder">导出</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          prop="order_no"
          label="订单编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品信息"
          min-width="260"
          align="center"
        >
          <template slot-scope="scope">
            <div class="goods-info">
              <el-image class="goods-info-img" :src="goodsImg(scope.row)" />
              <div class="goods-main">
                <div class="goods-main-top">
                  <p class="goods-name">{{ scope.row.goods_name }}</p>
                  <p class="goods-num">x{{ scope.row.num }}</p>
                </div>
                <p class="price">￥{{ scope.row.goods_price }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_code"
          label="商品编码"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="supplier_name"
          label="供应商"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_price"
          label="订单总金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cost_price_total"
          label="订单成本总价"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actual_price"
          label="订单支付金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="买家/收货人"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="配送方式"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">{{
            scope.row.express_company || ""
          }}</template>
        </el-table-column>
        <el-table-column
          prop="status_name"
          label="订单状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="订单创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_time"
          label="订单支付时间"
          min-width="150"
          align="center"
        ></el-table-column>

        <el-table-column
          label="操作"
          fixed="right"
          min-width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                scope.row.order_status == 2 && scope.row.refund_state == '0'
              "
              @click="delOrder(scope.row)"
              >取消订单</el-button
            >
            <el-button type="text" @click="orderDetail(scope.row)"
              >订单详情</el-button
            >
            <el-button
              type="text"
              v-if="
                scope.row.order_status == 2 && scope.row.refund_state == '0'
              "
              @click="shelvesBtn(scope.row)"
              >发货</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <send-out-goods-dialog
      v-if="isSendOutGoodsDialog"
      :selectObj="selectObj"
      @close="isSendOutGoodsDialog = false"
      @sure="sendOutGoods"
    />
    <order-detail-dialog
      v-if="isOrderDetailDialog"
      :selectObj="selectObj"
      @close="isOrderDetailDialog = false"
    />
    <cancelOrderDialog
      v-if="isCancelOrderDialog"
      :selectObj="selectObj"
      @close="isCancelOrderDialog = false"
      @sure="cancelOrder"
    />
  </div>
</template>

<script>
import {
  queryShopOrderApi,
  getShopOrderApi,
  cancelShopOrderApi,
} from "@/api-new/shop-order";
import moment from "moment";
import { orderStatus } from "@/enum/shopOrderEnum";
import SendOutGoodsDialog from "./send-out-goods-dialog.vue";
import OrderDetailDialog from "./order-detail-dialog.vue";
import cancelOrderDialog from "./cancel-order-dialog.vue";
import { operateAdd } from "@/api/system/user-api";
import { IMG_SERVE_URL } from "@/config/index";
import { getSupplierRestrictedListApi } from "@/api-new/user";
export default {
  data() {
    return {
      searchValue: {
        create_time_begin: "",
        create_time_end: "",
        search_info: "",
        goods_name: "",
        goods_supplier_id: "",
        order_status: "",
        source: "pc",
      },
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      total: 0,
      dataTable: [],
      vloading: false,
      tableHeight: "70vh",
      selectObj: {},
      isSendOutGoodsDialog: false,
      isOrderDetailDialog: false,
      isCancelOrderDialog: false,
      supplierList: [],
      statusList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "待付款",
        },
        {
          id: 2,
          name: "待发货",
        },
        {
          id: 3,
          name: "待收货",
        },
        {
          id: 4,
          name: "已完成",
        },
        {
          id: 5,
          name: "已取消",
        },
        {
          id: 6,
          name: "退款订单",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return [
          this.searchValue.create_time_begin,
          this.searchValue.create_time_end,
        ];
      },
      set(val) {
        this.searchValue.create_time_begin = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.searchValue.create_time_end = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },

  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getDataList();
    this.getSupplierRestrictedList();
  },
  methods: {
    async getSupplierRestrictedList() {
      const res = await getSupplierRestrictedListApi();
      if (res.data.code == 0) {
        this.supplierList = res.data.data;
      }
    },
    async delOrder(item) {
      this.selectObj = item;
      this.isCancelOrderDialog = true;
    },
    goodsImg(item) {
      let pic_content = JSON.parse(item.pic_content || "[]") || [];
      let smallImage =
        pic_content.find((it) => {
          return it.type == "2";
        }) || {};
      return `${IMG_SERVE_URL}${smallImage.url}`;
    },
    sendOutGoods() {
      this.isSendOutGoodsDialog = false;
      this.getDataList();
    },
    cancelOrder() {
      this.isCancelOrderDialog = false;
      this.getDataList();
    },
    orderDetail(item) {
      this.selectObj = item;
      this.isOrderDetailDialog = true;
    },
    shelvesBtn(item) {
      this.selectObj = item;
      this.isSendOutGoodsDialog = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async getDataList() {
      this.vloading = true;
      const res = await queryShopOrderApi(
        {
          ...this.searchValue,
        },
        this.pageSize,
        (this.pageIndex - 1) * this.pageSize
      ).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let array = res.data.data.items || [];
        array.forEach((item) => {
          item.status_name = this.getStatusName(item);
          item.cost_price_total = (item.cost_price * item.num).toFixed(2);
        });
        this.dataTable = array;
        this.total = res.data.data.total;
      }
    },
    getStatusName(item) {
      if (item.refund_proces_state == "1") {
        return "正在退款";
      }
      if (item.refund_proces_state == "3") {
        return "退款失败";
      }
      if (item.refund_state == "2") {
        return "退款完成";
      }
      return orderStatus[item.order_status];
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getDataList();
    },
    clear() {
      this.searchValue = {
        create_time_begin: "",
        create_time_end: "",
        search_info: "",
        goods_name: "",
        order_status: "",
      };
      this.clickSearch();
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getDataList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getDataList();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 42,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      await this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      if (this.total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("数据加载中...");
      const res = await queryShopOrderApi(
        {
          ...this.searchValue,
        },
        20000,
        0
      ).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        arr.forEach((item) => {
          item.status_name = this.getStatusName(item);
          item.delivery_type_name = item.express_company || "/";
          item.cost_price_total = (item.cost_price * item.num).toFixed(2);
        });
        this.exportData(arr);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "订单编号",
          "商品名称",
          "商品数量",
          "单价",
          "商品编码",
          "订单总金额",
          "订单成本总价",
          "订单支付金额",
          "买家/收货人",
          "配送方式",
          "订单状态",
          "订单创建时间",
          "订单支付时间",
          "供应商",
          "备注",
        ];
        const filterVal = [
          "order_no",
          "goods_name",
          "num",
          "goods_price",
          "goods_code",
          "pay_price",
          "cost_price_total",
          "actual_price",
          "real_name",
          "delivery_type_name",
          "status_name",
          "create_time",
          "pay_time",
          "supplier_name",
          "remark",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "订单管理列表导出",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  components: { SendOutGoodsDialog, OrderDetailDialog, cancelOrderDialog },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
.goods-info {
  width: 100%;
  overflow: hidden;
  display: flex;
  .goods-info-img {
    width: 70px;
    height: 70px;
  }
  .goods-main {
    margin-left: 20px;
    flex: 1;
    overflow: hidden;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .goods-main-top {
      display: flex;
      overflow: hidden;
      .goods-name {
        font-size: 12px;
        color: #606266;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      .goods-num {
        font-size: 12px;
        color: #606266;
        margin-left: 30px;
      }
    }
    .price {
      color: #606266;
      text-align: left;
    }
  }
}
.flex {
  display: flex;
}
</style>