
<div>
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item
            label="统计时间："
            label-width="90px"
            style="margin-bottom: 0"
          >
            <div class="flex">
              <el-select v-model="searchValue.timeId">
                <el-option
                  v-for="item in timeList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
              <el-date-picker
                v-model="searchValue.create_time"
                :type="searchValue.timeId == '1' ? 'date' : 'month'"
                placeholder="选择日期"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <div class="total">
      <div
        v-for="(item, index) in totalList"
        :style="{ background: item.background }"
        class="total-item"
        :key="index"
      >
        <div class="total-item-main">
          <p class="total-item-main-name">{{ item.name }}</p>
          <p class="total-item-main-price">{{ item.price }}</p>
        </div>
      </div>
    </div>
    <el-row style="margin-bottom: 15px">
      <span>订单数据</span>
      <div style="float: right">
        <el-button @click="exportOrder">导出</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        prop="order_date"
        label="统计时间"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_people_number"
        label="下单人数"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_number"
        label="下单笔数"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="payment_people_number"
        label="支付人数"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="payment_number"
        label="支付订单数"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="actual_price"
        label="下单金额"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="payment_price"
        label="支付金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="refund_money"
        label="退款金额"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
  </el-card>
</div>
