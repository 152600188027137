
<div class="height:100%;">
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>供应商管理</span>
      <div style="float: right">
        <el-button @click="edit({})">新增供应商</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        type="index"
        label="序号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="supplier_name"
        label="供应商名称"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="contacts"
        label="联系人"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone_number"
        label="手机"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: red" @click="delBtn(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <add-supplier-dialog
    v-if="isAddDialog"
    :selectItem="selectItem"
    @close="isAddDialog = false"
    @submit="addDialogSure"
  />
</div>
