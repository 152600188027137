/*
 * @Author: ljf
 * @Date: 2022-05-26 16:17:05
 * @LastEditors: ljf
 * @LastEditTime: 2022-05-27 09:27:08
 * @FilePath: \lxm-admin\src\router\index.js
 * @Description:
 *
 */
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { constantRoutes } from "./routers";
import Cookies from "js-cookie";
import AxiosHelper from "../utils/axiosHelper";
import { Watermark } from "watermark-js-plus";
import { getMenu } from "@/api/system/menu-api";
import Layout from "@/views/home/Home.vue";
import { dynamicsRouters } from "./dynamicsRouters";
Vue.use(Router);
let router = new Router({
  routes: constantRoutes,
});
let route;
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
let hasAddedDynamicRoutes = false;
let watermark;
router.beforeEach(async (to, from, next) => {
  //判断是否已经登录
  if (!Cookies.get("userToken") && to.query.token) {
    await AxiosHelper({
      method: "POST",
      url: "/system/cookie/userToken/" + to.query.token,
      headers: {
        appHost: window.location.host,
      },
    }).catch((error) => {
      console.log("token请求失败", error.message);
    });
  }
  if (!!Cookies.get("userToken")) {
    let uid = sessionStorage.getItem("uid") || "";
    if (uid && uid != "undefined") {
      let vuex = localStorage.getItem("vuex") || "{}";
      let user = JSON.parse(vuex)?.user;
      if (uid != user?.uid && uid != "undefined" && uid != undefined) {
        const vm = new Vue();
        vm.$alert("当前账号已切换，请刷新页面！", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            sessionStorage.setItem("uid", "");
            location.reload();
          },
        });
        return;
      }
    } else {
      sessionStorage.setItem("uid", store?.state?.user?.uid);
    }
    route = {
      path: "/home",
      name: "home",
      component: Layout,
      meta: {
        title: "首页",
      },
      children: [],
    };
    if (store.state.addRoutes.length == 0) {
      const menu = await getMenu("pms");
      store.commit("setAddRoutes", menu);
    }
    // await store.dispatch("restrictedCityListAction");
    if (!watermark) {
      watermark = new Watermark({
        content: `${store?.state?.user?.realName || ""}:${
          store?.state?.user?.cellphone || ""
        }`,
        width: 500,
        height: 500,
        globalAlpha: 0.1,
        onSuccess: () => {
          // success callback
        },
      });
    }
    watermark.create();
    if (!hasAddedDynamicRoutes && store.state.addRoutes.length > 0) {
      try {
        let subsidy_data = false;
        let subsidy_detail = false;
        store.state.addRoutes.forEach((item) => {
          if (item?.subs?.length > 0) {
            item.subs.forEach((items) => {
              if (items.component == "subsidy_data") {
                subsidy_data = true;
              }
              if (items.component == "subsidy_detail") {
                subsidy_detail = true;
              }
              if (items.component == "shopGoodsList") {
                route.children.push({
                  path: "/shop/editGoods",
                  name: "shopEditGoods",
                  component: dynamicsRouters["shopEditGoods"],
                  meta: {
                    title: "商品详情",
                  },
                });
              }
              route.children.push({
                path: items.index,
                name: items.component,
                component: dynamicsRouters[items.component],
                meta: {
                  title: items.title,
                },
              });
            });
          }
        });
        if (subsidy_data && !subsidy_detail) {
          route.children.push({
            path: "/subsidy_detail",
            name: "subsidy_detail",
            component: dynamicsRouters["subsidy_detail"],
            meta: {
              title: "补贴池详情",
            },
          });
        }
        router.addRoute(route);
        router.addRoute({
          path: "*",
          redirect: "/404",
          name: "404",
        });
        hasAddedDynamicRoutes = true;
        return next({ ...to, replace: true });
      } catch (error) {
        console.error("获取动态路由失败", error);
      }
    }

    if (to.path === "/") {
      next({ path: store.state.firstPath });
    } else {
      next();
    }
  } else {
    if (watermark) {
      watermark.destroy();
    }
    watermark = null;
    if (hasAddedDynamicRoutes) {
      hasAddedDynamicRoutes = false;
      router = new Router({
        routes: constantRoutes,
      });
    }
    store.commit("setRestrictedCityList", []);
    store.commit("setAddRoutes", []);
    if (to.path === "/") {
      next();
    } else {
      next({ path: "/" });
    }
  }
});
Vue.mixin({
  beforeRouteLeave: function (to, from, next) {
    if (to.name === "redirect") {
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : "")
                : this.$vnode.key;
            var cache = this.$vnode.parent.componentInstance.cache;
            var keys = this.$vnode.parent.componentInstance.keys;
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key);
                if (index > -1) {
                  keys.splice(index, 1);
                }
              }
              delete cache[key];
            }
          }
        }
      }
      this.$destroy();
    }
    next();
  },
});
// 将路由实例对象托管到vuex
// store.commit('global/setRouter', router)
export default router;
