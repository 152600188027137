<template>
  <div>
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item
              label="统计时间："
              label-width="90px"
              style="margin-bottom: 0"
            >
              <div class="flex">
                <el-select v-model="searchValue.timeId">
                  <el-option
                    v-for="item in timeList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
                <el-date-picker
                  v-model="searchValue.create_time"
                  :type="searchValue.timeId == '1' ? 'date' : 'month'"
                  placeholder="选择日期"
                  :clearable="false"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <div class="total">
        <div
          v-for="(item, index) in totalList"
          :style="{ background: item.background }"
          class="total-item"
          :key="index"
        >
          <div class="total-item-main">
            <p class="total-item-main-name">{{ item.name }}</p>
            <p class="total-item-main-price">{{ item.price }}</p>
          </div>
        </div>
      </div>
      <el-row style="margin-bottom: 15px">
        <span>订单数据</span>
        <div style="float: right">
          <el-button @click="exportOrder">导出</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
      >
        <el-table-column
          prop="order_date"
          label="统计时间"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_people_number"
          label="下单人数"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_number"
          label="下单笔数"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payment_people_number"
          label="支付人数"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payment_number"
          label="支付订单数"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actual_price"
          label="下单金额"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payment_price"
          label="支付金额"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_money"
          label="退款金额"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import { operateAdd } from "@/api/system/user-api";
import {
  getShopOrderDataListApi,
  getShopOrderDataTotalApi,
} from "@/api-new/shop-order";
export default {
  data() {
    return {
      searchValue: {
        timeId: "1",
        create_time: "",
      },
      statistics: {},
      dataTable: [],
      vloading: false,
      timeList: [
        {
          label: "自然日",
          value: "1",
        },
        {
          label: "自然月",
          value: "2",
        },
      ],
    };
  },
  computed: {
    totalList() {
      return [
        {
          name: "下单人数总计",
          background: "#E9F0FF",
          price: this.statistics.order_people_number || 0,
        },
        {
          name: "下单笔数总计",
          background: "#ECFFF4",
          price: `${Number(this.statistics.order_number || 0)}`,
        },
        {
          name: "支付人数总计",
          background: "#EAF8FF",
          price: `${Number(this.statistics.payment_people_number || 0)}`,
        },
        {
          name: "支付订单数总计",
          background: "#FFF2E6",
          price: `${Number(this.statistics.payment_number || 0)}`,
        },
        {
          name: "下单金额总计",
          background: "#E9F0FF",
          price: `￥${Number(this.statistics.actual_price || 0).toFixed(2)}`,
        },
        {
          name: "支付金额总计",
          background: "#ECFFF4",
          price: `￥${Number(this.statistics.payment_price || 0).toFixed(2)}`,
        },
        {
          name: "退款金额总计",
          background: "#EAF8FF",
          price: `￥${Number(this.statistics.refund_money || 0).toFixed(2)}`,
        },
      ];
    },
  },

  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.searchValue.create_time = moment().format("YYYY-MM-DD");
    this.getShopOrderDataTotal();
    this.getShopOrderDataList();
  },
  methods: {
    async getShopOrderDataList() {
      let create_time_begin, create_time_end;
      if (this.searchValue.timeId == "1") {
        create_time_begin = moment(this.searchValue.create_time)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        create_time_end = moment(this.searchValue.create_time)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        create_time_begin = moment(this.searchValue.create_time)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        create_time_end = moment(this.searchValue.create_time)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const res = await getShopOrderDataListApi({
        create_time_begin: create_time_begin,
        create_time_end: create_time_end,
      });
      if (res.data.code == 0) {
        this.dataTable = res.data.data;
      }
    },
    async getShopOrderDataTotal() {
      let create_time_begin, create_time_end;
      if (this.searchValue.timeId == "1") {
        create_time_begin = moment(this.searchValue.create_time)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        create_time_end = moment(this.searchValue.create_time)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        create_time_begin = moment(this.searchValue.create_time)
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        create_time_end = moment(this.searchValue.create_time)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const res = await getShopOrderDataTotalApi({
        create_time_begin: create_time_begin,
        create_time_end: create_time_end,
      });
      if (res.data.code == 0) {
        this.statistics = res.data.data;
      }
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 40,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      await this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.exportData(this.dataTable);
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "统计时间",
          "下单人数",
          "下单笔数",
          "支付人数",
          "支付订单数",
          "下单金额",
          "支付金额",
          "退款金额",
        ];
        const filterVal = [
          "order_date",
          "order_people_number",
          "order_number",
          "payment_people_number",
          "payment_number",
          "actual_price",
          "payment_price",
          "refund_money",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "商城订单数据导出",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getShopOrderDataTotal();
      this.getShopOrderDataList();
    },
    clear() {
      this.searchValue = {
        timeId: "1",
        create_time: moment().format("YYYY-MM-DD"),
      };
      this.clickSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  .total-item {
    display: flex;
    margin-left: 12px;
    min-height: 72px;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;
    flex: 1;
    overflow: hidden;
    .total-item-img {
      width: 40px;
      height: 40px;
    }
    .total-item-main {
      margin-left: 10px;
      flex: 1;
      overflow: hidden;
      .total-item-main-name {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #676d6d;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
      .total-item-main-price {
        font-weight: 700;
        font-size: 20px;
        color: #323232;
        line-height: 23px;
        text-align: left;
        margin-top: 10px;
        font-family: Arial, Arial;
        word-break: break-all;
      }
    }
    &:first-child {
      margin-left: 0px;
    }
  }
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
.flex {
  display: flex;
}
</style>