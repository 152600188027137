
<div>
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item
            label="订单搜索："
            label-width="90px"
            style="margin-bottom: 0"
          >
            <el-input
              v-model="searchValue.search_info"
              placeholder="可输入订单编号、收货人/买家手机号或后四位、收货人姓商品名称、物流单号"
              clearable
            />
          </el-form-item>
          <el-form-item
            label="发货时间"
            label-width="100px"
            style="margin-bottom: 0"
          >
            <div class="flex">
              <el-date-picker
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>物流监控</span>
      <div style="float: right">
        <el-button @click="exportOrder">导出</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        prop="courier_number"
        label="物流单号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_no"
        label="所属订单"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="发货时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="express_company"
        label="物流公司"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        label="异常类型"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="物流备注" min-width="150" align="center">
      </el-table-column>
      <el-table-column
        prop="express_name"
        label="物流状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="orderDetail(scope.row)"
            >查看物流</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <detail-dialog
    :selectObj="selectObj"
    v-if="isDetailDialog"
    @close="isDetailDialog = false"
  ></detail-dialog>
</div>
