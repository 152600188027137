<template>
  <div>
    <el-card class="box-card" style="margin-top: 15px">
      <div slot="header" class="clearfix">
        <span>设备筛选</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="search_dialog = true"
          >高级搜索</el-button
        >
      </div>
      <div class="text item">
        <el-form
          ref="form"
          style="padding-right: 10px"
          :rules="rules"
          :model="form"
          label-width="110px"
        >
          <el-row class="search">
            <el-col class="elCol">
              <el-form-item label="设备编号：">
                <el-input
                  v-model.trim="form.equipment_no"
                  clearable
                  placeholder="请输入设备编号"
                />
              </el-form-item>
            </el-col>
            <el-col class="elCol">
              <el-form-item label="城市：">
                <el-select
                  v-model="form.city_code"
                  placeholder="全国"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in restrictedCityList"
                    :key="item.cityCode"
                    :label="item.cityName"
                    :value="item.cityCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elCol">
              <el-form-item label="设备状态：">
                <el-select
                  v-model="form.equipment_state"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in dim_equipment_state"
                    :key="item.data_value"
                    :value="item.data_value"
                    :label="item.data_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col class="elCol">
              <el-form-item label="创建时间：">
                <el-date-picker
                  class="css-forbid-flash"
                  style="width: 100%"
                  v-model="form.create_time"
                  type="date"
                  placeholder="请选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="elColBtn">
              <!-- <el-form-item > -->
              <el-button
                style="margin-right: 15px"
                type="primary"
                @click="searchBtn(form)"
                >查询</el-button
              >
              <el-button type="primary" @click="clear">重置</el-button>
              <!-- </el-form-item> -->
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 15px">
      <div>
        <span>设备列表</span>
        <el-button
          style="margin-left: 10px"
          type="default"
          v-if="isMaintenanceOrder == 1"
          @click="openModel('add')"
          >添加</el-button
        >
        <el-button
          type="default"
          @click="deletedAll"
          v-if="isMaintenanceOrder == 1"
          >删除</el-button
        >
        <el-button style="float: right" type="default" @click="exportOrder"
          >导出</el-button
        >
      </div>
      <el-table
        ref="dataTable"
        v-loading="table_loading"
        :height="table_high"
        :data="table_data"
        @selection-change="selectChange"
        style="margin-top: 20px"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          type="index"
          label="序号"
          min-width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="equipment_no"
          label="设备编号"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              primary
              @click="openModel('browse', scope.row.id)"
              >{{ scope.row.equipment_no }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="城市"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_name"
          label="设备名称"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agent_name"
          label="代理商"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_type_name"
          label="设备类型"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_power"
          label="设备功率"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_capacity"
          label="容量（kWh）"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="battery_percentage"
          label="电量百分比"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_state_name"
          label="设备状态"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="availability_name"
          label="可用状态"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="open_self_charge_name"
          label="自助充电状态"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_valid_state"
          label="设备有效状态"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="equipment_org"
          label="归属网点"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="二维码"
          min-width="150"
          align="center"
          v-if="isMaintenanceOrder == 1"
        >
          <template slot-scope="scope">
            <div @click="showWxappQrcodeView(scope.row)">
              <vue-qr
                :ref="'qrcode_' + scope.row.id"
                :text="`http://qr.lvxiaoma.cn?scanType=zszc&id=${scope.row.id}`"
                :size="300"
                style="width: 100px"
              ></vue-qr>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="equipment_org"
          label="操作"
          min-width="180"
          align="center"
          fixed="right"
          v-if="isMaintenanceOrder == 1"
        >
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              @click="openModel('edit', scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="text"
              style="color: #ff0000"
              @click="deleted(scope.row.id)"
              >删除</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.valid_state == '1'"
              type="text"
              style="color: #000"
              @click="setValidState(scope.row)"
              >停用</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.valid_state == '0'"
              type="text"
              style="color: #67c23a"
              @click="setValidState(scope.row)"
              >启用</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.open_self_charge == '0'"
              type="text"
              style="color: #67c23a"
              @click="setSelftate(scope.row)"
              >开启自助充电</el-button
            >
            <el-button
              size="small"
              v-if="scope.row.open_self_charge == '1'"
              type="text"
              style="color: #000"
              @click="setSelftate(scope.row)"
              >关闭自助充电</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container" style="margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
      <base_table
        v-if="false"
        ref="finish_table"
        :setTableHigh="true"
        :data="table_data"
        :columns="data_columns"
        @page-change="pageChange"
        @sort-change="sortChange"
        showCheck
        showIndex
        :tableHigh="table_high"
        @on-select-change="selectChange"
        :showLoading="table_loading"
      ></base_table>
    </el-card>
    <search_model
      :dialog="search_dialog"
      @search="flush"
      :close.sync="search_dialog"
    ></search_model>
    <equipment_model
      :dialog="model_dialog"
      :model_type="type"
      :id="form.id"
      :close.sync="model_dialog"
      :cityList="restrictedCityList"
      @search="flush"
    ></equipment_model>
    <export_data_model
      :dialog="export_dialog"
      :columns="data_columns"
      :close.sync="export_dialog"
      @exportExcel="exportExcel"
    ></export_data_model>
    <qrcode v-if="wxappQrcodeViewVisible" ref="qrcodeDialog" />
  </div>
</template>

<script>
import base_table from "../../components/common/base/base_table.vue";
import search_model from "./model/search_model.vue";
import equipment_model from "./model/equipment_model.vue";
import export_data_model from "../../components/common/export/export_data_model.vue";
import EQUIPMENT_API from "../../api/equipment_api";
import DIM_API from "../../api/dim_api";
import FILE_API from "../../api/file_api";
import OPERATE_API from "@/api/operate_api";
import agentApi from "@/api/agent_api";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import qrcode from "./model/qrcode.vue";
import { mapState } from "vuex";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "equipment_manager",
  components: {
    base_table,
    search_model,
    equipment_model,
    export_data_model,
    vueQr,
    qrcode,
  },
  data() {
    const _this = this;
    return {
      table_ref: {},
      table_data: [],
      data_columns: [
        {
          label: "设备编号",
          prop: "equipment_no",
          style: "left",
          minWidth: "100",
          render(h, params) {
            let link = h(
              "el-link",
              {
                props: {
                  underline: false,
                  type: "primary",
                },
                on: {
                  click: () => {
                    _this.openModel("browse", params.row.id);
                  },
                },
              },
              params.row.equipment_no
            );
            return h("div", [link]);
          },
        },
        {
          label: "城市",
          prop: "city_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "设备名称",
          prop: "equipment_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "代理商",
          prop: "agent_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "设备类型",
          prop: "equipment_type_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "设备功率",
          prop: "equipment_power",
          style: "left",
          minWidth: "100",
        },
        {
          label: "容量（kWh）",
          prop: "equipment_capacity",
          style: "left",
          minWidth: "100",
        },
        {
          label: "电量百分比",
          prop: "battery_percentage",
          style: "left",
          minWidth: "100",
        },
        {
          label: "设备状态",
          prop: "equipment_state_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "自助充电状态",
          prop: "open_self_charge_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "设备有效状态",
          prop: "equipment_valid_state",
          style: "left",
          minWidth: "100",
        },
        {
          label: "可用状态",
          prop: "availability_name",
          style: "left",
          minWidth: "100",
        },
        {
          label: "归属网点",
          prop: "equipment_org",
          style: "left",
          minWidth: "100",
        },
        {
          label: "操作",
          style: "center",
          prop: "id",
          minWidth: "180",
          fixed: "right",
          render: (h, params) => {
            let btns = [];
            let editBtn = h(
              "el-button",
              {
                props: {
                  type: "text",
                  size: "small",
                },
                on: {
                  click: () => {
                    _this.openModel("edit", params.row.id);
                  },
                },
              },
              "编辑"
            );
            let deleteBtn = h(
              "el-button",
              {
                props: {
                  type: "text",
                  size: "small",
                },
                style: {
                  color: "red",
                },
                on: {
                  click: () => {
                    _this.deleted(params.row.id);
                  },
                },
              },
              "删除"
            );
            if (this.isMaintenanceOrder == 1) {
              btns.push(editBtn);
              btns.push(deleteBtn);
            }

            return h("div", btns);
          },
        },
      ],
      table_high: "500",
      table_loading: false,
      dim_equipment_state: [],
      select_row: [],
      form: {
        id: "",
        $limit: 50,
        $offset: 0,
        field: "",
        type: "",
        equipment_no: "",
        equipment_name: "",
        equipment_type: "",
        equipment_state: "",
        equipment_org: "",
        create_time: "",
        city_code: "",
        // availability: 1
      },
      search_dialog: false,
      model_dialog: false,
      export_dialog: false,
      type: "",
      rules: {},
      pageSize: 50,
      pageIndex: 1,
      total: 0,
      wxappQrcodeViewVisible: false,
    };
  },

  // , {
  //     label: '设备物理状态',
  //     prop: 'physical_state',
  //     style: 'left',
  //     minWidth: '100',
  // }
  methods: {
    async setSelftate(item) {
      await this.$confirm(
        `确定要${item.open_self_charge == "1" ? "停用" : "启用"}自助充电吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.table_loading = true;
      const res = await this.axiosHelper
        .post(
          `/api/equipment/set/openSelfCharge?id=${item.id}&openSelfCharge=${
            item.open_self_charge == "1" ? "0" : "1"
          }`
        )
        .finally(() => {
          this.table_loading = false;
        });
      if (res.data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success",
          duration: 1500,
        });
        this.searchBtn();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    openModel(type, id) {
      // this.clear();
      this.type = type;
      if (!this.is_add) {
        this.form.id = id;
      } else {
        this.form.id = "";
      }
      this.model_dialog = true;
    },
    deleted(id) {
      this.$confirm("是否删除设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        EQUIPMENT_API.deleteByIds(id).finally((_) => {
          this.flush();
        });
      });
    },
    deletedAll() {
      this.$confirm("是否批量删除设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = this.select_row.map((item) => {
          return item.id;
        });
        if (ids.length > 0) {
          EQUIPMENT_API.deleteByIds(ids.join(",")).finally((_) => {
            this.flush();
          });
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.search(this.form);
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.search(this.form);
    },
    selectChange(rows) {
      this.select_row = rows;
    },
    searchBtn() {
      this.pageIndex = 1;
      this.search(this.form);
    },
    async search(form) {
      form.$limit = this.pageSize;
      form.$offset = this.pageSize * (this.pageIndex - 1);
      this.table_loading = true;
      let res = await EQUIPMENT_API.getByCondition(form).finally((_) => {
        this.table_loading = false;
      });
      this.total = res.data.total_count;
      let arr = res.data.items || [];
      arr.forEach((item) => {
        item.equipment_valid_state = item.valid_state == "1" ? "启用" : "停用";
        item.open_self_charge_name =
          item.open_self_charge == "1" ? "启用" : "停用";
      });
      this.table_data = res.data.items;
    },
    flush(form) {
      Object.assign(this.form, form);
      this.pageIndex = 1;
      this.search(this.form);
    },
    async clear() {
      Object.assign(this.$data.form, this.$options.data().form);
      this.search(this.form);
    },
    async initDim() {
      let res = await DIM_API.getByType("equipment_state");
      this.dim_equipment_state = res.data;
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 11,
        operate_parameter: JSON.stringify(this.form),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.select_row.length === 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.table_loading = true;
          let res = await EQUIPMENT_API.exportData(this.form).finally((_) => {
            this.table_loading = false;
          });
          if (res.status === 200) {
            let arr = res.data || [];
            arr.forEach((item) => {
              item.equipment_valid_state =
                item.valid_state == "1" ? "启用" : "停用";
              item.open_self_charge_name =
                item.open_self_charge == "1" ? "启用" : "停用";
            });
            this.exportData(res.data);
          }
        });
      } else {
        this.exportData(this.select_row);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        let arr = this.data_columns.filter((item) => item.label !== "操作");
        const tHeader = arr.map((item) => item.label);
        const filterVal = arr.map((item) => item.prop);
        const data = this.formatJson(filterVal, export_data);
        console.log(data);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "设备信息",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    exportExcel(column) {
      let data = {
        condition: this.form,
        headers: column,
      };
      FILE_API.downloadPost(data, "/api/equipment/export");
      console.log("导出列", column);
    },
    showWxappQrcodeView(row) {
      this.wxappQrcodeViewVisible = true;
      this.$nextTick(() => {
        this.$refs.qrcodeDialog.init(
          row,
          this.$refs[`qrcode_${row.id}`].imgUrl
        );
      });
    },
    async setValidState(item) {
      await this.$confirm(
        `确定要${item.valid_state == "1" ? "停用" : "启用"}设备吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.table_loading = true;
      const res = await this.axiosHelper
        .post(
          `/api/equipment/set/validState?id=${item.id}&validState=${
            item.valid_state == "1" ? "0" : "1"
          }`
        )
        .finally(() => {
          this.table_loading = false;
        });
      if (res.data.code == 0) {
        this.$message({
          message: "操作成功",
          type: "success",
          duration: 1500,
        });
        this.searchBtn();
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
  computed: {
    ...mapState(["restrictedCityList"]),
    is_add() {
      return this.type === "add";
    },
    isMaintenanceOrder() {
      return this.$store.state.user.isMaintenanceOrder;
    },
  },
  async mounted() {
    this.initDim();
    this.search(this.form);
    this.table_ref = this.$refs["finish_table"];
    if (this.$store.state.user.isAgent) {
      this.data_columns.splice(-1);
    }
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: row;
}
.elCol {
  display: flex;
  align-items: center;
  /* background: red; */
  /* justify-content: center; */
  /* justify-content: space-evenly; */
}
.elColBtn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 40px;
}
.search-btn {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", 微软雅黑, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: rgb(0, 121, 254);
  border-width: 0px;
  font-size: 13px;
}
.title-text {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", 微软雅黑, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  line-height: 10px;
  border-width: 0px;
  font-size: 13px;
}
</style>