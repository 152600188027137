<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-card class="box-card">
        <div class="flex">
          <h3>商品分类</h3>
          <el-form-item style="margin-left: 30px" label="" prop="goods_type_id">
            <el-cascader
              v-model="goods_type_id"
              :options="typeList"
              :props="{
                value: 'id',
                label: 'goods_type_name',
                children: 'children',
              }"
              :show-all-levels="false"
              @change="handleChange"
              :disabled="readonly"
            ></el-cascader>
          </el-form-item>
        </div>
      </el-card>
      <el-card class="box-card">
        <h3>基本信息</h3>
        <div style="margin: 30px 0 0 30px">
          <el-form-item label-width="140px" required label="商品图片（大）：">
            <div class="flex">
              <div
                class="image-list"
                v-for="item in bigImageList"
                :key="item.url"
              >
                <el-image class="image" :src="`${IMG_SERVE_URL}${item.url}`" />
                <i class="el-icon-close" @click="handleRemove(item.url)"></i>
              </div>
              <div
                class="upload-btn"
                @click="showImgManamentDialog(750, 666, 'big')"
              >
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label-width="140px" required label="商品图片（小）：">
            <div class="flex">
              <div
                class="image-list"
                v-for="item in smallImageList"
                :key="item.url"
              >
                <el-image class="image" :src="`${IMG_SERVE_URL}${item.url}`" />
                <i class="el-icon-close" @click="handleRemove(item.url)"></i>
              </div>
              <div
                class="upload-btn"
                @click="showImgManamentDialog(1, 1, 'small')"
              >
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label-width="140px"
            label="商品名称："
            prop="goods_name"
          >
            <el-input
              style="width: 300px"
              v-model="form.goods_name"
              placeholder="请输入商品名称"
              maxlength="20"
              :readonly="readonly"
            />
          </el-form-item>
          <el-form-item
            label-width="140px"
            label="商品品牌："
            prop="goods_brand_id"
          >
            <el-select
              style="width: 300px"
              filterable
              v-model="form.goods_brand_id"
              :disabled="readonly"
            >
              <el-option
                v-for="item in goodsBrandList"
                :key="item.id"
                :label="item.goods_brand_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="140px"
            label="商品供应商："
            prop="goods_supplier_id"
          >
            <el-select
              style="width: 300px"
              filterable
              v-model="form.goods_supplier_id"
              :disabled="readonly"
            >
              <el-option
                v-for="item in goodsSupplierList"
                :key="item.id"
                :label="item.supplier_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="140px"
            label="商品编码："
            prop="goods_code"
          >
            <el-input
              v-model="form.goods_code"
              style="width: 300px"
              placeholder="请输入商品编码"
              maxlength="20"
              :readonly="readonly"
            ></el-input
          ></el-form-item>
          <el-form-item label-width="140px" label="规格信息：" prop="specs">
            <el-input
              style="width: 300px"
              v-model="form.specs"
              placeholder="请输入规格信息"
              :readonly="readonly"
              maxlength="20"
            />
          </el-form-item>
        </div>
      </el-card>
      <el-card class="box-card">
        <h3>价格和库存</h3>
        <div style="margin: 30px 0 0 30px">
          <div class="flex">
            <el-form-item
              label-width="140px"
              label="售价："
              prop="goods_sku.goods_price"
            >
              <el-input
                v-model="form.goods_sku.goods_price"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入售价"
                @input="validateInput('goods_price', $event, 8, 2)"
              ></el-input>
            </el-form-item>
            <el-form-item
              label-width="140px"
              label="划线价："
              prop="goods_sku.goods_underline_price"
            >
              <el-input
                v-model="form.goods_sku.goods_underline_price"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入划线价"
                @input="validateInput('goods_underline_price', $event, 8, 2)"
              ></el-input
            ></el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              label-width="140px"
              label="库存："
              prop="goods_sku.inventory"
            >
              <el-input
                v-model="form.goods_sku.inventory"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入库存"
                @input="validateInput('inventory', $event, 7)"
              ></el-input
            ></el-form-item>
            <el-form-item
              label-width="140px"
              label="进货价："
              prop="goods_sku.cost_price"
            >
              <el-input
                v-model="form.goods_sku.cost_price"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入进货价"
                maxlength="20"
                @input="validateInput('cost_price', $event, 8, 2)"
              ></el-input
            ></el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              label-width="140px"
              label="服务说明标题："
              prop="service_desc"
            >
              <el-input
                v-model="form.service_desc_title"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入服务说明标题"
                maxlength="20"
              ></el-input
            ></el-form-item>
            <el-form-item
              label-width="140px"
              label="服务说明："
              prop="service_desc"
            >
              <el-input
                v-model="form.service_desc"
                :readonly="readonly"
                style="width: 300px"
                placeholder="请输入服务说明"
                maxlength="100"
                type="textarea"
              ></el-input
            ></el-form-item>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <h3>商品详情</h3>
        <!-- <VueUeditorWrap
          style="margin-top: 30px"
          v-model="form.goods_details"
          :config="myConfig"
        /> -->
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          mode="default"
          :defaultConfig="toolbarConfig"
        />
        <Editor
          style="height: 500px; overflow-y: hidden; margin-top: 30px"
          v-model="form.goods_details"
          mode="default"
          ref="editor"
          :defaultConfig="editorConfig"
          @onCreated="onCreated"
        />
      </el-card>
    </el-form>
    <div class="flex justify-center">
      <el-button @click="submitForm" v-if="!readonly" :loading="isSubmitLoad"
        >保存</el-button
      >
    </div>
    <ImageManagement
      v-if="isImageManagement"
      rootFilePath="shop"
      :scale="false"
      @close="isImageManagement = false"
      :width="imgDialogWidth"
      :height="imgDialogHeight"
      @sure="uploadImgSuccess"
    />
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { IMG_SERVE_URL } from "@/config/index";
import { validateNum } from "@/utils/utils";
import {
  addGoodsApi,
  getGoodsApi,
  getGoodsTypeListApi,
  updateGoodsApi,
} from "@/api-new/goods";
import { getSupplierRestrictedListApi } from "@/api-new/user";
import { getGoodsBrandListApi } from "../../../api-new/shop-brand";
import { getGoodsSupplierListApi } from "../../../api-new/shop-supplier";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  data() {
    return {
      form: {
        goods_type_id: "",
        goods_name: "",
        goods_sku: {
          goods_price: "",
          goods_sku_private_id: "",
          goods_underline_price: "",
          id: "",
          inventory: "",
          cost_price: "",
        },
        pic_content: "",
        goods_details: "",
        jianpin: "",
        service_desc: "非质量问题不支持退换货",
        service_desc_title: "不支持7天无理由退换货",
        goods_code: "",
        goods_supplier_id: "",
        goods_brand_id: "",
        specs: "",
      },
      rules: {
        goods_type_id: [
          { required: true, message: "请选择商品分类", trigger: "blur" },
        ],
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        goods_brand_id: [
          { required: true, message: "请选择商品品牌", trigger: "blur" },
        ],
        goods_supplier_id: [
          { required: true, message: "请选择供应商", trigger: "blur" },
        ],
        specs: [{ required: true, message: "请输入规格信息", trigger: "blur" }],
        goods_sku: {
          goods_price: [
            { required: true, message: "请输入售价", trigger: "blur" },
          ],
          inventory: [
            { required: true, message: "请输入库存", trigger: "blur" },
          ],
          goods_underline_price: [
            { validator: this.validateUnderlinePrice, trigger: "blur" },
          ],
          cost_price: [
            { required: true, message: "请输入进货价", trigger: "blur" },
          ],
        },
      },
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "https://test.lvxiaoma.cn" + "/api/file/upload/path",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "/UEditor/",
        zIndex: 100,
      },
      typeList: [],
      isImageManagement: false,
      imgDialogWidth: 1,
      imgDialogHeight: 1,
      imgDialogType: "",
      goods_type_id: "",
      typeAllList: [],
      isSubmitLoad: false,
      goodsBrandList: [],
      goodsSupplierList: [],
      editor: null,
      editorConfig: {
        MENU_CONF: {
          uploadImage: {
            customUpload: this.uploaadImg,
          },
        },
        placeholder: "请输入内容...",
        readonly: false,
      },
      toolbarConfig: {},
      readonly: true,
    };
  },
  computed: {
    bigImageList() {
      let pic_content = JSON.parse(this.form.pic_content || "[]") || [];
      console.log(pic_content);
      return pic_content
        .filter((it) => it.type == 1)
        .sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
    },
    smallImageList() {
      let pic_content = JSON.parse(this.form.pic_content || "[]") || [];
      return pic_content
        .filter((it) => it.type == 2)
        .sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
    },
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
  },
  async created() {
    this.getGoodsBrandList();
    this.getGoodsSupplierList();
    await this.getGoodsTypeList();
    if (this.$route.query.id) {
      this.getGoodsDetail();
    } else {
      this.readonly = false;
    }
  },
  methods: {
    async uploaadImg(file, insertFn) {
      const param = new FormData();
      param.append("file", file);
      const res = await this.axiosHelper.post(
        `/api/file/upload/oss?rootFilePath=shop`,
        param
      );
      if (res.data.code == 0) {
        insertFn(
          `${IMG_SERVE_URL}${res.data.data.url}?x-oss-process=image/resize,w_750`
        );
      }
      console.log(file);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor);
      // this.toolbarConfig = {
      //   excludeKeys: [
      //     "insertVideo",
      //     "uploadVideo",
      //     "group-video",
      //     "fullScreen",
      //   ],
      // };
    },
    async getGoodsSupplierList() {
      const res = await getSupplierRestrictedListApi();
      if (res.data.code == 0) {
        this.goodsSupplierList = res.data.data;
      }
    },
    async getGoodsBrandList() {
      const res = await getGoodsBrandListApi();
      if (res.data.code == 0) {
        this.goodsBrandList = res.data.data;
      }
    },
    validateUnderlinePrice(rule, value, callback) {
      const goodsPrice = parseFloat(this.form.goods_sku.goods_price);
      const underlinePrice = parseFloat(value);

      if (underlinePrice && goodsPrice && underlinePrice < goodsPrice) {
        callback(new Error("划线价不能小于价格"));
      } else {
        callback();
      }
    },
    async handleRemove(url) {
      await this.$confirm("确定要删除该图片吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let pic_content = JSON.parse(this.form.pic_content || "[]");
      this.form.pic_content = JSON.stringify(
        pic_content.filter((it) => it.url != url)
      );
    },
    async getGoodsDetail() {
      const res = await getGoodsApi({
        id: this.$route.query.id,
      });
      if (res.data.code == 0) {
        let obj = res.data.data;
        this.form = {
          goods_type_id: obj.goods_type_id,
          goods_name: obj.goods_name,
          goods_sku: {
            goods_price: obj.goods_price,
            goods_sku_private_id: obj.goods_sku_private_id,
            goods_underline_price: obj.goods_underline_price,
            id: obj.goods_sku_id,
            inventory: obj.inventory,
            cost_price: obj.cost_price,
          },
          pic_content: obj.pic_content,
          goods_details: obj.goods_details,
          jianpin: obj.jianpin,
          service_desc: obj.service_desc,
          service_desc_title: obj.service_desc_title,
          goods_code: obj.goods_code,
          goods_supplier_id: obj.goods_supplier_id,
          goods_brand_id: obj.goods_brand_id,
          specs: obj.specs,
          id: this.$route.query.id,
        };
        let typeObj = this.typeAllList.find((it) => it.id == obj.goods_type_id);
        if (typeObj) {
          this.goods_type_id = [typeObj.parent_id, typeObj.id];
        }
        this.readonly = obj.shelves == "1";
      }
    },
    validateInput(type, e, precision, scale) {
      this.form.goods_sku[type] = validateNum(e, precision, scale);
    },
    handleChange(e) {
      this.form.goods_type_id = e[e.length - 1];
    },
    uploadImgSuccess(imgUrl) {
      if (this.imgDialogType == "big") {
        this.form.pic_content = JSON.stringify([
          ...JSON.parse(this.form.pic_content || "[]"),
          {
            type: 1,
            url: imgUrl,
            sortNum: 0,
          },
        ]);
      } else {
        this.form.pic_content = JSON.stringify([
          ...JSON.parse(this.form.pic_content || "[]"),
          {
            type: 2,
            url: imgUrl,
            sortNum: 0,
          },
        ]);
      }
      this.isImageManagement = false;
    },
    showImgManamentDialog(width, height, type) {
      this.imgDialogWidth = width;
      this.imgDialogHeight = height;
      this.imgDialogType = type;
      this.isImageManagement = true;
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        if (this.bigImageList.length == 0) {
          this.$message.error("请上传商品主图");
          return;
        }
        if (this.smallImageList.length == 0) {
          this.$message.error("请上传商品小图");
          return;
        }
        let res;
        this.isSubmitLoad = true;
        this.showLoading();
        if (this.$route.query.id) {
          res = await updateGoodsApi({
            ...this.form,
          }).finally(() => {
            this.hideLoading();
            this.isSubmitLoad = false;
          });
        } else {
          res = await addGoodsApi({
            ...this.form,
          }).finally(() => {
            this.hideLoading();
            this.isSubmitLoad = false;
          });
        }
        if (res.data.code == 0) {
          this.$message.success("操作成功");
          this.$router.back();
        }
      });
    },
    async getGoodsTypeList() {
      const res = await getGoodsTypeListApi();
      if (res.data.code == 0) {
        let arr = res.data.data || [];
        arr.sort((a, b) => {
          if (a.type_grade == b.type_grade) {
            return b.sort_num - a.sort_num;
          } else {
            return a.type_grade - b.type_grade;
          }
        });
        let list = [];
        arr.forEach((item) => {
          if (item.type_grade == 1) {
            item.disabled = true;
            list.push(item);
          }
          if (item.type_grade == 2) {
            let parent = list.find((it) => it.id === item.parent_id);
            if (parent) {
              parent.disabled = false;
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          }
        });
        this.typeList = list;
        this.typeAllList = arr;
      }
    },
  },
  components: {
    Editor,
    Toolbar,
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin-bottom: 20px;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.image-list {
  position: relative;
  margin-right: 30px;
  .image {
    width: 60px;
    height: 60px;
  }
  .el-icon-close {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #ff0000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}
.upload-btn {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  cursor: pointer;
}
</style>