import request from "@/utils/request";

/**
 * @description: 添加商品
 * @param {Object} data 参数对象
 * @param {String} data.goods_code 商品编码
 * @param {String} data.goods_details 商品详情
 * @param {String} data.goods_name	商品名称
 * @param {Object} data.goods_sku	商品sku
 * @param {String} data.goods_sku.goods_price 商品价格
 * @param {String} data.goods_sku.goods_sku_private_id 商品sku私有主键id,添加的时候不用传，更新时必传
 * @param {String} data.goods_sku.goods_underline_price 商品划线价格
 * @param {String} data.goods_sku.id 商品sku主键id,添加的时候不用传，更新时必传
 * @param {String} data.goods_sku.inventory 库存
 * @param {String} data.goods_type_id	商品类型id
 * @param {String} data.id	主键id,添加的时候不用传，更新时必传
 * @param {String} data.jianpin	商品简拼
 * @param {String} data.pic_content	图片内容(json字符串:[type:类型(1:主图,2:子图),url:路径,sortNum:排序号])
 * @param {String} data.service_desc	服务说明
 * @param {String} data.sort_num	排序号(越大的排在越前)
 * @return {*}
 */

export function addGoodsApi(data) {
  return request({
    url: "/api/goods/add",
    method: "post",
    data,
  });
}

/**
 * @description: 删除商品
 * @param {Object} data 参数对象
 * @param {String} data.id 商品主键id
 * @return {*}
 */

export function deleteGoodsApi(data) {
  return request({
    url: "/api/goods/delete",
    method: "post",
    data,
  });
}
/**
 * @description: 根据id获取商品信息
 * @param {Object} data 参数对象
 * @param {String} data.id 商品主键id
 * @return {*}
 */

export function getGoodsApi(data) {
  return request({
    url: "/api/goods/get",
    method: "post",
    data,
  });
}
/**
 * @description: 获取商品列表
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end 创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start 创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.goods_code	商品编码
 * @param {String} data.goods_name	商品名称
 * @param {String} data.goods_type_id	商品类型id
 * @param {String} data.jianpin	商品简拼
 * @param {String} data.shelves	是否上架(0:否,1:是,2:删除)
 * @return {*}
 */

export function getGoodsListApi(data) {
  return request({
    url: "/api/goods/list",
    method: "post",
    data,
  });
}
/**
 * @description: 分页查询商品
 * @param {Object} data 参数对象
 * @param {String} data.create_time_end 创建时间结束(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.create_time_start 创建时间开始(yyyy-MM-dd HH:mm:ss)
 * @param {String} data.goods_code	商品编码
 * @param {String} data.goods_name	商品名称
 * @param {String} data.goods_type_id	商品类型id
 * @param {String} data.goods_supplier_id 商品供应商id
 * @param {String} data.jianpin	商品简拼
 * @param {String} data.shelves	是否上架(0:否,1:是,2:删除)
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function getGoodsQueryApi(data, limit, offset) {
  return request({
    url: `/api/goods/query?limit=${limit}&offset=${offset}`,
    method: "post",
    data,
  });
}
/**
 * @description: 上架/下架商品
 * @param {String} data.id	商品id
 * @param {String} data.shelves	是否上架(0:否,1:是)
 * @return {*}
 */

export function setGoodsShelvesApi(data) {
  return request({
    url: "/api/goods/set/shelves",
    method: "post",
    data,
  });
}
/**
 * @description: 新增商品类型
 * @param {Object} data 参数对象
 * @param {String} data.goods_type_name 商品类型名称
 * @param {String} data.icon_url 图标url
 * @param {String} data.id	主键id,添加的时候不用传，更新时必传
 * @param {String} data.parent_id	父id(-1:根节点)
 * @param {String} data.sort_num	排序号(从小到大排序)
 * @param {String} data.type_grade	类型级别(1开始计数)
 * @return {*}
 */

export function addGoodsTypeApi(data) {
  return request({
    url: `/api/goods/type/add`,
    method: "post",
    data,
  });
}
/**
 * @description: 删除商品类型
 * @param {Object} data 参数对象
 * @param {String} data.id 商品类型id
 * @return {*}
 */

export function deleteGoodsTypeApi(data) {
  return request({
    url: `/api/goods/type/delete`,
    method: "post",
    data,
  });
}
/**
 * @description: 获取商品类型列表
 * @param {Object} data 参数对象
 * @param {String} data.goods_type_name 商品类型名称
 * @param {String} data.parent_id 父id(-1:根节点)
 * @param {String} data.type_grade 类型级别(1开始计数)
 * @return {*}
 */

export function getGoodsTypeListApi(data) {
  return request({
    url: `/api/goods/type/list`,
    method: "post",
    data,
  });
}
/**
 * @description: 分页查询商品类型
 * @param {Object} data 参数对象
 * @param {String} data.goods_type_name 商品类型名称
 * @param {String} data.parent_id 父id(-1:根节点)
 * @param {String} data.type_grade 类型级别(1开始计数)
 * @param {String} limit 每页条数
 * @param {String} offset 开始数
 * @return {*}
 */

export function getGoodsTyoeQueryApi(data, limit, offset) {
  return request({
    url: `/api/goods/type/query?limit=${limit}&offset=${offset}`,
    method: "post",
    data,
  });
}

/**
 * @description: 修改商品类型
 * @param {Object} data 参数对象
 * @param {String} data.goods_type_name 商品类型名称
 * @param {String} data.icon_url 图标url
 * @param {String} data.id 主键id,添加的时候不用传，更新时必传
 * @param {String} parent_id 父id(-1:根节点)
 * @param {String} sort_num 排序号(从小到大排序)
 * @param {String} type_grade 类型级别(1开始计数)
 * @return {*}
 */

export function updateGoodsTypeApi(data) {
  return request({
    url: `/api/goods/type/update`,
    method: "post",
    data,
  });
}

/**
 * @description: 修改商品
 * @param {Object} data 参数对象
 * @param {String} data.goods_code 商品编码
 * @param {String} data.goods_details 商品详情
 * @param {String} data.goods_name	商品名称
 * @param {Object} data.goods_sku	商品sku
 * @param {String} data.goods_sku.goods_price 商品价格
 * @param {String} data.goods_sku.goods_sku_private_id 商品sku私有主键id,添加的时候不用传，更新时必传
 * @param {String} data.goods_sku.goods_underline_price 商品划线价格
 * @param {String} data.goods_sku.id 商品sku主键id,添加的时候不用传，更新时必传
 * @param {String} data.goods_sku.inventory 库存
 * @param {String} data.goods_type_id	商品类型id
 * @param {String} data.id	主键id,添加的时候不用传，更新时必传
 * @param {String} data.jianpin	商品简拼
 * @param {String} data.pic_content	图片内容(json字符串:[type:类型(1:主图,2:子图),url:路径,sortNum:排序号])
 * @param {String} data.service_desc	服务说明
 * @param {String} data.sort_num	排序号(越大的排在越前)
 * @return {*}
 */

export function updateGoodsApi(data) {
  return request({
    url: `/api/goods/update`,
    method: "post",
    data,
  });
}
