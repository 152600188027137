export const orderStatus = {
  1: "待付款",
  2: "待发货",
  3: "待收货",
  4: "已完成",
  5: "已取消",
  6: "退款订单",
};
export const expressStatus = {
  0: "在途中",
  1: "揽收中",
  2: "疑难",
  3: "已签收",
  4: "已退签",
  5: "派件中",
  8: "清关",
  14: "已拒签",
};
export const refundProcesStateName = {
  1: "待处理",
  2: "退款完成",
  3: "退款失败",
};
