
<el-dialog :visible.sync="visible" @close="close" title="订单发货">
  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
    <el-form-item label="配送信息">
      <div>
        <p>配送方式：快递</p>
        <p>收货人信息：{{ selectObj.real_name }} {{ selectObj.mobile }}</p>
        <p>收货地址：{{ selectObj.address }}{{ selectObj.address_detail }}</p>
      </div>
    </el-form-item>
    <el-form-item label="发货方式">
      <p>在线下单</p>
    </el-form-item>
    <el-form-item label="发货类型">
      <el-radio v-model="form.delivery_type" label="1"
        >快递员上门打印面单</el-radio
      >
      <el-radio v-model="form.delivery_type" label="2" disabled
        >打印面单并通知快递员揽件</el-radio
      >
    </el-form-item>
    <el-form-item label="快递公司">
      <el-select
        v-model="form.express_company_code"
        placeholder="请选择快递公司"
      >
        <el-option
          v-for="item in address_company_list"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="快递单号">
      <el-input
        v-model="form.courier_number"
        maxlength="30"
        placeholder="请输入快递单号"
      />
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="close">取消</el-button>
    <el-button type="primary" @click="submitForm">发货</el-button>
  </div>
</el-dialog>
