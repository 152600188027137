import orderManagement from "@/views/order/order-management.vue";
import dashboard from "@/views/dashboard/index.vue";
import user from "@/views/system/user/User.vue";
import account from "@/views/sysAccount/sysaccount.vue";
import role from "@/views/system/role/Role.vue";
import org from "@/views/system/org/Org.vue";
import menu from "@/views/system/menu/menu.vue";
import roleAuthority from "@/views/system/role-authority/role-authority.vue";
import orderStatistics from "@/views/reportForm/page/order-statistics.vue";
import batteryStatistics from "@/views/reportForm/page/battery-statistics.vue";
import equipment_manager from "@/views/equipment/equipment_manager.vue";
import agent_manager from "@/views/agentManager/agentlist.vue";
import orderCount_config from "@/views/agentManager/config/config.vue";
import subsidy_data from "@/views/subsidy/subsidy.vue";
import subsidy_detail from "@/views/subsidy/subsidyDetail.vue";
import unorder_user_data from "@/views/dataCenter/unOrderUser/unorder-user-data.vue";
import city_user_develop_data from "@/views/dataCenter/cityUserDevelop/city-user-develop-data.vue";
import user_recharge_data from "@/views/dataCenter/userRecharge/user-recharge-data.vue";
import payment_account from "@/views/operate/payment-account.vue";
import recharge_account from "@/views/operate/recharge-account.vue";
import user_refund_account from "@/views/operate/user-refund-account.vue";
import user_wallet from "@/views/operate/user-wallet.vue";
import user_coupon from "@/views/operate/user-coupon.vue";
import bill_manage from "@/views/operate/bill-manage.vue";
import activity_manage from "@/views/operate/activity-manage.vue";
import coupon_manage from "@/views/operate/coupon-manage.vue";
import coupon_setting from "@/views/operate/coupon-setting.vue";
import recom_merchant from "@/views/operate/recom-merchant.vue";
import operate_setting from "@/views/operate/operate-setting.vue";
import business_setting from "@/views/agentManager/business-setting.vue";
import activity_page_manage from "@/views/operate/activity-page-manage.vue";
import scene_manage from "@/views/operate/scene-manage.vue";
import delivery_area_setting from "@/views/operate/delivery-area-setting.vue";
import agent_delivery_area_setting from "@/views/agentManager/agent-delivery-area-setting.vue";
import city_degrees_price_setting from "@/views/operate/city-degrees-price-setting.vue";
import user_degrees_wallet from "@/views/dataCenter/userDegreesWallet/user-degrees-wallet.vue";
import agent_user_degrees_wallet from "@/views/agentManager/agent-user-degrees-wallet.vue";
import degrees_manage from "@/views/operate/degrees-manage.vue";
import agent_degrees_manage from "@/views/agentManager/agent-degrees-manage.vue";
import channel_business_manage from "@/views/channelBusiness/channelBusinessManage/channel-business-manage.vue";
import b_end_enjoy_manage from "@/views/operate/b-end-enjoy-manage.vue";
import attract_message from "@/views/operate/attract-message.vue";
import battery_maintenance from "@/views/operate/battery-maintenance.vue";
import partner_message from "@/views/operate/partner-message.vue";
import system_delivery from "@/views/system/delivery/index.vue";
import agent_city_degrees_setting from "@/views/agentManager/agent-city-degrees-setting.vue";
import operationLog from "@/views/logManagement/operationLog/index.vue";
import loginLog from "@/views/logManagement/loginLog/index.vue";
import groundPushing from "@/views/operate/ground-pushing.vue";
import detectionWallet from "@/views/operate/detection-wallet.vue";
import shopClassfly from "@/views/shop/classfly/index.vue";
import shopGoodsList from "@/views/shop/goodsList/index.vue";
import shopEditGoods from "@/views/shop/editGoods/index.vue";
import shopOrderList from "@/views/shop/orderList/index.vue";
import shopLogisticsMonitoring from "@/views/shop/logisticsMonitoring/index.vue";
import shopOrderData from "@/views/shop/orderData/index.vue";
import shopRefundList from "@/views/shop/refundList/index.vue";
import shopBrandList from "@/views/shop/brandList/index.vue";
import shopSupplierList from "@/views/shop/supplierList/index.vue";
import bannerManage from "@/views/operate/banner-manage.vue";
export const dynamicsRouters = {
  dashboard: dashboard,
  orderManagement: orderManagement,
  user: user,
  account: account,
  role: role,
  org: org,
  menu: menu,
  roleAuthority: roleAuthority,
  orderStatistics: orderStatistics,
  batteryStatistics: batteryStatistics,
  equipment_manager: equipment_manager,
  agent_manager: agent_manager,
  orderCount_config: orderCount_config,
  subsidy_data: subsidy_data,
  subsidy_detail: subsidy_detail,
  unorder_user_data: unorder_user_data,
  city_user_develop_data: city_user_develop_data,
  user_recharge_data: user_recharge_data,
  payment_account: payment_account,
  recharge_account: recharge_account,
  user_refund_account: user_refund_account,
  user_wallet: user_wallet,
  user_coupon: user_coupon,
  bill_manage: bill_manage,
  activity_manage: activity_manage,
  coupon_manage: coupon_manage,
  coupon_setting: coupon_setting,
  recom_merchant: recom_merchant,
  operate_setting: operate_setting,
  business_setting: business_setting,
  activity_page_manage: activity_page_manage,
  scene_manage: scene_manage,
  delivery_area_setting: delivery_area_setting,
  agent_delivery_area_setting: agent_delivery_area_setting,
  city_degrees_price_setting: city_degrees_price_setting,
  user_degrees_wallet: user_degrees_wallet,
  agent_user_degrees_wallet: agent_user_degrees_wallet,
  degrees_manage: degrees_manage,
  agent_degrees_manage: agent_degrees_manage,
  channel_business_manage: channel_business_manage,
  b_end_enjoy_manage: b_end_enjoy_manage,
  attract_message: attract_message,
  battery_maintenance: battery_maintenance,
  partner_message: partner_message,
  system_delivery: system_delivery,
  agent_city_degrees_setting: agent_city_degrees_setting,
  operationLog: operationLog,
  loginLog: loginLog,
  groundPushing: groundPushing,
  detectionWallet: detectionWallet,
  shopClassfly: shopClassfly,
  shopGoodsList: shopGoodsList,
  shopEditGoods: shopEditGoods,
  shopOrderList: shopOrderList,
  shopLogisticsMonitoring: shopLogisticsMonitoring,
  shopOrderData: shopOrderData,
  shopRefundList: shopRefundList,
  shopBrandList: shopBrandList,
  shopSupplierList: shopSupplierList,
  bannerManage: bannerManage,
};
