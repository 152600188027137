
<el-dialog
  width="880px"
  :title="title"
  :appendToBody="true"
  :visible="visible"
  @close="close"
>
  <div class="image-wrap-cropper">
    <!-- 图片裁剪 -->
    <div
      class="cropper-main animated fadeIn faseOut"
      v-show="activeTab === 1"
    >
      <div class="cropper-left">
        <div>
          <label class="btn-primary" for="uploads">上传图片</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="seletImg($event, 1)"
          />
        </div>
        <div class="cropper-div">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :fixedBox="option.fixedBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :centerBox="option.centerBox"
            :high="option.high"
            :infoTrue="option.infoTrue"
            :maxImgSize="option.maxImgSize"
            @realTime="realTime"
            @imgLoad="imgLoad"
            @cropMoving="cropMoving"
            :enlarge="option.enlarge"
            :mode="option.mode"
            :fixedNumber="option.fixedNumber"
            :fixed="option.fixed"
          ></vueCropper>
        </div>
      </div>
      <div class="cropper-right">
        <div class="small-img">
          <p>预览:</p>
          <div class="cropper-small-img" :style="previewStyle1">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px">
            <!-- <el-button size="mini" type="info" @click="changeRatio(1, 1)"
              >编辑</el-button
            >-->
            <el-button
              size="mini"
              type="primary"
              @click="uploadImage"
              :loading="loading"
              >保存</el-button
            >
            <el-button
              v-if="showOriginalBtn || fileIsGif"
              size="mini"
              type="success"
              :loading="loading"
              @click="confirmFunc"
            >
              上传原图
            </el-button>
          </div>
          <div v-if="showOriginalBtn" style="font-size: 12px" class="mt_10">
            （上传图片过长时请选择上传原图）
          </div>
        </div>
      </div>
    </div>
  </div>
  <div slot="bottom"></div>
</el-dialog>
