<template>
  <el-dialog
    title="订单详情"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    @close="close"
  >
    <el-scrollbar
      style="height: 600px; margin-right: -20px"
      wrapStyle="padding-right:20px;overflow-x:hidden;"
      viewStyle
    >
      <el-card class="module">
        <el-row class="block-header">
          <span class="block-title">基本信息</span>
        </el-row>
        <div class="module-main">
          <p>订单编号 {{ selectObj.order_no }}</p>
          <p>订单状态：{{ status_name }}</p>
        </div>
      </el-card>
      <el-card class="module" style="margin-top: 20px">
        <el-row class="block-header">
          <span class="block-title">收货人信息</span>
        </el-row>
        <div class="module-main">
          <el-row>
            <el-col :span="8">
              收货人信息<el-button style="margin-left: 20px" @click="copy"
                >复制</el-button
              >
            </el-col>
            <el-col :span="8"> 配送信息 </el-col>
            <el-col :span="8"> 付款信息 </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="8"> 收货人：{{ selectObj.user_name }} </el-col>
            <el-col :span="8">
              配送方式：{{ selectObj.express_company || "/" }}
            </el-col>
            <el-col :span="8"> 实付金额：{{ selectObj.actual_price }} </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="8"> 联系电话：{{ selectObj.mobile }} </el-col>
            <el-col :span="8"> 发货时间：{{ selectObj.delivery_time }} </el-col>
            <el-col :span="8"> 付款方式：微信支付 </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="16">
              收货地址：{{ selectObj.address }}{{ selectObj.address_detail }}
            </el-col>
            <el-col :span="8">
              付款时间：{{ selectObj.pay_time || "" }}
            </el-col>
          </el-row>
        </div>
        <dev class="flex" style="margin-top: 10px">
          <p>备注：</p>
          <el-input
            v-model="selectObj.remark"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            readonly
            style="width: 300px"
          ></el-input>
        </dev>
      </el-card>
      <el-card
        class="module"
        style="margin-top: 20px"
        v-if="selectObj.courier_number"
      >
        <el-row class="block-header">
          <span class="block-title">包裹</span>
        </el-row>
        <div class="module-main">
          <el-row>
            <el-col :span="8">
              <p>发货方式：快递</p>
              <p style="margin-top: 10px">
                发货时间：{{ selectObj.delivery_time }}
              </p>
              <!-- <p style="margin-top: 10px">发货人：林晓英</p> -->
              <p style="margin-top: 10px">
                运单号： {{ selectObj.courier_number }}
              </p>
            </el-col>
            <el-col :span="16"> 物流状态：{{ expressStatusName }} </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="module" style="margin-top: 20px">
        <el-row class="block-header">
          <span class="block-title">商品</span>
        </el-row>
        <div class="module-main">
          <el-table :data="goodsList" border>
            <el-table-column label="商品" width="200">
              <template slot-scope="scope">
                <div class="goods-item">
                  <img
                    class="goods-img"
                    :src="`${IMG_SERVE_URL}${scope.row.goods_img}`"
                    alt=""
                  />
                  <div class="goods-info">
                    <el-tooltip :content="scope.row.goods_name" placement="top">
                      <div class="goods-name">{{ scope.row.goods_name }}</div>
                    </el-tooltip>

                    <el-tooltip
                      :content="`商品编码：${scope.row.goods_code}`"
                      placement="top"
                    >
                      <div class="goods-price" v-if="scope.row.goods_code">
                        商品编码：{{ scope.row.goods_code }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="商品品牌"
              prop="goods_brand_name"
            ></el-table-column>
            <el-table-column
              label="供应商"
              prop="supplier_name"
            ></el-table-column>
            <el-table-column label="规格信息" prop="specs"></el-table-column>
            <el-table-column label="进货价" prop="cost_price"></el-table-column>
            <el-table-column
              label="原价（元）"
              prop="goods_underline_price"
            ></el-table-column>
            <el-table-column
              label="售价（元）"
              prop="goods_price"
            ></el-table-column>
            <el-table-column label="数量" prop="num"></el-table-column>
            <el-table-column label="优惠" prop="discount"></el-table-column>
            <el-table-column
              label="实付小计"
              prop="actual_price"
            ></el-table-column>
            <el-table-column label="发货状态">
              <template>
                {{
                  selectObj.order_status == "4"
                    ? "已收货"
                    : selectObj.courier_number
                    ? "待收货"
                    : "未发货"
                }}
              </template>
            </el-table-column>
          </el-table>
          <div class="block-body">
            <div class="item">
              <p class="name">商品总价：</p>
              <p>￥{{ selectObj.pay_price }}</p>
            </div>
            <div class="item">
              <p class="name">运费：</p>
              <p>￥0</p>
            </div>
            <div class="item">
              <p class="name">优惠：</p>
              <p>
                ￥{{ (selectObj.order_price - selectObj.pay_price).toFixed(2) }}
              </p>
            </div>
            <div class="item">
              <p class="name">实收金额：</p>
              <p class="price">￥{{ selectObj.pay_price }}</p>
            </div>
          </div>
        </div>
      </el-card>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { orderStatus } from "@/enum/shopOrderEnum";
import { IMG_SERVE_URL } from "@/config/index";
import { getShopOrderExpressApi } from "../../../api-new/shop-order";
import { expressStatus } from "../../../enum/shopOrderEnum";
export default {
  data() {
    return {
      dialogVisible: true,
      expressData: {},
    };
  },
  computed: {
    expressStatusName() {
      return expressStatus[this.expressData.state];
    },
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
    status_name() {
      if (this.selectObj.refund_proces_state == "1") {
        return "正在退款";
      }
      if (this.selectObj.refund_proces_state == "3") {
        return "退款失败";
      }
      if (this.selectObj.refund_state == "2") {
        return "退款完成";
      }
      return orderStatus[this.selectObj.order_status];
    },
    goodsList() {
      let pic_content = JSON.parse(this.selectObj.pic_content || "[]") || [];
      let smallImageList = pic_content
        .filter((it) => it.type == 2)
        .sort((a, b) => {
          return a.sortNum - b.sortNum;
        });
      return [
        {
          goods_img: smallImageList.length > 0 ? smallImageList[0].url : "",
          goods_name: this.selectObj.goods_name,
          jianpin: this.selectObj.jianpin,
          goods_code: this.selectObj.goods_code,
          goods_underline_price:
            this.selectObj.goods_underline_price || this.selectObj.goods_price,
          goods_price: this.selectObj.goods_price,
          actual_price: (
            this.selectObj.goods_price * this.selectObj.num
          ).toFixed(2),
          num: this.selectObj.num,
          discount: (
            (this.selectObj.goods_underline_price -
              this.selectObj.goods_price) *
            this.selectObj.num
          ).toFixed(2),
          goods_brand_name: this.selectObj.goods_brand_name,
          supplier_name: this.selectObj.supplier_name,
          specs: this.selectObj.specs,
          cost_price: this.selectObj.cost_price,
        },
      ];
    },
  },
  props: {
    selectObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    if (this.selectObj.courier_number) {
      this.getShopOrderExpress();
    }
  },
  methods: {
    copy() {
      this.$copyText(
        `${this.selectObj.user_name} ${this.selectObj.mobile}\n${this.selectObj.address}${this.selectObj.address_detail}`
      )
        .then((e) => {
          this.$message.success("已复制到剪贴板");
        })
        .catch((e) => {
          this.$message.error("复制失败，请手动复制");
        });
    },
    async getShopOrderExpress() {
      const res = await getShopOrderExpressApi({
        id: this.selectObj.id,
      });
      if (res.data.code == 0) {
        this.expressData = res.data.data;
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-item {
  display: flex;
  width: 100%;
  overflow: hidden;
  .goods-img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  .goods-info {
    margin-left: 10px;
    flex: 1;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .goods-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .goods-price {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.block-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
  .item {
    display: flex;
  }
  p {
    line-height: 30px;
    width: 100px;
    margin-left: 20px;
    &.name {
      text-align: right;
    }
    &.price {
      color: #ff0000;
    }
  }
}
.flex {
  display: flex;
}
</style>