import request from "@/utils/request";

/**
 * @description: 获取受限供应商和用户管理的供应商列表
 * @param {Object} data 参数对象
 * @param {String} data.user_id 用户UID
 * @return {*}
 */

export function getManageSupplierRestrictedApi(data) {
  return request({
    url: "/api/user/common/manage/supplier/restricted/get",
    method: "post",
    data,
  });
}

/**
 * @description: 更新用户管理的供应商列表
 * @param {Object} data 参数对象
 * @param {String} data.user_id 用户UID
 * @param {String} data.restricted_supplier 是否受限供应商（1，受限，0：不受限）
 * @param {String} data.user_rel_suppliers 受限供应商列表
 * @return {*}
 */

export function updateManageSupplierApi(data) {
  return request({
    url: "/api/user/common/manage/supplier/update",
    method: "post",
    data,
  });
}

/**
 * @description: 受限供应商列表
 * @param {Object} data 参数对象
 * @return {*}
 */

export function getSupplierRestrictedListApi() {
  return request({
    url: "/api/goods/supplier/restrictedList",
    method: "post",
  });
}
