<template>
  <div>
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item
              label="退款时间"
              label-width="100px"
              style="margin-bottom: 0"
            >
              <div class="flex">
                <el-date-picker
                  v-model="createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  style="width: auto"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="供应商：" label-width="90px">
              <el-select
                v-model="searchValue.goods_supplier_id"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.supplier_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>退款管理</span>
        <div style="float: right">
          <el-button @click="exportOrder">导出</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        :span-method="handleRowSpan"
        border
      >
        <el-table-column label="退款单号" min-width="120" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #606266"
              @click="copy(scope.row.apply_number)"
              >{{ scope.row.apply_number }}</el-button
            >
            <i
              class="el-icon-copy-document"
              @click="copy(scope.row.apply_number)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="订单号" min-width="120" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #606266"
              @click="copy(scope.row.order_no)"
              >{{ scope.row.order_no }}</el-button
            >
            <i
              class="el-icon-copy-document"
              @click="copy(scope.row.apply_number)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column label="退款商品" min-width="120" align="center">
          <template slot-scope="scope">
            <div class="goods">
              <img :src="`${IMG_SERVE_URL}${scope.row.goods_img}`" />
              <p>{{ scope.row.goods_name }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="refund_num"
          label="退款数量 (件)"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_name"
          label="买家"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column min-width="150" align="center">
          <template slot="header" slot-scope="scope">
            <p>售后类型</p>
            <p>退单时间</p>
          </template>
          <template slot-scope="scope">
            <p>{{ scope.row.receiving_status === 0 ? "退款" : "退货退款" }}</p>
            <p>{{ scope.row.create_time }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="real_name"
          label="退款方式"
          min-width="150"
          align="center"
        >
          <template> 原路返回 </template>
        </el-table-column>
        <el-table-column
          prop="apply_refund_money"
          label="应退(元)"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refund_money"
          label="实退(元)"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="refund_proces_state_name"
          label="退款状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row, 'detail')"
              >退款详情</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.refund_proces_state == '1'"
              @click="detail(scope.row, 'examine')"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <refund-detail-dialog
      :selectObj="selectObj"
      :selectType="selectType"
      v-if="isDetailDialog"
      @close="isDetailDialog = false"
      @sure="getDataList"
    ></refund-detail-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { orderStatus } from "@/enum/shopOrderEnum";
import { queryShopOrderRefundApi } from "../../../api-new/shop-order";
import { refundProcesStateName } from "../../../enum/shopOrderEnum";
import { IMG_SERVE_URL } from "@/config/index";
import refundDetailDialog from "./refund-detail-dialog.vue";
import { operateAdd } from "@/api/system/user-api";
import { getSupplierRestrictedListApi } from "@/api-new/user";
export default {
  data() {
    return {
      searchValue: {
        create_time_begin: "",
        create_time_end: "",
      },
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      total: 0,
      dataTable: [],
      vloading: false,
      tableHeight: "70vh",
      selectObj: {},
      selectType: "",
      isDetailDialog: false,
      supplierList: [],
      statusList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: 1,
          name: "待付款",
        },
        {
          id: 2,
          name: "待发货",
        },
        {
          id: 3,
          name: "待收货",
        },
        {
          id: 4,
          name: "已完成",
        },
        {
          id: 5,
          name: "已取消",
        },
        {
          id: 6,
          name: "退款订单",
        },
      ],
    };
  },
  computed: {
    IMG_SERVE_URL() {
      return IMG_SERVE_URL;
    },
    processedData() {
      return this.dataTable.flatMap((item) => [
        {
          isStatRow: true,
          order_no: item.order_no,
          apply_number: item.apply_number,
        },
        item,
      ]);
    },
    createTime: {
      get() {
        return [
          this.searchValue.create_time_begin,
          this.searchValue.create_time_end,
        ];
      },
      set(val) {
        this.searchValue.create_time_begin = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.searchValue.create_time_end = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
  },

  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getDataList();
    this.getSupplierRestrictedList();
  },
  methods: {
    async getSupplierRestrictedList() {
      const res = await getSupplierRestrictedListApi();
      if (res.data.code == 0) {
        this.supplierList = res.data.data;
      }
    },
    copy(text) {
      this.$copyText(text)
        .then((e) => {
          this.$message.success("已复制到剪贴板");
        })
        .catch((e) => {
          this.$message.error("复制失败，请手动复制");
        });
    },
    handleRowSpan({ row, rowIndex }) {
      if (row.isStatRow) {
        return [1, 10]; // 合并12列（根据实际列数调整）
      }
      return [1, 1];
    },
    sendOutGoods() {
      this.isSendOutGoodsDialog = false;
      this.getDataList();
    },
    detail(item, type) {
      this.selectObj = item;
      this.selectType = type;
      this.isDetailDialog = true;
    },
    shelvesBtn(item) {
      this.selectObj = item;
      this.isSendOutGoodsDialog = true;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    async getDataList() {
      this.isDetailDialog = false;
      this.vloading = true;
      const res = await queryShopOrderRefundApi(
        {
          ...this.searchValue,
        },
        this.pageSize,
        (this.pageIndex - 1) * this.pageSize
      ).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let array = res.data.data.items || [];
        array.forEach((item) => {
          item.refund_proces_state_name =
            refundProcesStateName[item.refund_proces_state];
          let pic_content = JSON.parse(item.pic_content || "[]") || [];
          let smallImageList = pic_content
            .filter((it) => it.type == 2)
            .sort((a, b) => {
              return a.sortNum - b.sortNum;
            });
          item.goods_img =
            smallImageList.length > 0 ? smallImageList[0].url : "";
        });
        this.dataTable = array;
        this.total = res.data.data.total;
      }
    },
    getStatusName(item) {
      if (item.refund_proces_state == "1") {
        return "正在退款";
      }
      if (item.refund_proces_state == "3") {
        return "退款失败";
      }
      if (item.refund_state == "2") {
        return "退款完成";
      }
      return orderStatus[item.order_status];
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getDataList();
    },
    clear() {
      this.searchValue = {
        create_time_begin: "",
        create_time_end: "",
        search_info: "",
        goods_name: "",
        order_status: "",
      };
      this.clickSearch();
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getDataList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getDataList();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 43,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      await this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      if (this.total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("数据加载中...");
      const res = await queryShopOrderRefundApi(
        {
          ...this.searchValue,
        },
        20000,
        0
      ).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        arr.forEach((item) => {
          item.refund_proces_state_name =
            refundProcesStateName[item.refund_proces_state];
          item.receiving_status_name =
            item.receiving_status === 0 ? "退款" : "退货退款";
        });
        this.exportData(arr);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "订单号",
          "退款单号",
          "退款商品",
          "退款数量",
          "买家",
          "售后类型",
          "退单时间",
          "应退",
          "实退",
          "退款状态",
        ];
        const filterVal = [
          "order_no",
          "apply_number",
          "goods_name",
          "refund_num",
          "user_name",
          "receiving_status_name",
          "create_time",
          "apply_refund_money",
          "refund_money",
          "refund_proces_state_name",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "退款管理列表导出",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  components: {
    refundDetailDialog,
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
.flex {
  display: flex;
}
.goods {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    flex: 1;
    text-align: left;
    margin-left: 10px;
  }
}
</style>