<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <p class="title">数据筛选</p>
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            label-width="110px"
          >
            <el-row>
              <el-col :span="7">
                <el-form-item label="订单编号：" style="margin-bottom: 0">
                  <el-input v-model.trim="searchValue.order_no" clearable />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="支付状态：" style="margin-bottom: 0">
                  <el-select
                    v-model="searchValue.pay_status"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in paymentStatusDim"
                      :key="item.data_value"
                      :value="item.data_value"
                      :label="item.data_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="用户编号：" style="margin-bottom: 0">
                  <el-input
                    v-model.trim="searchValue.userNumber"
                    clearable
                    maxlength="10"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="3" style="float: right; text-align: right">
                <el-button type="primary" @click="clickSearch">查询</el-button>
                <el-button type="default" @click="clear">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button class="filter" type="text" @click="filter"
          >高级查询</el-button
        >
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>支付列表</span>
        <el-button style="float: right" @click="exportOrder">导出</el-button>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        border
        @selection-change="rowSelected"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_serial_number"
          label="支付流水号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_no"
          label="订单编号"
          min-width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="viewRow(scope.row)">{{
              scope.row.order_no
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="wechat_no"
          label="微信号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="open_id"
          label="微信ID"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_number"
          label="用户编号"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cellphone"
          label="联系方式"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="order_price"
          label="订单预估金额（元）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="actual_price"
          label="支付金额（元）"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pay_method_name"
          label="支付方式"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_status_name"
          label="支付状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_time"
          label="支付时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="pay_platform_serial_number"
          label="支付平台流水号"
          min-width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
    <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
    <OrderModel
      :dialog.sync="orderModelVisible"
      :id="selectId"
      :status="editState"
    />
  </div>
</template>
<script>
import DIM_API from "../../api/dim_api";
import FilterModel from "./model/pay-search-model.vue";
import OrderModel from "../order/component/order-model.vue";
import { operateAdd } from "@/api/system/user-api";
export default {
  name: "payment-account",
  components: {
    FilterModel,
    OrderModel,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      vloading: false,
      searchValue: {
        $limit: 50,
        $offset: 0,
        order_no: "",
        pay_status: "",
        pay_method: "",
        userNumber: "",
      },
      paymentStatusDim: [],
      paymentModeDim: [],
      dataTable: [],
      selection: [],
      pageSize: 50,
      offset: 0,
      limit: 0,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      editState: "view",
      selectId: "",
      orderModelVisible: false,
    };
  },
  created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
  },
  mounted() {
    this.initStatusDim();
    this.initModeDim();
    this.search();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.tableData.doLayout();
    });
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (
          column.property === "order_price" ||
          column.property === "actual_price"
        ) {
          let total = data.reduce((prev, cur) => {
            return (
              prev +
              (cur[column.property] ? parseFloat(cur[column.property]) : 0)
            );
          }, 0);
          sums[index] = parseFloat(total).toFixed(2);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    filter() {
      this.filterVisible = true;
    },
    search() {
      let that = this;
      this.vloading = true;
      this.axiosHelper
        .get("/v0.1/api/operate/pay", { params: that.searchValue })
        .then((response) => {
          this.dataTable = response.data.items;
          this.total = response.data.total_count;
          this.vloading = false;
        })
        .catch((error) => {
          this.vloading = false;
          this.$message.error(
            {
              message: "获取支付对账列表失败",
            },
            error
          );
        });
    },
    clear() {
      this.searchValue.order_no = "";
      this.searchValue.pay_status = "";
      this.searchValue.pay_method = "";
      this.searchValue.userNumber = "";
      this.clickSearch();
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 20,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      if (this.selection.length == 0) {
        this.$confirm("是否要导出全部数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          if (this.total > 20000) {
            await this.$confirm(
              `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            );
          }
          this.showLoading("数据加载中...");
          this.axiosHelper
            .get("/v0.1/api/operate/pay/export", {
              params: this.searchValue,
            })
            .then((response) => {
              this.hideLoading();
              this.exportData(response.data);
            })
            .catch(() => {
              this.hideLoading();
            });
        });
      } else {
        this.exportData(this.selection);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "支付流水号",
          "创建时间",
          "订单编号",
          "微信号",
          "微信ID",
          "用户编号",
          "联系方式",
          "订单预估金额（元）",
          "支付金额（元）",
          "支付方式",
          "支付状态",
          "支付时间",
          "支付平台流水号",
        ];
        const filterVal = [
          "pay_serial_number",
          "create_time",
          "order_no",
          "wechat_no",
          "open_id",
          "user_number",
          "cellphone",
          "order_price",
          "actual_price",
          "pay_method_name",
          "pay_status_name",
          "pay_time",
          "pay_platform_serial_number",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "支付对账导出",
          autoWidth: this.autoWidth,
        });
      });
    },
    //JSON格式化
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    viewRow(row) {
      this.selectId = row.order_id;
      this.orderModelVisible = true;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.limit = this.pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.offset = (this.currentPage - 1) * this.pageSize;
      this.limit = this.pageSize;
      this.searchValue.$limit = this.limit;
      this.searchValue.$offset = this.offset;
      this.search();
    },
    async initStatusDim() {
      let res = await DIM_API.getByType("payment_state");
      this.paymentStatusDim = res.data;
    },
    async initModeDim() {
      let res = await DIM_API.getByType("payment_mode");
      this.paymentModeDim = res.data;
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    clickSearch() {
      this.handleCurrentChange(1);
    },
    filterSearch(form) {
      this.searchValue.height_order_no = form.order_no;
      this.searchValue.height_pay_status = form.pay_status;
      this.searchValue.height_pay_method = form.pay_method;
      this.searchValue.wechat_no = form.wechat_no;
      this.searchValue.open_id = form.open_id;
      this.searchValue.cellphone = form.cellphone;
      this.searchValue.pay_serial_number = form.pay_serial_number;
      this.searchValue.pay_platform_serial_number =
        form.pay_platform_serial_number;
      this.handleCurrentChange(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
  .filter {
    margin: 0 0 0 auto;
  }
}
</style>