<template>
  <div class="height:100%;">
    <el-card class="box-card" style="height: 20%">
      <div class="box-card-main">
        <div class="text item">
          <el-form
            ref="searchValue"
            style="padding-right: 10px"
            :model="searchValue"
            inline
          >
            <el-form-item
              label="创建时间"
              label-width="100px"
            >
              <el-date-picker
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="商品名称："
              label-width="90px"
            >
              <el-input
                v-model="searchValue.goods_name"
                placeholder="请输入商品名称"
                clearable
              />
            </el-form-item>
            <el-form-item
              label="商品类目："
              label-width="90px"
            >
              <el-cascader
                v-model="goods_type_id"
                :options="typeList"
                :props="{
                  value: 'id',
                  label: 'goods_type_name',
                  children: 'children',
                }"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="商品状态："
              label-width="90px"
            >
              <el-select
                v-model="searchValue.shelves"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in shelvesList"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="供应商：" label-width="90px">
              <el-select
                v-model="searchValue.goods_supplier_id"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.supplier_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
          >查询</el-button
        >
        <el-button type="default" @click="clear">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <el-row style="margin-bottom: 15px">
        <span>商品列表</span>
        <div style="float: right">
          <el-button @click="addGoods">新增商品</el-button>
          <el-button @click="exportOrder">导出</el-button>
          <el-button @click="exportShelves('1')" v-if="isShelvesAuthority"
            >批量上架</el-button
          >
          <el-button @click="exportShelves('0')">批量下架</el-button>
        </div>
      </el-row>
      <el-table
        ref="tableData"
        v-loading="vloading"
        :height="tableHeight"
        :data="dataTable"
        @selection-change="rowSelected"
        border
      >
        <el-table-column
          fixed="left"
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="商品ID"
          min-width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_brand_name"
          label="品牌"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="supplier_name"
          label="供应商"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_price"
          label="价格"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="goods_underline_price"
          label="划线价"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="cost_price"
          label="进货价"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="inventory"
          label="库存"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shelves_name"
          label="商品状态"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">{{
              scope.row.shelves == "1" ? "查看" : "编辑"
            }}</el-button>
            <el-button
              type="text"
              v-if="isShelvesAuthority || scope.row.shelves == '1'"
              @click="shelvesBtn(scope.row)"
              >{{ scope.row.shelves == "1" ? "下架" : "上架" }}</el-button
            >
            <el-button
              type="text"
              style="color: red"
              @click="delGoodBtn(scope.row)"
              v-if="scope.row.shelves != '1'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-footer height="32px" style="margin-top: 10px">
        <el-pagination
          background
          :total="total"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </el-footer>
    </el-card>
  </div>
</template>
<script>
import moment from "moment";
import {
  deleteGoodsApi,
  getGoodsQueryApi,
  getGoodsTypeListApi,
  setGoodsShelvesApi,
  getGoodsListApi,
} from "@/api-new/goods";
import { operateAdd } from "@/api/system/user-api";
import { mapState } from "vuex";
import { getSupplierRestrictedListApi } from "@/api-new/user";
export default {
  data() {
    return {
      searchValue: {
        create_time_end: "",
        create_time_start: "",
        goods_code: "",
        goods_name: "",
        goods_type_id: "",
        jianpin: "",
        goods_supplier_id: "",
        shelves: "",
      },
      dataTable: [],
      pageIndex: 1,
      pageSize: 50,
      pageSizeList: [10, 20, 50, 100, 500],
      tableHeight: "70vh",
      filterVisible: false,
      typeList: [],
      allTypeList: [],
      supplierList: [],
      total: 0,
      vloading: false,
      selection: [],
      shelvesList: [
        {
          label: "上架",
          value: 1,
        },
        {
          label: "下架",
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    isShelvesAuthority() {
      return (
        this.user.roleCodes.includes("PTYYBGLY") ||
        this.user.isMaintenanceOrder == 1
      );
    },
    createTime: {
      get() {
        return [
          this.searchValue.create_time_start,
          this.searchValue.create_time_end,
        ];
      },
      set(val) {
        this.searchValue.create_time_start = val
          ? moment(val[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
        this.searchValue.create_time_end = val
          ? moment(val[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
    },
    goods_type_id: {
      get() {
        let obj = this.allTypeList.find(
          (it) => it.id == this.searchValue.goods_type_id
        );
        if (obj) {
          if (obj.parent_id == "-1") {
            return [obj.id];
          } else {
            return [obj.parent_id, obj.id];
          }
        } else {
          return [];
        }
      },
      set(val) {
        console.log(val[val.length - 1]);
        this.searchValue.goods_type_id = val[val.length - 1];
      },
    },
  },
  async created() {
    this.getTableHeight();
    window.onresize = () => {
      this.getTableHeight();
    };
    this.getGoodsTypeList();
    this.getGoodsList();
    this.getSupplierRestrictedList();
  },

  methods: {
    async exportShelves(shelves) {
      if (this.selection.length == 0) return this.$message.error("请选择商品");
      await this.$confirm(
        `请确认是否${shelves == "1" ? "上" : "下"}架商品?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      let success = 0;
      let fail = 0;
      this.showLoading("批量上下架中...");
      for (let i = 0; i < this.selection.length; i++) {
        let item = this.selection[i];
        const res = await setGoodsShelvesApi({
          id: item.id,
          shelves: shelves,
        });
        if (res.data.code == 0) {
          success++;
        } else {
          fail++;
        }
      }
      this.hideLoading();
      this.$message.success(`成功${success}条，失败${fail}条`);
      this.getGoodsList();
    },
    rowSelected(selection) {
      this.selection = selection;
    },
    async getSupplierRestrictedList() {
      const res = await getSupplierRestrictedListApi();
      if (res.data.code == 0) {
        this.supplierList = res.data.data;
      }
    },
    async getGoodsTypeList() {
      const res = await getGoodsTypeListApi();
      if (res.data.code == 0) {
        let arr = res.data.data || [];
        arr.sort((a, b) => {
          if (a.type_grade == b.type_grade) {
            return b.sort_num - a.sort_num;
          } else {
            return a.type_grade - b.type_grade;
          }
        });
        let list = [];
        arr.forEach((item) => {
          if (item.type_grade == 1) {
            list.push(item);
          }
          if (item.type_grade == 2) {
            let parent = list.find((it) => it.id === item.parent_id);
            if (parent) {
              parent.children = parent.children || [];
              parent.children.push(item);
            }
          }
        });
        this.typeList = list;
        this.allTypeList = arr;
      }
    },
    edit(item) {
      this.$router.push({
        name: "shopEditGoods",
        query: {
          id: item.id,
        },
      });
    },
    async shelvesBtn(item) {
      await this.$confirm(
        `请确认是否${item.shelves == "1" ? "下" : "上"}架该商品?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      );
      this.showLoading();
      const res = await setGoodsShelvesApi({
        id: item.id,
        shelves: item.shelves == "1" ? "0" : "1",
      }).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        this.$message.success("操作成功");
        this.getGoodsList();
      }
    },
    async delGoodBtn(item) {
      await this.$confirm("请确认是否删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.showLoading();
      const res = await deleteGoodsApi({
        id: item.id,
      }).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        this.$message.success("删除成功");
        this.getGoodsList();
      }
    },
    clickSearch() {
      this.pageIndex = 1;
      this.getGoodsList();
    },
    async getGoodsList() {
      let offset = (this.pageIndex - 1) * this.pageSize;
      let limit = this.pageSize;
      this.vloading = true;
      const res = await getGoodsQueryApi(
        {
          ...this.searchValue,
        },
        limit,
        offset
      ).finally(() => {
        this.vloading = false;
      });
      if (res.data.code == 0) {
        let arr = res.data.data.items || [];
        arr.forEach((item) => {
          if (item.shelves == 0) {
            item.shelves_name = "下架";
          }
          if (item.shelves == 1) {
            item.shelves_name = "上架";
          }
          if (item.shelves == 2) {
            item.shelves_name = "删除";
          }
        });
        this.dataTable = arr;
        this.total = res.data.data.total;
      }
    },
    getTableHeight() {
      this.tableHeight = String(
        document.documentElement.clientHeight - 95 - 150 - 200
      );
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getGoodsList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getGoodsList();
    },
    clear() {
      this.searchValue = {
        create_time_end: "",
        create_time_start: "",
        goods_code: "",
        goods_name: "",
        goods_type_id: "",
        jianpin: "",
      };
      this.clickSearch();
    },
    addGoods() {
      this.$router.push({
        name: "shopEditGoods",
      });
    },
    async exportOrder() {
      await operateAdd({
        operate_type: "1",
        operate_number: 39,
        operate_parameter: JSON.stringify(this.searchValue),
      });
      await this.$alert(
        "请妥善保管数据信息，严禁任何未经授权的行为对数据进行传播",
        "数据安全保密须知",
        {
          confirmButtonText: "已阅读，关闭弹窗",
        }
      );
      await this.$confirm("是否要导出全部数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      if (this.total > 20000) {
        await this.$confirm(
          `您本次需要导出${this.total}条数据，但目前系统最多支持导出20000条数据，遂将可能导致导出的数据不全，是否继续导出？？?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
      }
      this.showLoading("数据加载中...");
      const res = await getGoodsListApi({
        ...this.searchValue,
      }).finally(() => {
        this.hideLoading();
      });
      if (res.data.code == 0) {
        let arr = res.data.data;
        arr.forEach((item) => {
          if (item.shelves == 0) {
            item.shelves_name = "下架";
          }
          if (item.shelves == 1) {
            item.shelves_name = "上架";
          }
          if (item.shelves == 2) {
            item.shelves_name = "删除";
          }
        });
        this.exportData(arr);
      }
    },
    exportData(export_data) {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "商品ID",
          "商品名称",
          "品牌",
          "供应商",
          "价格",
          "划线价",
          "进货价",
          "库存",
          "创建时间",
          "商品状态",
        ];
        const filterVal = [
          "id",
          "goods_name",
          "goods_brand_name",
          "supplier_name",
          "goods_price",
          "goods_underline_price",
          "cost_price",
          "inventory",
          "create_time",
          "shelves_name",
        ];
        const list = export_data;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "商品列表导出",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>
<style  lang="scss" scoped >
.box-card {
  margin-top: 15px;
}
.box-card-main {
  display: flex;

  align-items: center;
  .title {
    flex-shrink: 0;
    line-height: 32px;
  }
}
</style>