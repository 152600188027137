<template>
  <el-dialog
    width="880px"
    :title="title"
    :appendToBody="true"
    :visible="visible"
    @close="close"
  >
    <div class="image-wrap-cropper">
      <!-- 图片裁剪 -->
      <div
        class="cropper-main animated fadeIn faseOut"
        v-show="activeTab === 1"
      >
        <div class="cropper-left">
          <div>
            <label class="btn-primary" for="uploads">上传图片</label>
            <input
              type="file"
              id="uploads"
              style="position: absolute; clip: rect(0 0 0 0)"
              accept="image/png, image/jpeg, image/gif, image/jpg"
              @change="seletImg($event, 1)"
            />
          </div>
          <div class="cropper-div">
            <vueCropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :fixedBox="option.fixedBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :centerBox="option.centerBox"
              :high="option.high"
              :infoTrue="option.infoTrue"
              :maxImgSize="option.maxImgSize"
              @realTime="realTime"
              @imgLoad="imgLoad"
              @cropMoving="cropMoving"
              :enlarge="option.enlarge"
              :mode="option.mode"
              :fixedNumber="option.fixedNumber"
              :fixed="option.fixed"
            ></vueCropper>
          </div>
        </div>
        <div class="cropper-right">
          <div class="small-img">
            <p>预览:</p>
            <div class="cropper-small-img" :style="previewStyle1">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-top: 20px">
              <!-- <el-button size="mini" type="info" @click="changeRatio(1, 1)"
                >编辑</el-button
              >-->
              <el-button
                size="mini"
                type="primary"
                @click="uploadImage"
                :loading="loading"
                >保存</el-button
              >
              <el-button
                v-if="showOriginalBtn || fileIsGif"
                size="mini"
                type="success"
                :loading="loading"
                @click="confirmFunc"
              >
                上传原图
              </el-button>
            </div>
            <div v-if="showOriginalBtn" style="font-size: 12px" class="mt_10">
              （上传图片过长时请选择上传原图）
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="bottom"></div>
  </el-dialog>
</template>
<script>
import { VueCropper } from "vue-cropper";
import { mapActions, mapGetters } from "vuex";
// import $ from "jquery";
export default {
  props: {
    width: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      default: 1,
    },
    scale: {
      type: Boolean,
      default: true,
    },
    maxSize: {
      type: Number,
      default: 10,
    },
    isCompress: {
      type: Number,
      default: 1,
    },
    showOriginalBtn: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "上传图片",
    },
    rootFilePath: {
      type: String,
      default: "",
    },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      activeTab: 1,
      visible: true,
      showDialogFooter: false,
      // title: '上传图片',
      model: false,
      modelSrc: "",
      crap: false,
      previews: {},
      previewStyle1: {},
      previewStyle2: {},
      option: {
        img: "",
        size: 1,
        full: true, // 是否输出原图比例的截图
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false, // 上传图片按照原始比例渲染
        canMoveBox: true,
        autoCrop: true,
        //  只有自动截图开启 宽度高度才生效
        autoCropWidth: 200,
        autoCropHeight: 200,
        centerBox: true,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: "contain",
        maxImgSize: 20000,
        infoTrue: true,
        fixedNumber: [3, 3], //  截图框的宽高比例
        fixed: true, // 是否开启截图框宽高固定比例
      },
      curActiveCropperTypeIndex: 1,
      imgList: [
        {
          url: "https://sdxpos.oss-cn-shanghai.aliyuncs.com/image/2019-11-27/4416de088ed848f6b394871f83540c4c.jpg",
          selected: true,
          id: "1",
        },
      ],
      newCropperTypeName: "", //  类型名称
      originalImg: "",
      fileIsGif: false,
      loading: false,
    };
  },
  mounted() {
    this.changeRatio();
  },
  methods: {
    confirmFunc() {
      let message =
        "上传原图将不对图片进行裁剪，请确定您的图片规格符合使用场景，否则会导致图片展示变形";
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.saveOriginalPic();
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async saveOriginalPic() {
      this.loading = true;
      let files = this.originalImg;
      if (files !== null) {
        this.showLoading("加载中");
        try {
          let param = new FormData();
          param.append("file", files);
          const res = await this.axiosHelper.post(
            `/api/file/upload/oss?rootFilePath=${this.rootFilePath}`,
            param
          );
          if (res.data.code == 0) {
            this.hideLoading();
            this.$message({
              type: "success",
              message: "上传成功",
            });
            console.log(res.data);
            this.$emit("sure", res.data.data.url);
          } else {
            this.loading = false;
            this.$message({
              type: "error",
              message: res.data.msg,
            });
            this.hideLoading();
          }
        } catch (error) {
          this.loading = false;
          this.hideLoading();
        }
      } else {
        this.loading = false;
        this.$message({
          type: "warning",
          message: "请选择上传的图片",
        });
      }
    },
    //  实时预览函数
    realTime(data) {
      var previews = data;
      // var h = 0.5
      // var w = 0.2

      this.previewStyle1 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        //  margin: "15",
        zoom: 120 / previews.w,
      };

      this.previewStyle2 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        //  margin: "15",
        zoom: 100 / previews.h,
      };

      this.previews = data;
    },
    imgLoad(msg) {
      console.log(msg);
    },

    cropMoving(data) {
      this.option.cropData = data;
    },
    //  修改截图框比例
    changeRatio() {
      this.option.fixedNumber = [];
      this.option.fixedNumber.push(this.width);
      this.option.fixedNumber.push(this.height);
      this.option.fixed = this.scale;
    },
    //  选择图片
    seletImg(e, num) {
      // 上传图片
      //  this.option.img
      let file = e.target.files[0];
      this.originalImg = file;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      if (this.originalImg.type === "image/gif") {
        this.fileIsGif = true;
      } else {
        this.fileIsGif = false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          //  把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      //  转化为base64
      //  reader.readAsDataURL(file)
      //  转化为blob
      reader.readAsArrayBuffer(file);
    },
    //  图片上传
    async uploadImage() {
      const self = this;
      if (!this.$refs.cropper.imgs) {
        self.$message({
          message: "请选择图片",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      try {
        this.$refs.cropper.getCropBlob(async (data) => {
          const file = new File([data], "cropped-image.png", {
            type: "image/png",
          });
          const param = new FormData();
          param.append("file", file);
          // const url = this.baseImgUrl;
          this.showLoading("加载中");
          const res = await this.axiosHelper.post(
            `/api/file/upload/oss?rootFilePath=${this.rootFilePath}`,
            param
          );
          if (res.data.code == 0) {
            this.hideLoading();
            this.$message({
              type: "success",
              message: "上传成功",
            });
            this.$emit("sure", res.data.data.url);
          } else {
            this.loading = false;
            this.$message({
              type: "error",
              message: res.data.msg,
            });
            this.hideLoading();
          }
        });
      } catch (e) {
        this.loading = false;
        this.hideLoading();
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog {
  .el-dialog__body {
    .image-wrap-cropper {
      height: 470px;
      .cropper-main {
        border-top: solid 2px #dfe6ec;
        background: #ffffff;
        display: flex;
        //  display: none;
        .cropper-left {
          width: 630px;
          border-right: solid 1px #dfe6ec;
          padding: 20px;
          .icon {
            font-size: 12px;
            margin-right: 5px;
          }
          .cropper-div {
            height: 360px;
            width: 570px;
            margin-top: 20px;
          }
          .btn-primary {
            font-size: 13px;
            color: white;
            background: #409eff;
            padding: 9px 15px;
            border-radius: 3px;
          }
        }
        .cropper-right {
          flex: 1;
          width: 170px;
          padding: 20px;
          .small-img {
            .cropper-small-img {
              width: 120px;
              height: 120px;
              background: #dedede;
              border-radius: 10px;
              // margin-bottom: 20px;
            }
          }
          .big-img {
            margin-top: 40px;
            .cropper-big-img {
              width: 200px;
              height: 100px;
              background: #dedede;
              border-radius: 10px;
              margin-bottom: 15px;
            }
          }
        }
      }
      .cropper-img-list-div {
        border-top: solid 2px #dfe6ec;
        background: #ffffff;
        height: 100%;
        .el-container {
          height: 100%;
          .cropper-list-header {
            max-height: 50px;
            border-bottom: solid 1px #dfe6ec;
            display: flex;
            align-items: center;
            .cropper-header-name {
              font-size: 12px;
              color: #606266;
              flex: 1;
              font-weight: bold;
            }
            .icon {
              font-size: 14px;
              margin-right: 10px;
              cursor: pointer;
            }
          }
          .cropper-list-container {
            .cropper-list-aside::-webkit-scrollbar {
              display: none;
            }
            .cropper-list-aside {
              width: 130px !important;
              display: flex;
              flex-direction: column;
              .cropper-img-type-list {
                flex: 1;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                overflow: auto;
                background: #f5f7fa;
                &::-webkit-scrollbar {
                  display: none;
                }
                .cropper-img-type {
                  padding: 15px 20px;
                  background: #f5f7fa;
                  color: #6d6f73;
                }
                .cropper-img-type-active {
                  padding: 15px 20px;
                  background: #ffffff;
                  color: #1890ff;
                }
              }
              .cropper-img-type-add {
                padding: 15px 18px;
                background: #f5f7fa;
                z-index: 2;
                height: 60px;
                .icon {
                  font-size: 12px;
                  margin-right: 5px;
                }
                .cropper-new-type-div {
                  padding: 13px 10px;
                }
              }
            }
            .cropper-img-operation-container {
              .cropper-img-list {
                background: #ffffff;
                padding: 8px 18px;
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;
                .cropper-img-div {
                  position: relative;
                  img {
                    width: 86px;
                    height: 86px;
                    border-radius: 10px;
                    margin: 8px;
                  }
                  .icon {
                    font-size: 12px;
                    background: #26ca61;
                    border-radius: 50px;
                    color: #ffffff;
                    padding: 2px;
                    position: absolute;
                    bottom: 14px;
                    right: 12px;
                  }
                }
              }
              .cropper-img-btns {
                border-top: solid 1px #dfe6ec;
                height: 60px;
                background: #ffffff;
                padding: 15px 20px;
                display: flex;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}
</style>
