
<div class="app-main">
  <el-card class="box-card">
    <div class="box-card-search">
      <el-form ref="form" :model="form" class="box-card-search-form">
        <p class="box-card-title">订单筛选</p>
        <el-form-item label="城市" style="flex: 1" label-width="50px">
          <el-select
            v-model="form.cityCode"
            placeholder="请选择城市"
            multiple
            collapse-tags
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in restrictedCityList"
              :key="item.cityCode"
              :label="item.cityName"
              :value="item.cityCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号：" style="flex: 1" label-width="90px">
          <el-input
            v-model.trim="form.searchOrderNo"
            placeholder="请输入订单编号"
            clearable
          />
        </el-form-item>
        <el-form-item label="用户编号：" style="flex: 1" label-width="100px">
          <el-input
            v-model.trim="form.userNumber"
            placeholder="请输入用户编号"
            clearable
            maxlength="10"
            oninput="value=value.replace(/[^0-9]/g,'')"
          />
        </el-form-item>
        <el-form-item label="订单状态：" style="flex: 1" label-width="90px">
          <el-select
            v-model="form.searchOrderStatus"
            multiple
            collapse-tags
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in orderStatusDim"
              :key="item.data_value"
              :value="item.data_value"
              :label="item.data_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：" label-width="90px">
          <el-date-picker
            v-model="payTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 220px"
            align="center"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="elColBtn">
          <el-button style="margin-right: 10px" type="primary" @click="search"
            >查询</el-button
          >
          <el-button type="default" @click="clear">重置</el-button>
        </el-form-item>
        <el-button type="text" class="filter_btn" @click="filter"
          >高级查询</el-button
        >
      </el-form>
    </div>
  </el-card>
  <el-card class="box-card box-card-main">
    <div class="total">
      <div
        v-for="(item, index) in totalList"
        :style="{ background: item.background }"
        class="total-item"
        :key="index"
      >
        <img class="total-item-img" :src="item.img" />
        <div class="total-item-main">
          <p class="total-item-main-name">{{ item.name }}</p>
          <p class="total-item-main-price">{{ item.price }}</p>
        </div>
      </div>
    </div>
    <el-row style="margin-bottom: 15px">
      <span>订单列表</span>
      <el-button
        style="margin-left: 15px"
        v-if="isShowBtn"
        @click="closeOrder"
        type="primary"
        >关闭订单</el-button
      >
      <!-- <el-button style="margin-left: 15px" @click="settleOrder" type="primary"
        >设置结算单</el-button
      > -->
      <el-button
        style="margin-left: 15px"
        v-if="isShowBtn3 || isShowBtn2"
        @click="testOrder"
        type="primary"
        >设置测试单</el-button
      >
      <el-dropdown
        v-if="isShowBtn2 || isShowBtn"
        trigger="click"
        @command="settleOrder"
        placement="bottom"
      >
        <el-button type="primary" style="margin-left: 15px">
          设置结算单<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">已结算</el-dropdown-item>
          <el-dropdown-item command="0">未结算</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" style="float: right" @click="exportOrder"
        >导出</el-button
      >
    </el-row>
    <!-- <el-table ref="dataTable" :height="tableHeight" :data="dataTable" border @selection-change="rowSelected"
              :summary-method="getSummaries" show-summary> -->
    <el-table
      ref="dataTable"
      :height="tableHeight"
      class="dataTable"
      :data="dataTable"
      show-summary
      :summary-method="getSummaries"
      border
      @selection-change="rowSelected"
      @sort-change="handleSortChange"
      :header-cell-style="{
        background: '#eff4fd',
        color: '#323232',
      }"
    >
      <el-table-column
        fixed="left"
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column label="序号" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="城市"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_no"
        label="订单编号"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="viewRow(scope.row)">{{
            scope.row.order_no
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="order_type_name"
        label="订单类型"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reserve_time"
        label="预约时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_name"
        label="用户名称"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_number"
        label="用户编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recommend_type_name"
        label="推荐人类型"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reference_name"
        label="推荐人"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_cellphone"
        label="用户手机号"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="car_no"
        label="车牌号码"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="delivery_user_name"
        label="配送人员"
        min-width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="order_status_name"
        label="订单状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_finish_time"
        label="订单完成时间"
        sortable="custom"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="equipment_name"
        label="设备名称"
        sortable="custom"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recover_flag_name"
        label="设备回收状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="actual_charge"
        label="充电度数"
        sortable="custom"
        min-width="100"
        align="center"
        :formatter="formatCharge"
      ></el-table-column>
      <el-table-column
        prop="real_charge"
        label="实际充电度数"
        sortable="custom"
        min-width="120"
        align="center"
        :formatter="formatCharge"
      ></el-table-column>
      <el-table-column
        prop="start_charge_degree"
        label="起充度数"
        min-width="100"
        align="center"
        :formatter="formatCharge"
      >
      </el-table-column>
      <el-table-column
        prop="has_maintenance_order"
        label="是否运维工单"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.has_maintenance_order" type="danger"
            >是</el-tag
          >
          <el-tag v-else type="success">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="electric_unit_price"
        label="电费单价（元/度）"
        sortable="custom"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="order_price"
        label="订单预估金额"
        sortable="custom"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="user_invoice_amount_money"
        label="用户开票金额"
        min-width="140"
        align="center"
      >
        <span style="" slot="header">
          <span
            >用户开票金额<el-tooltip
              class="item"
              effect="dark"
              content="账户余额支付+c端度数（度数金额）"
              placement="top"
              ><i class="el-icon-question"></i></el-tooltip
          ></span>
        </span>
      </el-table-column>
      <!-- <el-table-column prop="real_pay_money" label="实付金额总计" sortable="custom" min-width="120"
                  align="center"></el-table-column> -->
      <el-table-column
        prop="actual_price"
        label="账户余额支付"
        sortable="custom"
        min-width="120"
        align="center"
      ></el-table-column>
      <!-- <el-table-column label="实付金额" min-width="100" align="center">
                  <el-table-column prop="actual_price" label="账户余额支付" sortable="custom" min-width="120"
                      align="center">
                  </el-table-column><el-table-column prop="use_degree_real_money" label="实时度数金额" sortable="custom"
                      min-width="120" align="center"></el-table-column>
              </el-table-column> -->
      <el-table-column
        prop="coupon_name"
        label="优惠券"
        min-width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="coupon_deduction_money"
        label="优惠券补贴金额"
        min-width="100"
        align="center"
      >
        <span style="" slot="header">
          <span
            >优惠券补贴金额<el-tooltip
              class="item"
              effect="dark"
              placement="top"
            >
              <div slot="content">
                度数券：电费单价*实际抵扣度数<br />现金券：实际抵扣现金金额
              </div>
              <i class="el-icon-question"></i></el-tooltip
          ></span>
        </span>
      </el-table-column>

      <el-table-column label="C端度数" min-width="100" align="center">
        <el-table-column
          prop="use_degree_unit_price"
          label="实时单价"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="client_use_degree"
          label="数量"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="use_degree_real_money"
          label="度数金额"
          min-width="100"
          align="center"
        >
          <span style="" slot="header">
            <span
              >度数金额<el-tooltip
                class="item"
                effect="dark"
                content="c端度数（数量） * c端度数（实时单价）"
                placement="top"
                ><i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>
      </el-table-column>
      <el-table-column label="B端度数" min-width="100" align="center">
        <el-table-column
          prop="busin_degree_unit_price"
          label="实时单价"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="busin_degree"
          label="数量"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="busin_degree_amount_money"
          label="度数金额"
          min-width="100"
          align="center"
        >
          <span slot="header">
            <span
              >度数金额<el-tooltip
                class="item"
                effect="dark"
                content="B端度数（数量） * B端度数（实时单价）"
                placement="top"
                ><i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>
      </el-table-column>
      <el-table-column label="度数合计" min-width="100" align="center">
        <el-table-column
          prop="use_degree"
          label="数量"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="use_degree_subsidy_amount_money"
          label="度数补贴金额"
          min-width="120"
          align="center"
        >
          <span slot="header">
            <span
              >度数补贴金额<el-tooltip
                class="item"
                effect="dark"
                content="订单预估金额 - 账户余额支付 - 优惠券补贴金额 - 度数金额合计"
                placement="top"
                ><i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>
        <el-table-column
          prop="use_degree_amount_money"
          label="度数金额合计"
          min-width="120"
          align="center"
        >
          <span slot="header">
            <span
              >度数金额合计<el-tooltip
                class="item"
                effect="dark"
                content="c端度数（度数金额）+b端度数（ 度数金额）"
                placement="top"
                ><i class="el-icon-question"></i></el-tooltip
            ></span>
          </span>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="pay_flag_name"
        label="支付状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_time"
        label="支付时间"
        sortable="custom"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注信息"
        min-width="200"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="agent_settle_name"
        label="结算状态"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="identify_type_name"
        label="车牌识别"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button @click="viewRow(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button
            @click="editRow(scope.row)"
            v-if="isShowBtn"
            type="text"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-if="
              isShowBtn &&
              scope.row.order_status != 7 &&
              scope.row.order_status != 8 &&
              scope.row.order_status != 9
            "
            @click="closeRow(scope.row)"
            type="text"
            size="small"
            >关闭</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="page-footer">
      <p class="page-footer-total">共{{ page_total }}条数据</p>
      <el-pagination
        background
        layout="sizes, prev, pager, next,jumper"
        :total="page_total"
        :page-size="page_size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :current-page.sync="page_index"
      ></el-pagination>
    </div>
  </el-card>
  <FilterModel :dialog.sync="filterVisible" @search="filterSearch" />
  <OrderModel
    :dialog.sync="orderModelVisible"
    :id="selectId"
    :status="editState"
    @search="search"
  />
  <CloseModel
    :dialog.sync="closeModelVisible"
    :id="selectId"
    @search="search"
  />
  <SettleModel
    :dialog.sync="settleModelVisible"
    :ids="ids"
    @search="search"
  />
  <TestModel :dialog.sync="testModelVisible" :ids="ids" @search="search" />
</div>
