
<div>
  <el-card class="box-card" style="height: 20%">
    <div class="box-card-main">
      <div class="text item">
        <el-form
          ref="searchValue"
          style="padding-right: 10px"
          :model="searchValue"
          inline
        >
          <el-form-item label="订单编号：" label-width="90px">
            <el-input
              v-model="searchValue.order_no"
              placeholder="请输入订单编号"
              clearable
            />
          </el-form-item>
          <el-form-item label="下单时间" label-width="100px">
            <div class="flex">
              <el-date-picker
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                style="width: auto"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="商品名称：" label-width="90px">
            <el-input
              v-model="searchValue.goods_name"
              placeholder="请输入商品名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="订单状态：" label-width="90px">
            <el-select
              v-model="searchValue.order_status"
              placeholder="全部"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商：" label-width="90px">
            <el-select
              v-model="searchValue.goods_supplier_id"
              placeholder="全部"
              clearable
            >
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :value="item.id"
                :label="item.supplier_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="clickSearch" style="margin-left: 10px"
        >查询</el-button
      >
      <el-button type="default" @click="clear">重置</el-button>
    </div>
  </el-card>
  <el-card class="box-card">
    <el-row style="margin-bottom: 15px">
      <span>订单列表</span>
      <div style="float: right">
        <el-button @click="exportOrder">导出</el-button>
      </div>
    </el-row>
    <el-table
      ref="tableData"
      v-loading="vloading"
      :height="tableHeight"
      :data="dataTable"
      border
    >
      <el-table-column
        prop="order_no"
        label="订单编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_name"
        label="商品信息"
        min-width="260"
        align="center"
      >
        <template slot-scope="scope">
          <div class="goods-info">
            <el-image class="goods-info-img" :src="goodsImg(scope.row)" />
            <div class="goods-main">
              <div class="goods-main-top">
                <p class="goods-name">{{ scope.row.goods_name }}</p>
                <p class="goods-num">x{{ scope.row.num }}</p>
              </div>
              <p class="price">￥{{ scope.row.goods_price }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_code"
        label="商品编码"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="supplier_name"
        label="供应商"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_price"
        label="订单总金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="cost_price_total"
        label="订单成本总价"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="actual_price"
        label="订单支付金额"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="real_name"
        label="买家/收货人"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="配送方式"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">{{
          scope.row.express_company || ""
        }}</template>
      </el-table-column>
      <el-table-column
        prop="status_name"
        label="订单状态"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="订单创建时间"
        min-width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pay_time"
        label="订单支付时间"
        min-width="150"
        align="center"
      ></el-table-column>

      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="
              scope.row.order_status == 2 && scope.row.refund_state == '0'
            "
            @click="delOrder(scope.row)"
            >取消订单</el-button
          >
          <el-button type="text" @click="orderDetail(scope.row)"
            >订单详情</el-button
          >
          <el-button
            type="text"
            v-if="
              scope.row.order_status == 2 && scope.row.refund_state == '0'
            "
            @click="shelvesBtn(scope.row)"
            >发货</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-footer height="32px" style="margin-top: 10px">
      <el-pagination
        background
        :total="total"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizeList"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-footer>
  </el-card>
  <send-out-goods-dialog
    v-if="isSendOutGoodsDialog"
    :selectObj="selectObj"
    @close="isSendOutGoodsDialog = false"
    @sure="sendOutGoods"
  />
  <order-detail-dialog
    v-if="isOrderDetailDialog"
    :selectObj="selectObj"
    @close="isOrderDetailDialog = false"
  />
  <cancelOrderDialog
    v-if="isCancelOrderDialog"
    :selectObj="selectObj"
    @close="isCancelOrderDialog = false"
    @sure="cancelOrder"
  />
</div>
