
<el-dialog
  :visible.sync="visible"
  @close="close"
  title="退款详情"
  width="600px"
>
  <el-form ref="form" :model="form" :rules="rules" label-width="140px">
    <el-form-item label="退款状态：">
      <div>{{ selectObj.refund_proces_state_name }}</div>
    </el-form-item>
    <el-form-item label="退款原因：">
      <div>{{ selectObj.remark }}</div>
    </el-form-item>
    <el-form-item label="退款图片：" v-if="imgList.length > 0">
      <el-image
        v-for="item in imgList"
        :key="item"
        :src="`${item}`"
        style="width: 100px; height: 100px; maring-right: 10px"
        :preview-src-list="imgList"
      />
    </el-form-item>
    <el-form-item label="商品应退金额：">
      <div>{{ selectObj.apply_refund_money }}</div>
    </el-form-item>
    <el-form-item label="应退金额修改为：" prop="refund_money">
      <p v-if="selectType == 'detail'">{{ selectObj.refund_money }}</p>
      <el-input
        v-else
        v-model="form.refund_money"
        @input="
          validateInput(
            'refund_money',
            $event,
            null,
            2,
            false,
            selectObj.apply_refund_money
          )
        "
        style="width: 200px"
      />
    </el-form-item>
    <el-form-item
      label="退款审核备注："
      v-if="
        (selectType == 'detail' && selectObj.examine_remark != '') ||
        selectType != 'detail'
      "
    >
      <p v-if="selectType == 'detail'">{{ selectObj.examine_remark }}</p>
      <el-input
        v-else
        type="textarea"
        v-model="form.examine_remark"
        style="width: 400px"
      />
    </el-form-item>
    <el-form-item
      label="拒绝退款原因："
      v-if="selectObj.refund_proces_state == '3'"
    >
      <p>{{ selectObj.refusing_refund_reason }}</p>
    </el-form-item>
  </el-form>
  <div class="bottom" v-if="selectType != 'detail'">
    <el-button type="primary" @click="submitForm">同意退款</el-button>
    <el-button @click="rejectBtn">拒绝退款</el-button>
  </div>
</el-dialog>
